<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet">
<div class="content">
  <div class="container">

    <nb-card-header>
      <div class="row">
        <div class="col-md-3">
          <h3>Contracts</h3>
        </div>
        <div class="col-md-5">
          <div class="row">
            <input [(ngModel)]="search"
                   class="w-100" fieldSize="large" nbInput
                   nbTooltip="Tap here to search your contract"
                   nbTooltipStatus="primary" placeholder="Search contract"
                   type="text">

          </div>
        </div>
        <div class="col-md-4 pt-1">
          <div>

            <button (click)="exportAsXLSX()"
                    align="right" class="mx-1"
                    nbButton nbTooltip="Click here to export data to Excel" nbTooltipStatus="primary"
                    status="success">
              <i class="fas fa-file-excel text-white"></i>
            </button>
           

          </div>
        </div>
      </div>


    </nb-card-header>
    <div *ngIf="!dataContracts || dataContracts.length === 0" align="center">
      <nb-alert class="w-100 mt-3" outline="danger">No Data Found.</nb-alert>
    </div>
    <div *ngIf="(dataContracts | filter : search).length === 0" [hidden]="dataContracts.length === 0"
         class="">
      <nb-alert class="col-lg-12 mt-3" fullWidth outline="danger">The item searched is not found. Please make sure
        while typing your data
      </nb-alert>
    </div>


    <div class="page-content container note-has-grid mt-4">

      <div class="row">

        <div class="col-md-12 mt-3">


          <table class="table mb-0">
            <thead class="bg-light">
            <tr>
              <th>Contract Name</th>
              <th>Contract Type</th>
              <th>Designation</th>
              <th>Price Effective</th>
              <th>Date Contract</th>
              <th>state</th>
              <th>Actions</th>

            </tr>
            </thead>
            <tbody
              *ngFor="let contract of dataContracts | filter : search | paginate : {itemsPerPage:10, currentPage: current,totalItems: total}"
            >
            <tr class="col-md-9 col-sm-10">
              <td>
                <p class=" mb-1">{{contract.contractName}}</p>
              </td>
              <td><p class=" mb-0">{{contract.contractType}}</p>

              </td>
              <td><p class="fw-normal mb-1">{{contract.designation}}</p></td>
              <td><p class="fw-normal mb-1">{{contract.priceEffective}}</p></td>
              <td><p class="fw-normal mb-1">{{contract.dateContract | date : 'YYYY MMMM dd'}}</p></td>
              <td><p class="fw-normal mb-1">{{contract.state}}</p></td>


              <td>
                <button [matMenuTriggerFor]="menu" aria-expanded="false" aria-haspopup="true"
                        aria-label="Example icon-button with a menu" mat-icon-button
                        nbButton nbTooltip="Click here to see advanced menu"
                        nbTooltipStatus="primary"
                        shape="round"
                        size="small"
                        status="basic">
                  <nb-icon icon="more-horizontal-outline"></nb-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item>
                    <a (click)="onRestoreConfirm(contract,contract.id)" fullWidth nbButton status="basic">
                      <nb-icon icon="archive-outline"></nb-icon>
                      Restore
                    </a>
                  </button>
                </mat-menu>
              </td>
            </tr>
            </tbody>
          </table>


        </div>
      </div>

      <div class="d-flex justify-content-center">
        <nb-card class="card ml-auto mr-auto text-center" style="height: 30px; width: auto ;">
          <pagination-controls (pageChange)="current = $event" align="right" nextLabel=""
                               previousLabel=""></pagination-controls>

        </nb-card>
      </div>

    </div>
  </div>
</div>