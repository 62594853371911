<div *ngIf="event" class="container mt-3">
  <div class="row">
    <div class="col-xs-12 col-sm-12">

      <div class="panel panel-default">

        <div class="panel-body">
          <div class="row">
            <div class="col-md-10 col-sm-12">
              <h2 class="more-vertical">
                <div class="event__image mx-3">
                  <img src="{{event?.image}}" alt="Event Image" class="event-picture">
                </div>

              </h2>
            </div>
            <div align="right" class="justify-content-end col-md-2">
              <a [routerLink]="['/crm/edit-event/',event.id]" class="mx-2" nbButton
                 nbTooltip="Click here to edit event" nbTooltipStatus="primary"
                 shape="round"
                 status="warning">
                <nb-icon icon="edit-outline"></nb-icon>
              </a>

              <a [routerLink]="['/crm/events']" nbButton
                 nbTooltip="Back to events list" nbTooltipStatus="danger"
                 shape="round" status="basic">
                <nb-icon icon="corner-up-right-outline"></nb-icon>
              </a>

            </div>
          </div>


        </div>

      </div>

      <!-- User info -->

      <div class="panel panel-default mt-3">
        <div class="panel-heading">

          <nb-accordion>
            <nb-accordion-item expanded="true">
              <nb-accordion-item-header><h6>Detail Event </h6></nb-accordion-item-header>
              <nb-accordion-item-body>
                <div class="panel-body">

                  <div class="row mt-3">
                    <div class="col-md-3">
                      Title
                    </div>
                    <div class="col-md-2">
                      |
                    </div>
                    <div class="col-md-7">
                      {{event.title}}
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-md-3">
                      Country
                    </div>
                    <div class="col-md-2">
                      |
                    </div>
                    <div class="col-md-7">
                      {{event?.country}}
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-md-3">
                      Address
                    </div>
                    <div class="col-md-2">
                      |
                    </div>
                    <div class="col-md-7">
                      {{event?.address}}
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-md-3">
                      Type
                    </div>
                    <div class="col-md-2">
                      |
                    </div>
                    <div class="col-md-7">
                      {{event?.type}}
                    </div>
                  </div>
                  <div *ngIf="event?.type === 'Other'" class="row mt-3">
                  <div class="col-md-3">
                    Other Type
                  </div>
                  <div class="col-md-2">
                    |
                  </div>
                  <div class="col-md-7">
                    {{ event?.otherType }}
                  </div>
                </div>
                  <div class="row mt-3">
                    <div class="col-md-3">
                      Time
                    </div>
                    <div class="col-md-2">
                      |
                    </div>
                    <div class="col-md-7">
                      {{event.timeOfEvent}}
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-md-3">
                      Date
                    </div>
                    <div class="col-md-2">
                      |
                    </div>
                    <div class="col-md-7">
                      {{event.dateOfEvent}}
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-md-3">
                      Status
                    </div>
                    <div class="col-md-2">
                      |
                    </div>
                    <div class="col-md-7">
                      <span *ngIf="event.statusEvent === 'Scheduled'">
                        <nb-tag status="success" text={{event.statusEvent}}></nb-tag>
                      </span>
                      <span *ngIf="event.statusEvent === 'Ongoing'">
                        <nb-tag status="info" text={{event.statusEvent}}></nb-tag>
                      </span>
                      <span *ngIf="event.statusEvent === 'Completed'">
                        <nb-tag status="basic" text={{event.statusEvent}}></nb-tag>
                      </span>
                      <span *ngIf="event.statusEvent === 'Postponed'">
                        <nb-tag status="warning" text={{event.statusEvent}}></nb-tag>
                      </span>
                      <span *ngIf="event.statusEvent === 'Cancelled'">
                        <nb-tag status="danger" text={{event.statusEvent}}></nb-tag>
                      </span>

                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-md-3">
                      Description
                    </div>
                    <div class="col-md-2">
                      |
                    </div>
                    <div class="col-md-7">
                      {{event.description}}
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-md-3">
                      Participants
                    </div>
                    <div class="col-md-2">
                      |
                    </div>
                    <div class="col-md-7">
                      {{event.participants}}
                    </div>
                  </div>

                </div>
              </nb-accordion-item-body>
            </nb-accordion-item>
          </nb-accordion>

        </div>
      </div>
      <div class="panel panel-default mt-3">
        <div class="panel-heading">


        </div>
      </div>
      <div class="panel panel-default mt-3">
        <div class="panel-heading">

          <nb-accordion>
            <nb-accordion-item>
              <nb-accordion-item-header><h6>Detail User </h6></nb-accordion-item-header>
              <nb-accordion-item-body>
                <div class="panel-body">

                  <div class="row">
                    <div class="col-md-3">
                      Name
                    </div>
                    <div class="col-md-2">
                      |
                    </div>
                    <div class="col-md-7">
                      <nb-user [name]="event?.user?.username"
                               [picture]="event?.user?.userProfileImage"
                               size="medium"></nb-user>

                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-md-3">
                      Email
                    </div>
                    <div class="col-md-2">
                      |
                    </div>
                    <div class="col-md-7">
                      {{event?.user?.email}}
                    </div>
                  </div>



                </div>
              </nb-accordion-item-body>
            </nb-accordion-item>
          </nb-accordion>

        </div>
      </div>
      <br>

      <div class="panel panel-default">
        <div class="panel-heading">

        </div>
      </div>
      <br>

      <!-- Latest posts -->


    </div>

  </div>
</div>
