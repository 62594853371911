import {Component, OnInit} from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import { SmartTableData } from '../../../../../@core/data/smart-table';
import {CrmTeamMemberService} from '../../../services/crmTeamMember/crm-team-member.service';
import {CrmTeamMember} from '../../../models/CrmTeamMember';
import {Employee} from '../../../../humain-capital-management/models/Employee';
import {EmployeeService} from '../../../../humain-capital-management/services/employeeServices/employee.service';
@Component({
  selector: 'ngx-crm-team-members',
  templateUrl: './crm-team-members.component.html',
  styleUrls: ['./crm-team-members.component.scss'],
})
export class CrmTeamMembersComponent implements OnInit {

  source: LocalDataSource = new LocalDataSource();
  employeeToStore: any;
  selectedEmployee: string = '';
  crmTeamMember: CrmTeamMember = new CrmTeamMember();
  list: Employee[] = [];

  settings = {
    add: {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmCreate: true,
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmSave: true,
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    columns: {
      projet: { title: 'PROJET', type: 'string' },
      coache: { title: 'COACHE', type: 'string' },
      coach: {
        title: 'COACH',
        type: 'html',
        editor: {
          type: 'list',
          config: {
            list: [],  // Cette liste doit être remplie avec les données des employés
          },
        },
        valuePrepareFunction: (cell, row) => cell ? cell.employeeName : 'N/A',
      },
      dateVisite: { title: 'DATE', type: 'Date' },
      heureVisite: { title: 'HEURE', type: 'string' },
      phone: { title: 'N° TELEPHONE DU COACHE', type: 'string' },
      duree: { title: 'DUREE VISITE', type: 'string' },
      address: { title: 'ADDRESS', type: 'string' },
      region: { title: 'REGION', type: 'string' },
    },
  };


  // Example of setting selectedEmployee
  onEmployeeSelected(employeeId: string): void {
    this.selectedEmployee = employeeId;
  }


  constructor(private service: CrmTeamMemberService, private es: EmployeeService) {
    // const data = this.service.getData();
    // this.source.load(data);
    this.loadCrmTeamMembers();
  }
  ngOnInit(): void {
    // employees data
    this.es.findAllCommercialsEmployees().subscribe(
      (employees: Employee[]) => {
        this.list = employees;
        this.updateCoachEditorOptions(employees);

      }, (err) => {
        console.log(err);
      },
    );

  }
  updateCoachEditorOptions(employees: Employee[]) {
    this.settings.columns.coach.editor.config.list = employees.map(emp => ({
      value: emp.id,  // This should be the identifier used for selection
      title: emp.employeeName,  // This is what will be displayed in the dropdown
    }));

    // Important: Trigger a settings update to refresh the table
    this.settings = Object.assign({}, this.settings);
  }

  onCreateConfirm(event): void {
    if (!event.newData.coach) {
      alert('Please select a coach.');
      event.confirm.reject();
      return;
    }

    this.service.addCrmTeamMember(event.newData, event.newData.coach).subscribe(
      data => {
        // Mettez à jour la table pour refléter les données du serveur au lieu d'ajouter localement
        this.loadCrmTeamMembers();  // Rechargez toutes les données depuis le serveur
        event.confirm.resolve();  // Confirmez l'ajout dans la table
      },
      error => {
        console.log('Error adding CRM team member:', error);
        event.confirm.reject();
      },
    );
  }



  loadCrmTeamMembers() {
    this.service.findAllCrmTeamMember().subscribe(
      (response: any) => {
        if (Array.isArray(response)) {
          this.source.load(response);
        } else {
          console.error('Expected an array of data:', response);
        }
      },
      error => {
        console.error('Error loading CRM team members:', error);
      },
    );
  }


  onEditConfirm(event): void {
    if (!event.newData.coach) {
      alert('Please select a coach.');
      event.confirm.reject();
      return;
    }

    this.service.updateCrmTeamMember(event.newData, event.newData.coach).subscribe(
      data => {
        // Assurez-vous que data contient l'objet modifié complet
        event.confirm.resolve(data); // Mettez à jour la ligne dans la table
        this.source.update(event.data, data);
      },
      error => {
        console.log('Error updating CRM team member:', error);
        event.confirm.reject();
      },
    );
  }
  onDeleteConfirm(event): void {
    if (window.confirm('Are you sure you want to delete?')) {
      this.service.deleteCrmTeamMember(event.data).subscribe(data => {
        event.confirm.resolve();
      });
    } else {
      event.confirm.reject();
    }
  }
}
