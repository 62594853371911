import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {finalize} from 'rxjs/operators';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {Observable} from 'rxjs';
import GoogleCountries from '../../../../humain-capital-management/services/googlecountries.json';
import {NbComponentStatus, NbGlobalPhysicalPosition, NbGlobalPosition, NbToastrService} from '@nebular/theme';
import {Event} from '../../../models/Event';
import {Users} from '../../../../auth/model/Users';
import {EventService} from '../../../services/events/event.service';
import {Router} from '@angular/router';
import {AuthService} from '../../../../auth/service/auth.service';
import {TokenStorageService} from '../../../../auth/service/token/token.service';
@Component({
  selector: 'ngx-add-event',
  templateUrl: './add-event.component.html',
  styleUrls: ['./add-event.component.scss'],
})
export class AddEventComponent implements OnInit {
  eventGroup: FormGroup = new FormGroup({});
  downloadURL: Observable<string>;
  fb = '';
  loading: boolean;
  selectedType;
  countries: any [] = [];
  options = [
    {key: 1, value: 'Scheduled'},
  ];
  selectedOption = this.options['Scheduled'];
  event: Event = new Event(); // Initialize your event model
  selectedUserId: string; // ID of the user assigned to the event (selected in your form)
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  preventDuplicates = false;
  destroyByClick = true;
  duration = 2000;
  index = 1;
  selectedUser: string = '';
  userGroup: FormGroup = new FormGroup({});
  addressForm: FormGroup = new FormGroup({});
  currentUser: Users;  // Define currentUser of type Users


  constructor( private ev: EventService,
               private router: Router,
               private storage: AngularFireStorage ,
               private toastrService: NbToastrService,
               private tokenStorageService: TokenStorageService,
               private us: AuthService) {

    this.eventGroup = new FormGroup({
      title: new FormControl(''),
      type: new FormControl(''),
      otherType: new FormControl(''),
      image: new FormControl(''),
      description: new FormControl(''),
      address: new FormControl(''),
      dateOfEvent: new FormControl(null),
      timeOfEvent: new FormControl(''),
      country: new FormControl(''),
      statusEvent: new FormControl(''),
      participants: new FormControl(0),
      archive: new FormControl(''),
      active: new FormControl(false),
    });
  }

  formatTime(date: Date): string {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;  // Converts "0" to "12"
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  }

  get title() {
    return this.eventGroup.get('title');
  }

  get type() {
    return this.eventGroup.get('type');
  }

  get assignee() {
    return this.eventGroup.get('assignee');
  }

  get otherType() {
    return this.eventGroup.get('otherType');
  }

  get image() {
    return this.eventGroup.get('image');
  }

  get description() {
    return this.eventGroup.get('description');
  }

  get address() {
    return this.eventGroup.get('address');
  }

  get dateOfEvent() {
    return this.eventGroup.get('dateOfEvent');
  }

  get timeOfEvent() {
    return this.eventGroup.get('timeOfEvent');
  }

  get country() {
    return this.eventGroup.get('country');
  }

  get statusEvent() {
    return this.eventGroup.get('statusEvent');
  }

  get user() {
    return this.eventGroup.get('user');
  }

  get participants() {
    return this.eventGroup.get('participants');
  }

  get archive() {
    return this.eventGroup.get('archive');
  }

  get active() {
    return this.eventGroup.get('active');
  }


  onEventFormSubmit() {
    this.eventGroup.markAsDirty();
  }

  onAddressFormSubmit() {
    this.addressForm.markAsDirty();
  }
  onUserFormSubmit() {
    this.userGroup.markAsDirty();
  }
  toggleLoadingAnimation(event) {
    this.loading = true;
    this.onFileSelected(event);
    setTimeout(() => this.loading = false, 3000);
  }
  ngOnInit(): void {
    this.us.getCurrentUser().subscribe(user => {
      this.currentUser = user;
      console.log('Current User Loaded:', this.currentUser);
    });
    this.countries = GoogleCountries;

  }

  onMenuItemSelectedUser(selectedOne) {
    this.selectedUser = selectedOne;
  }
  onFileSelected(event) {
    const n = Date.now();
    const file = event.target.files[0];
    const filePath = `events/${n}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(`events/${n}`, file);
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.downloadURL = fileRef.getDownloadURL();
          this.downloadURL.subscribe(url => {
            if (url) {
              this.fb = url;
            }
            this.event.image = this.fb;
            // this.event.image = this.fb;
          });
        }),
      )
      .subscribe(url => {
        if (url) {

        }
      });
  }


  addEventFunction() {
    console.log('Assignee before setting:', this.currentUser); // This will show what currentUser data is available at the time of method call

    if (!this.currentUser ) {
      console.error('No user data available or selectedUserId is undefined');
      return;  // Exit the function if no user data is available or userId is undefined
    }

    this.event.timeOfEvent = this.formatTime(new Date(this.event.timeOfEvent));

    // Set assignee object to the current user details
    this.event.user = {
      id: this.currentUser.id,
      username: this.currentUser.username,
      email: this.currentUser.email,
      userProfileImage: this.currentUser.userProfileImage,
    },

    this.event.image = this.fb, // Set image data
      console.log('Attempting to add event with ID:', this.currentUser.id);
    this.ev.addEvent(this.event, this.currentUser.id).subscribe({
      next: (result) => {
        this.showToast('success', 'Add ! ', 'Event Added !!');
        this.router.navigateByUrl('/crm/events').then(() => {
          this.router.navigate(['/crm/events']);
        });
      },
      error: (err) => {
        this.showToast('danger', 'Add ! ', err);
        console.error('Error adding event:', err);
      },
    });
  }

  onMenuItemSelected(selectedOne) {
    this.selectedOption = selectedOne;
  }


  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: this.destroyByClick,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
    };
    const titleContent = title ? ` ${title}` : '';

    this.index += 1;
    this.toastrService.show(
      body,
      `${titleContent}`,
      config);
  }

  onError($event: boolean) {
  }
}
