<div *ngIf="customer" class="container">
  <div class="row">
    <div class="col-md-10 col-sm-12">
      <h2 class="more-vertical">
        Customer Details </h2>
    </div>

    <div align="right" class="col-md-2 justify-content-end">
      <a [routerLink]="['/crm/edit-customer',customer.id]" class="w-25" status="warning"
         fullWidth nbButton nbTooltip="Click here to edit customer data" nbTooltipStatus="primary" shape="round">
        <nb-icon icon="edit-outline"></nb-icon>
      </a>
      <a [routerLink]="['/crm/customers']" class="w-25 mx-3"
         fullWidth nbButton nbTooltip="Back to customers list" 
         nbTooltipStatus="primary" shape="round" status="basic">
        <nb-icon icon="corner-up-right-outline"></nb-icon>
      </a>

    </div>

  </div>
  <div class="row mt-3">
    <div class="col-md-4 mb-3">
      <nb-card>
        <nb-card-body class="card-body">
          <div class="d-flex flex-column align-items-center text-center">
            <div class="row">

              <img alt="" src="{{customer.image}}" style="width: 150px;height: 150px;border-radius: 100%;">
            </div>

            <div class="mt-3">
              <h4>{{customer.name}}</h4>
              <div class="row">
                <div class="col-md-2">
                </div>
                <div class="col-md-8">
                     <nb-tag-list>
                  <nb-tag [text]="customer.status" status="danger" appearance="filled"></nb-tag>
                </nb-tag-list>
                </div>
              </div>
               <div class="row mt-3">
              <div class="col-md-3">
                 <button nbButton 
                        size="small"
                        shape="round"
                        status="danger">
                  <nb-icon icon="pin-outline"></nb-icon>
                </button>
              </div>
              <div class="col-md-7">
                <p class="mt-1 mx-2">  {{customer.address}}{{customer.city}}{{customer.country}} </p>
              </div>
            </div>
           
            </div>
          </div>
        </nb-card-body>
      </nb-card>
      <nb-card class="">
        <div class="col-sm-6">

          <div class="card-body">
            <h6 class="d-flex align-items-center mb-3">Contact</h6>
            <div class="row">
              <div class="col-md-3">
                <button nbButton 
                        size="small"
                        shape="round"
                        status="danger">
                  <nb-icon icon="phone-outline"></nb-icon>
                </button>
              </div>
              <div class="col-md-7">
                <p class="mt-1 mx-2"> {{customer.phone}}</p>
              </div>
            </div>
            <div class="row mt-2" *ngIf="customer.secondPhone">
              <div class="col-md-3">
                 <button nbButton 
                        size="small"
                        shape="round"
                        status="danger">
                  <nb-icon icon="phone-outline"></nb-icon>
                </button>
              </div>
              <div class="col-md-7">
                <p class="mt-1 mx-2" >  {{customer.secondPhone}} </p>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-3">
                 <button nbButton 
                        size="small"
                        shape="round"
                        status="danger">
                  <nb-icon icon="at-outline"></nb-icon>
                </button>
              </div>
              <div class="col-md-7">
                <p class="mt-1 mx-2">  {{customer.workEmail}} </p>
              </div>
            </div>
<div class="row mt-2" *ngIf="customer.secondEmail">
              <div class="col-md-3">
                 <button nbButton 
                        size="small"
                        shape="round"
                        status="danger">
                  <nb-icon icon="at-outline"></nb-icon>
                </button>
              </div>
              <div class="col-md-7">
                <p class="mt-1 mx-2" >  {{customer.secondEmail}} </p>
              </div>
            </div>
          </div>

        </div>

      </nb-card>

    </div>
    <div class="col-md-8">
      <nb-accordion>
        <nb-accordion-item expanded="true">
          <nb-accordion-item-header>Personal Information</nb-accordion-item-header>
          <nb-accordion-item-body>
                <div class="row">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Fiscal number</p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{customer.matriculateFiscal}}
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Title</p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{customer.title}}
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Full Name</p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{customer.name}}
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Address</p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{customer.address}},{{customer.city}}  {{customer.country }}
              </div>
            </div>
        <div class="row" [hidden]="customer.gender !== 'Man' || customer.gender!== 'Women'">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Gender</p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{customer.gender}}
              </div>
            </div>
            <hr>
            <div class="row" [hidden]="customer.gender !== 'Other'">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Gender</p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{customer.otherGender}}
              </div>
            </div>
            <hr>   
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Date of birth</p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{customer.dateOfBirth | date : "MMM dd YYYY"}}
              </div>
            </div>
            <hr>
       

          </nb-accordion-item-body>
        </nb-accordion-item>
      </nb-accordion>
      <br>
      <nb-accordion>
        <nb-accordion-item expanded="true">
          <nb-accordion-item-header>Contact Information</nb-accordion-item-header>
          <nb-accordion-item-body>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Email Address</p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{customer.workEmail}}
              </div>
            </div>
             <hr>
             <div class="row" [hidden]="customer.secondEmail == null ">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Second email</p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{customer.secondEmail}}
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Cell Phone</p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{customer.phone }}
              </div>
            </div>
            <hr>
             <div class="row" [hidden]="customer.secondPhone == null ">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Second phone</p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{customer.secondPhone}}
              </div>
            </div>
            <hr>   
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Website</p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{customer.workWebsite }}
              </div>
            </div>
            <hr>
            <div class="row" >
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Linkedin</p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{customer.linkedinUrl }}
              </div>
            </div>
            <hr> 
            
            <div class="row" [hidden]="customer.contactType == null ">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Contact Type</p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{customer.contactType }}
              </div>
            </div>
            <hr>
            <div class="row" [hidden]="customer.description == null ">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Description</p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{customer.description}}<br>
              </div>
            </div>
            <hr>
          </nb-accordion-item-body>
        </nb-accordion-item>
      </nb-accordion>
      <br>

      <nb-accordion>
        <nb-accordion-item>
          <nb-accordion-item-header>Security Information</nb-accordion-item-header>
          <nb-accordion-item-body>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Social Security</p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{customer.active}}
                <span *ngIf="customer.active === null">
                      This field is not fill it yet.
                    </span>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Creation </p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{customer.createdAt | date: 'YYYY MMM dd'}} at
                {{customer.createdAt | date: 'HH:MM'}}


              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Last modification </p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{customer.modifiedAt | date: 'YYYY MMM dd'}} at
                {{customer.modifiedAt | date: 'HH:MM'}}


              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Customer Status</p>
              </div>
              <nb-tag-list>
                <nb-tag *ngIf="customer.status === 'Lead' " [text]="customer.status" size="small"
                        status="basic"></nb-tag>
                <nb-tag *ngIf="customer.status === 'Prospect' " [text]="customer.status" size="small"
                        status="danger"></nb-tag>
                <nb-tag *ngIf="customer.status === 'Client' " [text]="customer.status" size="small"
                        status="primary"></nb-tag>

              </nb-tag-list>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Assignee</p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{customer?.assignee?.employeeName}}
                <span *ngIf="customer?.assignee?.employeeName === 0">
                      This employee does not have any kids.
                    </span>
              </div>
            </div>
            <hr>
              <div class="row">
              <div class="col-sm-3" *ngIf="customer.status =='Lead'">
                <p class="mb-0 text-sm">Added by</p>
              </div> 
                <div class="col-sm-3" *ngIf="customer.status =='Client'">
                <p class="mb-0 text-sm">Approved by</p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{customer.user}}
              </div>
            </div>
            <hr>
          </nb-accordion-item-body>
        </nb-accordion-item>
      </nb-accordion>
      <br>
    </div>
  </div>


  <div class="row"  >
    <div class="col-12">
      <h6 class="d-flex align-items-center mb-3">Deals</h6>

      <nb-card size="small">


 <ngx-deals-customer></ngx-deals-customer>
      </nb-card>
    </div>
  </div>
  <div class="row" >
    <div class="col-md-12 col-lg-12">
      <h6 class="d-flex align-items-center mb-3">Visits</h6>

     <nb-card size="small">

   
            <div>
              <table 
                class="table mb-0  active">
                <thead class="">
                <tr>
                  <th><p class="fw-normal mb-1">refVisit</p></th>
                  <th><p class="fw-normal mb-1">title</p></th>
                  <th><p class="fw-normal mb-1">Date Visit</p></th>
                  <th><p class="fw-normal mb-1">Address Visit</p></th>
                  <th><p class="fw-normal mb-1">City</p></th>
                  <th><p class="fw-normal mb-1">Status</p></th>
                  <th><p class="fw-normal mb-1">Employee</p></th>
                </tr>
                </thead>
                <tbody *ngFor="let visit of visits |  paginate : {itemsPerPage:pageSize, currentPage: current,totalItems: visits.length} " >
                <td><p class="fw-normal mb-1">{{visit.refVisit}}</p></td>
                <td><p class="fw-normal mb-1">{{visit.title}}</p></td>
                <td><p class="fw-normal mb-1">{{visit.dateVisit | date :'MM-DD-YYYY'}}</p></td>
                <td><p class="fw-normal mb-1">{{visit?.property?.propertyCountry}}</p></td>
                <td><p class="fw-normal mb-1">{{visit?.property?.propertyCity}}</p></td>
                <td>  <span *ngIf="visit.status === 'doing'"
                            class="mb-2 badge badge-warning badge-pill">{{visit.status}}</span>
                  <span *ngIf="visit.status === 'DONE'"
                        class="mb-2 badge badge-success badge-pill">{{visit.status}}</span>
                  <span *ngIf="visit.status === 'UPCOMING'"
                        class="mb-2 badge badge-light badge-pill">{{visit.status}}</span>
                </td>
                <td><p class="fw-normal mb-1">{{visit?.employee?.employeeName}}</p></td>


                
                </tbody>
              </table>
            </div>
      <div class="d-flex justify-content-center ">
     <nb-card class="card ml-auto mr-auto" style="height: 30px; border-radius: 25px;">
        <pagination-controls (pageChange)="current = $event" align="right"></pagination-controls>

     </nb-card>
    </div>
      </nb-card>
    </div>
  </div>
    <div class="col-md-12 col-lg-12" >
      <h6 class="d-flex align-items-center mb-3">Contracts</h6>
      <nb-card size="small">
        
           <div>
              <table 
                class="table mb-0  active">
                <thead class="">
                <tr>
                  <th><p class="fw-normal mb-1">Contract code</p></th>
                  <th><p class="fw-normal mb-1">Contract name</p></th>
                  <th><p class="fw-normal mb-1">Date contract</p></th>
                  <th><p class="fw-normal mb-1">Contract Type</p></th>
                  <th><p class="fw-normal mb-1">Duration</p></th>
                  <th><p class="fw-normal mb-1">State</p></th>
                  <th><p class="fw-normal mb-1">Final price</p></th>
                </tr>
                </thead>
                <tbody *ngFor="let contract of dataContracts | paginate : {itemsPerPage:pageSizeContracts, currentPage: currentContracts,totalItems: dataContracts.length} " >
            
                <td><p class="fw-normal mb-1">{{contract.contractCode}}</p></td>
                <td><p class="fw-normal mb-1">{{contract.contractName}}</p></td>
                <td><p class="fw-normal mb-1">{{contract.dateContract | date :'MMMM EEEE YYYY'}}</p></td>
                <td><p class="fw-normal mb-1">{{contract.contractType}}</p></td>
                <td><p class="fw-normal mb-1">{{contract.duration}}</p></td>
                <td><p class="fw-normal mb-1">  {{contract.state}} </p></td>
                <td><p class="fw-normal mb-1">  {{contract.finalPrice}} </p></td>


                
                </tbody>
              </table>
            </div>
        <div class="d-flex justify-content-center ">
     <nb-card class="card ml-auto mr-auto" style="height: 30px; border-radius: 25px;">
        <pagination-controls (pageChange)="currentContracts = $event" align="right"></pagination-controls>

     </nb-card>
    </div>

   
      </nb-card>
    </div>


</div>