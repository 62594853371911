<link href="//maxcdn.bootstrapcdn.com/bootstrap/4.1.1/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css">
<script src="//maxcdn.bootstrapcdn.com/bootstrap/4.1.1/js/bootstrap.min.js"></script>
<script src="//cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
<!DOCTYPE html>
<html>
<head>
  <title>Chatbot</title>
  <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css" integrity="sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO" crossorigin="anonymous">
  <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.5.0/css/all.css" integrity="sha384-B4dIYHKNBt8Bc12p+WXckhzcICo0wtJAoU8YZTY5qE0Id1GSseTk6S+L3BlXeVIU" crossorigin="anonymous">
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
  <link rel="stylesheet" type="text/css"/>
</head>

<body style="background-color: transparent">
<div class="container-fluid h-100" ng-app="chatbotApp" ng-controller="ChatbotController">
  <div class="row justify-content-center h-100">
    <div class="col-md-8 col-xl-6 chat">
      <div class="card">
        <div class="card-header msg_head">
          <div class="d-flex bd-highlight">
            <div class="img_cont">
              <img src="https://i.ibb.co/fSNP7Rz/icons8-chatgpt-512.png" class="rounded-circle user_img">
              <span class="online_icon"></span>
            </div>
            <div class="user_info">
              <span>ChatBot</span>
              <p>Ask me anything!</p>
            </div>
          </div>
        </div>
        <div id="messageFormeight" class="card-body msg_card_body">
          <div *ngFor="let message of messages" class="d-flex mb-4" [ngClass]="{'justify-content-end': message.sender === 'user', 'justify-content-start': message.sender === 'bot'}">
            <div *ngIf="message.sender === 'user'" class="d-flex justify-content-end mb-4">
              <div class="msg_cotainer_send">{{ message.text }}<span class="msg_time_send">{{ message.time }}</span></div>
              <div class="img_cont_msg"><img [src]="message.userPhoto" class="rounded-circle user_img_msg"></div>
            </div>
            <div *ngIf="message.sender === 'bot'" class="d-flex justify-content-start mb-4">
              <div class="img_cont_msg"><img src="https://i.ibb.co/fSNP7Rz/icons8-chatgpt-512.png" class="rounded-circle user_img_msg"></div>
              <div class="msg_cotainer">{{ message.text }}<span class="msg_time">{{ message.time }}</span></div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <form (ngSubmit)="sendMessage()" class="input-group">
            <input [(ngModel)]="userMessage" name="msg" placeholder="Type your message..." autocomplete="off" class="form-control type_msg" required/>
            <div class="input-group-append">
              <button type="submit" class="input-group-text send_btn"><i class="fas fa-location-arrow"></i></button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

</body>
</html>
