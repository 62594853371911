import {Component, OnInit} from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import {CrmTeamObjectiveService} from '../../../services/crmTeamObjective/crm-team-objective.service';
import {CrmTeamObjective} from '../../../models/CrmTeamObjective';
@Component({
  selector: 'ngx-crm-team-objectives',
  templateUrl: './crm-team-objectives.component.html',
  styleUrls: ['./crm-team-objectives.component.scss'],
})
export class CrmTeamObjectivesComponent {
  source: LocalDataSource = new LocalDataSource();
  crmTeamObjective: CrmTeamObjective = new CrmTeamObjective();

  settings = {
    add: {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmCreate: true,
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmSave: true,
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    columns: {
      representantCommercial: { title: 'REPRESENTANT COMMERCIAL', type: 'string' },
      objectifGain: { title: 'OBJECTIFS DE GAIN POUR 2024 (DINARS)', type: 'string' },
    },
  };




  constructor(private service: CrmTeamObjectiveService) {
    // const data = this.service.getData();
    // this.source.load(data);
    this.loadCrmTeamObjectives();
  }

  onCreateConfirm(event): void {

    this.service.addCrmTeamObjective(event.newData).subscribe(
      data => {
        // Mettez à jour la table pour refléter les données du serveur au lieu d'ajouter localement
        this.loadCrmTeamObjectives();  // Rechargez toutes les données depuis le serveur
        event.confirm.resolve();  // Confirmez l'ajout dans la table
      },
      error => {
        console.log('Error adding CRM team Objective:', error);
        event.confirm.reject();
      },
    );
  }

  updateTotalAndLoadData(response: any) {
    response = response.filter(item => item.representantCommercial !== 'Total Objectives for the Team');
    // Calculate total gains
    const totalGain = response.reduce((acc, curr) => {
      const gain = parseFloat((curr.objectifGain || '0').replace(/,/g, ''));
      return acc + gain;
    }, 0);

    // Create a total line object
    const totalLine = {
      representantCommercial: 'Total Objectives for the Team',
      objectifGain: totalGain, // Format the total as needed
    };

    // Add this line to the end of the data array
    response.unshift(totalLine);

    // Load the updated array into the data source
    this.source.load(response);
  }


  loadCrmTeamObjectives() {
    this.service.findAllCrmTeamObjective().subscribe(
      (response: any) => {
        if (Array.isArray(response)) {
          this.updateTotalAndLoadData(response);
        } else {
          console.error('Expected an array of data:', response);
        }
      },
      error => {
        console.error('Error loading CRM team Objectives:', error);
      },
    );
  }




  onEditConfirm(event): void {
    this.service.updateCrmTeamObjective(event.newData).subscribe(
      data => {
        // Assurez-vous que data contient l'objet modifié complet
        event.confirm.resolve(data); // Mettez à jour la ligne dans la table
        this.source.update(event.data, data);
      },
      error => {
        console.log('Error updating CRM team Objective:', error);
        event.confirm.reject();
      },
    );
  }
  onDeleteConfirm(event): void {
    if (window.confirm('Are you sure you want to delete?')) {
      this.service.deleteCrmTeamObjective(event.data).subscribe(data => {
        event.confirm.resolve();
        this.loadCrmTeamObjectives();
      });
    } else {
      event.confirm.reject();
    }
  }
}
