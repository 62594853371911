import {Component, OnInit} from '@angular/core';
import {VisitService} from '../../../services/visits/visit.service';
import {
  NbComponentStatus,
  NbGlobalPhysicalPosition,
  NbGlobalPosition,
  NbToastrConfig,
  NbToastrService,
} from '@nebular/theme';
import {Router} from '@angular/router';
import {ExportService} from '../../../../../shared/exports/export.service';
import {EmployeeService} from '../../../../humain-capital-management/services/employeeServices/employee.service';
import {CustomerService} from '../../../services/customers/customer.service';
import GoogleCountries from '../../../../humain-capital-management/services/googlecountries.json';
import {EventService} from '../../../services/events/event.service';
import {Event} from '../../../models/Event';
import {Visit} from '../../../models/visit';
import {Observable} from 'rxjs';

@Component({
  selector: 'ngx-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
})
export class EventsComponent implements OnInit {

  search: string;
  countries: any [] = [];
  config: NbToastrConfig;
  index = 1;
  destroyByClick = true;
  duration = 2000;
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  preventDuplicates = false;
  // getting customers per page
  pageSize: number = 4;
  // current number page for pagination
  current: number = 1;
  // toast alert notification
  dataClaims: string ;
  events$: Observable<Event[]>;
  dataEvents: Event [] = [];
  selectedSortOrder: string = 'All';
  constructor(private toastrService: NbToastrService,
              private router: Router,
              private exportService: ExportService,
              private ev: EventService) {
  }

ngOnInit(): void {
  this.loadAllEvents();
  this.getAllEvent();
  this.countries = GoogleCountries;
}


  getEventsByStatus(e) {
    this.filterByStatus(e);
  }

  getEventsByType(e) {
    this.filterByType(e);
  }

  filterByType(e) {
    this.ev.findNonArchivedEvents().subscribe(
      (data: Event[]) => {
        this.dataEvents = [];
        this.dataEvents = data.filter(
          (v =>
              v.type === e
          ),
        );
      }, (err) => {
        return err;
      });
  }
  filterByStatus(e) {
    this.ev.findNonArchivedEvents().subscribe(
      (data: Event[]) => {
        this.dataEvents = [];
        this.dataEvents = data.filter(
          (v =>
              v.statusEvent === e
          ),
        );
      }, (err) => {
        return err;
      });
  }


  getEventsTitleAsc() {
    this.ev.getEventAscName().subscribe(
      (data: Event[]) => {
        this.dataEvents = data;

      }, (err) => {
        console.log(err);
      },
    );
  }

  getEventsCreatedDesc() {
    this.ev.getEventDescCreatedAt().subscribe(
      (data: Event[]) => {
        this.dataEvents = data;

      }, (err) => {
        console.log(err);
      },
    );
  }
  // sort data prospect descending created At date
  getEventsCreatedAsc() {
    this.ev.getEventAscCreatedAt().subscribe(
      (data: Event[]) => {
        this.dataEvents = data;

      }, (err) => {
        console.log(err);
      },
    );
  }

  getEventsTitleDesc() {
    this.ev.getEventDescName().subscribe(
      (data: Event[]) => {
        this.dataEvents = data;

      }, (err) => {
        console.log(err);
      },
    );
  }
  getEventsByAddress(e, x) {
    this.filtreEventsByCountry(e, x);
  }

  filtreEventsByCountry(value: string, type: string) {
    this.ev.findNonArchivedEvents().subscribe(
      (data: Event[]) => {
          this.dataEvents = data.filter(event => event.country === value);
      }, (error) => {
        console.error('Error fetching events:', error);
      });
  }

  loadAllEvents(): void {
    this.ev.findAllEvents().subscribe({
      next: (events) => {
        this.dataEvents = events;
      },
      error: (error) => {
        this.showToast('danger', 'Failed to Load Events', 'Could not fetch events from server');
        console.error('Error loading events:', error);
      },
    });
  }

  onArchiveConfirm(event: Event, id: string) {
      this.ev.archiveEvent(event, id).subscribe(
      () => {
        this.showToast('success', 'Archived Successfully',
          'Event Archived !!');
        this.getAllEvent();
        this.router.navigate(['/crm/events']).then(() => {
          this.getAllEvent();
        });
      });
  }



  getAllEvent() {
    this.ev.findNonArchivedEvents()
      .subscribe(
        (data: any[]) => {
          this.dataEvents = data;
        },
      );
  }
  exportAsXLSX(): void {
    this.exportService.exportAsExcelFile(this.dataEvents, 'dataEvents');
  }
  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: this.destroyByClick,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
    };
    const titleContent = title ? ` ${title}` : '';

    this.index += 1;
    this.toastrService.show(
      body,
      `${titleContent}`,
      config);
  }

}
