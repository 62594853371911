import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {finalize} from 'rxjs/operators';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {Observable} from 'rxjs';
import GoogleCountries from '../../../../humain-capital-management/services/googlecountries.json';
import {NbComponentStatus, NbGlobalPhysicalPosition, NbGlobalPosition, NbToastrService} from '@nebular/theme';
import {Event} from '../../../models/Event';
import {Users} from '../../../../auth/model/Users';
import {EventService} from '../../../services/events/event.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../../auth/service/auth.service';
import {TokenStorageService} from '../../../../auth/service/token/token.service';
import {HttpErrorResponse} from '@angular/common/http';


@Component({
  selector: 'ngx-edit-event',
  templateUrl: './edit-event.component.html',
  styleUrls: ['./edit-event.component.scss'],
})
export class EditEventComponent implements OnInit {
  eventGroup: FormGroup = new FormGroup({});
  downloadURL: Observable<string>;
  fb = '';
  loading: boolean;
  selectedType;
  countries: any [] = [];
  options = [
    {key: 1, value: 'Scheduled'},
    {key: 2, value: 'Ongoing'},
    {key: 3, value: 'Completed'},
    {key: 4, value: 'Postponed'},
    {key: 5, value: 'Cancelled'},
  ];
  selectedOption = this.options['Scheduled , Ongoing , Completed , Postponed , Cancelled'];
  event: Event = new Event(); //
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  preventDuplicates = false;
  destroyByClick = true;
  duration = 2000;
  dataEvents: Event [] = [];
  index = 1;
  selectedUser: string = '';
  idUri = '';
  userGroup: FormGroup = new FormGroup({});
  addressForm: FormGroup = new FormGroup({});
  currentUser: Users;  // Define currentUser of type Users
  constructor( private ev: EventService,
               private router: Router,
               private storage: AngularFireStorage ,
               private toastrService: NbToastrService,
               private activatedroute: ActivatedRoute,
               private us: AuthService) {

    this.eventGroup = new FormGroup({
      title: new FormControl(''),
      type: new FormControl(''),
      otherType: new FormControl(''),
      image: new FormControl(''),
      description: new FormControl(''),
      address: new FormControl(''),
      dateOfEvent: new FormControl(null),
      timeOfEvent: new FormControl(''),
      country: new FormControl(''),
      statusEvent: new FormControl(''),
      participants: new FormControl(0),
      archive: new FormControl(''),
      active: new FormControl(false),
    });
  }

  get title() {
    return this.eventGroup.get('title');
  }

  get type() {
    return this.eventGroup.get('type');
  }

  get assignee() {
    return this.eventGroup.get('assignee');
  }

  get otherType() {
    return this.eventGroup.get('otherType');
  }

  get image() {
    return this.eventGroup.get('image');
  }

  get description() {
    return this.eventGroup.get('description');
  }

  get address() {
    return this.eventGroup.get('address');
  }

  get dateOfEvent() {
    return this.eventGroup.get('dateOfEvent');
  }

  get timeOfEvent() {
    return this.eventGroup.get('timeOfEvent');
  }

  get country() {
    return this.eventGroup.get('country');
  }

  get statusEvent() {
    return this.eventGroup.get('statusEvent');
  }

  get user() {
    return this.eventGroup.get('user');
  }

  get participants() {
    return this.eventGroup.get('participants');
  }

  get archive() {
    return this.eventGroup.get('archive');
  }

  get active() {
    return this.eventGroup.get('active');
  }


  onEventFormSubmit() {
    this.eventGroup.markAsDirty();
  }

  onAddressFormSubmit() {
    this.addressForm.markAsDirty();
  }
  onUserFormSubmit() {
    this.userGroup.markAsDirty();
  }
  toggleLoadingAnimation(event) {
    this.loading = true;
    this.onFileSelected(event);
    setTimeout(() => this.loading = false, 3000);
  }
  ngOnInit(): void {
    this.countries = GoogleCountries;

    // First, get the current user information.
    this.us.getCurrentUser().subscribe(user => {
      this.currentUser = user;
      console.log('Current User Loaded:', this.currentUser);

      // After the current user is loaded, proceed to load event data.
      this.activatedroute.paramMap.subscribe(result => {
        this.idUri = result.get('id');
        if (this.idUri) {
          this.loadEventData(this.idUri);
        }
      });
    }, error => {
      console.error('Failed to load user data:', error);
    });
  }

  loadEventData(id: string) {
    this.ev.getEventByID(id).subscribe(data => {
      this.event = data;
      // Now the user data is guaranteed to be loaded before the event is fetched
      // Additional setup or methods to handle event data can be called here if necessary
    }, error => {
      console.error('Failed to load event data:', error);
    });
  }

  formatTime(date: Date): string {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;  // Converts "0" to "12"
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  }
  updateEvent() {
    if (!this.currentUser) {
      this.showToast('warning', 'User Error', 'Current user data is not loaded.');
      return;
    }

    this.event.timeOfEvent = this.formatTime(new Date(this.event.timeOfEvent));

    // Update user details in the event object
    this.event.user = {
      id: this.currentUser.id,
      username: this.currentUser.username,
      email: this.currentUser.email,
      userProfileImage: this.currentUser.userProfileImage,
    };

    this.event.user = {...this.currentUser};
    this.ev.updateEvent(this.event, this.currentUser.id).subscribe(
      result => {
        this.showToast('success', 'Update!', 'Event Updated Successfully!!');
        this.router.navigateByUrl('/crm/events');
      },
      (err: HttpErrorResponse) => {
        console.error('Error during event update:', err.message);
        this.showToast('danger', 'Update Failed', err.error.message);
      },
    );
  }


  onFileSelected(event) {
    const n = Date.now();
    const file = event.target.files[0];
    const filePath = `events/${n}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(`events/${n}`, file);
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.downloadURL = fileRef.getDownloadURL();
          this.downloadURL.subscribe(url => {
            if (url) {
              this.fb = url;
            }
            this.event.image = this.fb;
            // this.event.image = this.fb;
          });
        }),
      )
      .subscribe(url => {
        if (url) {

        }
      });
  }

  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: this.destroyByClick,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
    };
    const titleContent = title ? ` ${title}` : '';

    this.index += 1;
    this.toastrService.show(
      body,
      `${titleContent}`,
      config);
  }
}
