
  <div class="d-flex flex-column flex-md-row mb-3 mb-md-0">
    <div class="col-md-3">
      <h2>Visits</h2>
    </div>
    <div class="col-md-5">
   
    </div>
    
    <div class="btn-toolbar col-md-4" role="toolbar">

      <div class="mr-auto mr-md-2 btn-group" role="group">
          <a [routerLink]="['/crm/grid-visits']" aria-current="page"
           class=" mx-1 align-self-center active text-white"
           id="tooltipShowSimplGrid" nbTooltip="Visualize data on keypad "
           nbTooltipStatus="primary" nbButton status="primary">
          <nb-icon icon="grid-outline"></nb-icon>
        </a>
          <a [routerLink]="['/crm/visits']" aria-current="page"
           class=" mx-1 align-self-center active text-white"
           id="tooltipShowGrid" nbTooltip="Visualize data on keypad "
           nbTooltipStatus="primary" nbButton status="warning">
          <nb-icon icon="keypad-outline"></nb-icon>
        </a>
        <a [routerLink]="['/crm/list-visits']" nbButton status="basic"
           class="mx-1 align-self-center"
           id="tooltipShowList"
           nbTooltip="Visualize data on list "
           nbTooltipStatus="primary" >
          <nb-icon icon="list-outline">
          </nb-icon>
        </a>
        
 <a [routerLink]="['/crm/calendar-visits']" aria-current="page"
           class=" mx-1 align-self-center " nbButton status="danger"
           id="tooltipShowCalendar" nbTooltip="Visualize data on calendar "
           nbTooltipStatus="primary" >
          <nb-icon icon="calendar-outline"></nb-icon>
        </a>
      
        <a (click)="exportAsXLSX()" aria-current="page" class=" mx-1 align-self-center active"
           id="tooltipExport" nbTooltip="Export data to excel file " status="success"
           nbTooltipStatus="primary" nbButton 
           > <i class="fas fa-file-excel text-white"></i>
        </a>
        <button [routerLink]="['/crm/add-visit']"
                aria-current="page" class=" mx-1 align-self-center active" nbButton status="primary"
                id="tooltipAddDeal" nbTooltip="Click here to create a new visit"
                nbTooltipStatus="primary"><nb-icon icon="plus-outline"></nb-icon> Add
        </button>
      </div>
    </div>
  </div>
 
  
<div class="row text-center mt-3">
    <div class="col-md-4">
      <div class="btn-group">
        <button
          nbButton
          status="basic"
          mwlCalendarPreviousView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="closeOpenMonthViewDay()"
        >
           <nb-icon icon="arrow-ios-back-outline"></nb-icon> Prev
        </button>
        &nbsp;
        <button
          nbButton
          status="primary"
          mwlCalendarToday
          [(viewDate)]="viewDate"
        >
          Today 
        </button>
        &nbsp;
        <button
          nbButton
          status="basic"
          mwlCalendarNextView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="closeOpenMonthViewDay()"
        >
          Next <nb-icon icon="arrow-ios-forward-outline"></nb-icon>
        </button>
      </div>
    </div>
    <div class="col-md-4">
      <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
    </div>
    <div class="col-md-4">
      <div class="btn-group">
        <div
          class="btn btn-primary"
          (click)="setView(CalendarView.Month)"
          [class.active]="view === CalendarView.Month"
        >
          Month
        </div>
        <div
          class="btn btn-primary"
          (click)="setView(CalendarView.Week)"
          [class.active]="view === CalendarView.Week"
        >
          Week
        </div>
        <div
          class="btn btn-primary"
          (click)="setView(CalendarView.Day)"
          [class.active]="view === CalendarView.Day"
        >
          Day
        </div>
      </div>
    </div>
  </div>
  <br />
  <nb-card>
    
<ng-template #customCellTemplate let-day="day" let-locale="locale">
  <div class="cal-cell-top">
    <span class="cal-day-badge" *ngIf="day.badgeTotal > 0"
      >{{ day.badgeTotal }}</span
    >
    <span class="cal-day-number"
      >{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span
    >
  </div>
  <small style="margin: 5px" *ngIf="day.events.length !=0"
    >There are {{ day.events.length }} visits on this day</small
  >
</ng-template>
    <div [ngSwitch]="view" >
            <mwl-calendar-month-view
            style="color: black;"
            *ngSwitchCase="CalendarView.Month"
            [viewDate]="viewDate"
            [events]="events"
            [refresh]="refresh"
             [cellTemplate]="customCellTemplate"
            [activeDayIsOpen]="activeDayIsOpen"
            (dayClicked)="dayClicked($event.day)"
            (eventClicked)="handleEvent('Clicked', $event.event)"
            (eventTimesChanged)="eventTimesChanged($event)"
        >
        </mwl-calendar-month-view>

        
        
        <mwl-calendar-week-view
        style="color: black;"
        *ngSwitchCase="CalendarView.Week"
        [viewDate]="viewDate"
        [events]="events"
        [refresh]="refresh"
        (eventClicked)="handleEvent('Clicked', $event.event)"
        (eventTimesChanged)="eventTimesChanged($event)"
        >
        </mwl-calendar-week-view>
        <mwl-calendar-day-view
        style="color: black;"
        *ngSwitchCase="CalendarView.Day"
        [viewDate]="viewDate"
        [events]="events"
        [refresh]="refresh"
        (eventClicked)="handleEvent('Clicked', $event.event)"
        (eventTimesChanged)="eventTimesChanged($event)"
        >
        </mwl-calendar-day-view>
    </div>
  </nb-card>
 