<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet">
<div class="content">
  <div class="container">

    <nb-card-header>
      <div class="row">
        <div class="col-md-5">
          <h3>Prospects archived</h3>
        </div>
        <div class="col-md-6">
          <div class="row">
            <input [(ngModel)]="search"
                   nbInput
                   nbTooltip="Tap here to search your customer" nbTooltipStatus="primary" placeholder="search customer"
                   type="text">
            <nb-select (selectedChange)="getCustomersByStatus($event)"
                       class="mx-1" placeholder="Filter by Status"   nbTooltip="Tap here to filter your customer by status" nbTooltipStatus="primary" 
                       selected="0">
              <nb-option (click)="getAllCustomers()" value="All">All</nb-option>
              <nb-option value="Prospect">Prospect</nb-option>
              <nb-option value="Lead">Lead</nb-option>
              <nb-option value="Client">Client</nb-option>
            </nb-select>
            <nb-select [disabled]="dataCustomers.length === 0"
                       placeholder="Sort by name"   nbTooltip="Tap here to sort by name your customer" nbTooltipStatus="primary" 
                       selected="0">
              <nb-option (click)="getAllCustomers()" value="All">All</nb-option>
              <nb-option (click)="getCustomersAsc()" value="1">Names (A-Z)</nb-option>
              <nb-option (click)="getCustomersDesc()" value="2">Names (Z-A)</nb-option>
            </nb-select>
          </div>
        </div>
        <div class="col-md-1">
          <div>
                        <button (click)="exportAsXLSX()"
                    align="right" class="btn "
                    nbTooltip="Click here to export data to Excel" nbTooltipStatus="primary"
                    style="background-color: #008000">
              <i class="fas fa-file-excel text-white"></i></button>&nbsp;

         

          </div>
        </div>
      </div>


    </nb-card-header>
    <div *ngIf="!dataCustomers || dataCustomers.length === 0" align="center">
      <nb-alert class="w-100 mt-3" outline="danger">No Data Found.</nb-alert>
    </div>
    <div *ngIf="(dataCustomers | filter : search).length === 0" [hidden]="dataCustomers.length === 0"
         class="">
      <nb-alert class="col-lg-12 mt-3" fullWidth outline="danger">The item searched is not found. Please make sure
        while typing your data
      </nb-alert>
    </div>

    <div class="row mt-3">
      
      <table class="table mb-0 " id="htmlData">
        <thead class="bg-light">
        <tr>
          <th>Name</th>
          <th>title</th>
          <th>Cell Phone</th>
          <th>Address</th>
          <th>City</th>
          <th>Status</th>
          <th>Actions</th>

        </tr>
        </thead>
        <tbody
          *ngFor="let customer of dataCustomers | filter : search | paginate : {itemsPerPage:10, currentPage: current,totalItems: total}"
         >
        <tr class="col-md-9 col-sm-10">
          <td>
            <div class="d-flex align-items-center">
              <nb-user [name]="customer.name" onlyPicture picture="{{customer.image}}"></nb-user>
              <div class="ms-3">
                <p class=" mb-1">{{customer.name}}</p>
                <p class=" mb-0">{{customer.workEmail}}</p>
              </div>
            </div>
          </td>
          <td><p class="fw-normal mb-1">{{customer.title}}</p></td>
          <td><p class="fw-normal mb-1">{{customer.phone}}</p></td>
          <td><p class="fw-normal mb-1">{{customer.address}}</p></td>
          <td><p class="fw-normal mb-1">{{customer.city}}</p></td>
          <td>

            <nb-tag-list>
              <nb-tag *ngIf="customer.status === 'Lead' " [text]="customer.status" size="small" status="basic"></nb-tag>
              <nb-tag *ngIf="customer.status === 'Prospect' " [text]="customer.status" size="small"
                      status="danger"></nb-tag>
              <nb-tag *ngIf="customer.status === 'Client' " [text]="customer.status" size="small"
                      status="primary"></nb-tag>

            </nb-tag-list>

          </td>

          <td>
            <button [matMenuTriggerFor]="menu" aria-expanded="false" aria-haspopup="true"
                    aria-label="Example icon-button with a menu" mat-icon-button
                    nbButton nbTooltip="Click here to see advanced menu"
                    nbTooltipStatus="primary"
                    shape="round"
                    size="small"
                    status="basic">
              <nb-icon icon="more-horizontal-outline"></nb-icon>
            </button>
            <mat-menu #menu="matMenu">
                             <button mat-menu-item>
                        <a (click)="onRestoreConfirm(customer,customer.id)" fullWidth nbButton>
                          <span class="text-danger"><nb-icon icon="refresh-outline"></nb-icon></span>Restore
                        </a>
                      </button>
            </mat-menu>
          </td>
        </tr>
        </tbody>
      </table>
      <!-- end col -->
    </div>
    <!-- end row -->
   <div class="d-flex justify-content-center">
      <nb-card class="card ml-auto mr-auto" style="height: 30px; border-radius: 25px;">
        <pagination-controls (pageChange)="current = $event" align="right"></pagination-controls>

     </nb-card>
    </div><!-- end row -->
  </div>
  <!-- container -->
</div>