<nb-card>
  <nb-card-header>
    <div class="row">
      <div class="col-md-10 col-sm-12">
        <h5 class="more-vertical">
          Update event </h5>
      </div>

      <div align="right" class="col-md-2 justify-content-end">
        <a [routerLink]="['/crm/event-details/',event.id]" class="w-25" fullWidth nbButton status="info"
           nbTooltip="Click here to show event detail" nbTooltipStatus="primary" shape="round">
          <nb-icon icon="eye-outline"></nb-icon>
        </a>
        <a [routerLink]="['/crm/events']" class="w-25 mx-3"
           fullWidth nbButton nbTooltip="Back to events list" nbTooltipStatus="primary"  shape="round" status="basic">
          <nb-icon icon="corner-up-right-outline"></nb-icon>
        </a>

      </div>

    </div>
  </nb-card-header>
  <nb-card-body>

    <form [formGroup]="eventGroup">
      <div class="row mt-1">
        <div class="col-md-6">
          <label class="small">Title</label>
          <div class="row">
            <div class="col-md-10">
              <input
                [(ngModel)]="event.title"
                fieldSize="large" formControlName="title"
                fullWidth id="title" name="title" nbInput placeholder="Event title"
                type="text">
            </div>
            <div class="col-md-1 mt-3">
              <nb-icon icon="info-outline" nbTooltip="Only caracters can be accepted"
                       nbTooltipStatus="primary"></nb-icon>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <label class="small">Date Of Event</label>
          <div class="row">
            <div class="col-md-10">
              <input  [(ngModel)]="event.dateOfEvent" [nbDatepicker]="dateTimePicker"
                      fieldSize="large" formControlName="dateOfEvent" fullWidth id="dateOfEvent"
                      name="dateOfEvent" nbInput
                      placeholder="Pick Date">
              <nb-datepicker #dateTimePicker></nb-datepicker>
            </div>
            <div class="col-md-1 mt-3">
              <nb-icon icon="info-outline" nbTooltip="Select Date of Event"
                       nbTooltipStatus="primary"></nb-icon>
            </div>
          </div>
        </div>
      </div>



      <div class="row mt-1">
        <div class="col-md-6">
          <label class="small">Type</label>
          <div class="row">
            <div class="col-md-10">
              <nb-select  [(ngModel)]="event.type" [selected]="selectedType" size="large"
                          formControlName="type" fullWidth
                          id="type" name="type" placeholder="Select Type">
                <nb-option disabled value="0">Type</nb-option>
                <nb-option value="Seminar">Seminar</nb-option>
                <nb-option value="workshop">workshop</nb-option>
                <nb-option value="conference">conference</nb-option>
                <nb-option value="Celebration">Celebration</nb-option>
                <nb-option value="Other">Other</nb-option>
              </nb-select>
            </div>
            <div class="col-md-1 mt-3">
              <nb-icon icon="info-outline" nbTooltip="Select the type" nbTooltipStatus="primary"></nb-icon>
            </div>
          </div>

        </div>
        <div  [hidden]="event.type !== 'Other'" class="col-md-6">
          <label class="small">Other Type</label>
          <div class="row">
            <div class="col-md-10">
              <input [(ngModel)]="event.otherType" fieldSize="large" formControlName="otherType"
                     fullWidth id="otherType" name="otherType" nbInput placeholder="Tap other type"
                     type="text">
            </div>
            <div class="col-md-1 mt-3">
              <nb-icon icon="info-outline" nbTooltip="Specify the type(Seminar, conference, Celebration...)"
                       nbTooltipStatus="primary"></nb-icon>
            </div>

          </div>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-md-6">
          <label class="small">Event Image</label>
          <br>
          <div class="row">
            <div class="col-md-10">
              <input (change)="onFileSelected($event)"
                     autocomplete="off"
                     formControlName="image"
                     fullWidth
                     id="image"
                     name="image"
                     nbInput
                     type="file"/>
            </div>
            <div class="col-md-1 mt-3">
              <nb-icon icon="info-outline" nbTooltip="Upload image" nbTooltipStatus="primary"></nb-icon>
            </div>
          </div>
        </div>
        <div  class="col-md-6">
                  <span [nbSpinner]="loading" nbSpinnerStatus="danger" *ngIf="event.image === null; else erreur">
                    <p class="text-warning mt-5">
                     <nb-icon icon="loader-outline"></nb-icon> This Space Is Reserved For Loading Image
                    </p>
                  </span>
          <ng-template #erreur >
            <img alt="" class="w-25" src="{{event.image}}" style="border-radius: 5px;">
          </ng-template>
        </div>

      </div>

      <div class="row mt-1">
        <div class="col-md-6">
          <div class="row">
            <div class="col-lg-12">
              <label class="mt-2 small ">Country</label>
            </div>

            <div class="col-lg-10">
              <nb-select  class="mt-1"
                          [(ngModel)]="event.country"
                          formControlName="country" fullWidth
                          id="country" name="country"
                          placeholder="Select Country" selected="0" size="large">
                <nb-option disabled value="0">Country</nb-option>
                <nb-option *ngFor="let country of countries" value="{{country.country_name}}">
                  {{country.country_name}}</nb-option>
              </nb-select>
            </div>
            <div class="col-md-1 mt-3">
              <nb-icon icon="info-outline" nbTooltip="Select the country" nbTooltipStatus="primary"></nb-icon>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <label class="small">Address</label>
          <div class="row">
            <div class="col-md-10">
              <input
                [(ngModel)]="event.address"
                fieldSize="large" formControlName="address"
                fullWidth id="address" placeholder="Address"
                name="address" nbInput type="text">
            </div>
            <div class="col-md-1 mt-3">
              <nb-icon icon="info-outline" nbTooltip="This Field should typed correctly"
                       nbTooltipStatus="primary"></nb-icon>
            </div>
          </div>
        </div>          </div>


      <div class="row mt-1">
        <div class="col-md-6">
          <label class="small">Number of participants</label>
          <div class="row">
            <div class="col-md-10">
              <input
                [(ngModel)]="event.participants"
                fieldSize="large" formControlName="participants"
                fullWidth id="participants" placeholder ="Number of participants"
                name="active" nbInput type="text">
            </div>
            <div class="col-md-1 mt-3">
              <nb-icon icon="info-outline" nbTooltip="Tap Number of participants"
                       nbTooltipStatus="primary"></nb-icon>
            </div>
          </div>

        </div>

        <div class="col-md-6">
          <label class="small">Status</label>
          <div class="row">
            <div class="col-md-10">
              <nb-select
                [(ngModel)]="event.statusEvent"
                size="large"
                formControlName="statusEvent"
                fullWidth
                id="statusEvent"
                name="statusEvent"
                placeholder="Select status">

                <nb-option
                  *ngFor="let option of options" value="{{option.value}}">{{option.value}}</nb-option>
              </nb-select>
            </div>
            <div class="col-md-1 mt-3">
              <nb-icon icon="info-outline" nbTooltip="Select Event status"
                       nbTooltipStatus="primary"></nb-icon>
            </div>
          </div>

        </div>
      </div>



      <div class="row mt-1">
        <div class="col-md-6">
          <label class="small">Description</label>
          <div class="row">
            <div class="col-md-10">
                 <textarea
                   [(ngModel)]="event.description"
                   formControlName="description"
                   fullWidth
                   id="description"
                   name="description"
                   nbInput
                   placeholder="Description">
                 </textarea>
            </div>
            <div class="col-md-1 mt-3">
              <nb-icon icon="info-outline" nbTooltip="Tap description"
                       nbTooltipStatus="primary"></nb-icon>
            </div>
          </div>

        </div>
        <!-- Time Picker Section -->
        <div class="col-md-6">
          <label class="small">Time of Event</label>
          <div class="row">
            <div class="col-md-10">
              <input [(ngModel)]="event.timeOfEvent"
                     fullWidth
                     nbInput
                     placeholder="Select time"
                     [nbTimepicker]="timepicker"
                     formControlName="timeOfEvent"
                     class="custom-input-height">
              <nb-timepicker #timepicker="nbTimepicker" twelveHoursFormat></nb-timepicker>
            </div>
            <div class="col-md-1 mt-3">
              <nb-icon icon="info-outline" nbTooltip="Tap Time"
                       nbTooltipStatus="primary"></nb-icon>
            </div>
          </div>
        </div>
      </div>
      <div align="right">
        <button (click)="updateEvent()"  nbButton status="success" >Update </button>
      </div>
    </form>
  </nb-card-body>

</nb-card>
