<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet">

<div class="container">
  <div class="d-flex flex-column flex-md-row mb-3 mb-md-0">
    <div class="col-md-3">
      <h2>Visits</h2>
    </div>
    <div class="col-md-5">
      <input [(ngModel)]="search"
             nbInput class="w-100"
             nbTooltip="Tap here to search a visit "
             nbTooltipStatus="primary" placeholder="Search visit" type="text">

    </div>
    
    <div class="btn-toolbar col-md-6" role="toolbar">
        <a (click)="exportAsXLSX()" aria-current="page" class=" mx-1 align-self-center active"
           id="tooltipExport" nbTooltip="Export data to excel file "
           nbTooltipStatus="primary" nbButton 
           status="success"> <i class="fas fa-file-excel text-white"></i>
        </a>
    </div>
  </div>
 
  <div *ngIf="!dataVisits || dataVisits.length === 0" align="center">
    <nb-alert class="w-100 mt-3" outline="danger">No Data Found.</nb-alert>
  </div>
  <div *ngIf="(dataVisits | filter : search).length === 0" [hidden]="dataVisits.length === 0"
       class="">
    <nb-alert class="col-lg-12 mt-3" fullWidth outline="danger">The item searched is not found. Please make sure
      while typing your data
    </nb-alert>
  </div>
  <div class="row mt-3">
      <div class="row col-md-12">
      <nb-card
        *ngFor="let visit of dataVisits| filter : search | paginate : {itemsPerPage:pageSize, currentPage: current,totalItems: dataClaims?.length}"
        class="col-md-4 form-group">

        <div *ngIf="visit.status === 'DONE'">
          <nb-badge position="top right" status="success" text={{visit.status}}></nb-badge>
        </div>
          <div *ngIf="visit.status=== 'doing'">
          <nb-badge position="top right" status="warning" text={{visit.status}}></nb-badge>
        </div>
     <div *ngIf="visit.status=== 'UPCOMING'">
            <nb-badge position="top right" status="basic" text={{visit.status}}></nb-badge>
        </div>
        <div class="row mt-4">
            <div class="row">
              <div class="col-md-7 mx-2">
                <nb-user [picture]="visit?.customer?.image" [name]="visit?.customer?.name" size="medium">
                  
                </nb-user>
              </div>
            
               <div class="col-md-3" align="right">
                 <button [matMenuTriggerFor]="menu" aria-expanded="false" aria-haspopup="true"
                    aria-label="Example icon-button with a menu" class="float-right"
                    mat-icon-button nbButton
                    nbTooltip="Click here to see advanced menu"
                    nbTooltipStatus="primary"
                    shape="round"
                    size="small"
                    status="basic">
              <nb-icon icon="more-horizontal-outline"></nb-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item>
                <a (click)="onRestoreConfirm(visit,visit.id)"  fullWidth nbButton>
                  <span class="text-info"><nb-icon icon="eye-outline"></nb-icon></span>Restore
                </a>
              </button>
             
            </mat-menu>

                                </div>
              <div class="col-md-12 pt-4 mx-3">
                <p>
                  <nb-icon class="text-primary" icon="file-outline" nbTooltip="Claim title"></nb-icon>
                  <b>{{visit.title}}</b>
                </p>
                <p>
                  <nb-icon class="text-primary" icon="clock-outline" nbTooltip="Claim type"></nb-icon>
                  <b>{{visit.hourVisit}}<br/>
                {{visit.dateVisit | date : 'EEEE, d MMMM '}}</b>
                </p>
                <p>
                  <nb-icon class="text-primary" icon="pin-outline" nbTooltip="Claim evaluation"></nb-icon>
                  <b>   {{visit?.property?.propertyCity}},     {{visit?.property?.propertyCountry}}</b>
                </p>
             
              </div>
              
              	
				
            </div>

     
        </div>


      </nb-card>

    </div>
  </div>
  
  <div class="d-flex justify-content-center ">
     <nb-card class="card ml-auto mr-auto" style="height: 30px; border-radius: 25px;">
        <pagination-controls (pageChange)="current = $event" align="right"></pagination-controls>

     </nb-card>
    </div>
</div>

