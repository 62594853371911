import {Customer} from './Customer';
import {CrmStages} from './CrmStages';
import {CrmEtapes} from './CrmEtapes';
import {CrmTeamObjective} from './CrmTeamObjective';

export class CrmTrackingDeals {
  id?: string;

  nomEntreprise: Customer;  // Assuming `any` here but should ideally define a type or interface

  prospect: Customer;       // Same as above, use a more specific type if available

  emailContact: string;

  valeur: string;

  stage: CrmStages;          // Replace `any` with a specific type or interface

  recette: string;

  archive?: string;

  recetteR: string;

  dateP: Date;

  dateC: Date;

  teamMember: CrmTeamObjective;     // Replace `any` with a specific type or interface

  progresVente?: string;

  dateDEchange: Date;

  etapeSuivante: CrmEtapes;
}
