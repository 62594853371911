import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {Observable} from 'rxjs';
import {CrmFollowUp} from '../../models/CrmFollowUp';
const httpOptionsPlain = {
  headers: new HttpHeaders({
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }),
  'responseType': 'text as json',
};
@Injectable({
  providedIn: 'root',
})
export class CrmFollowUpService {
  httpOptions = {
    headers: new HttpHeaders({

      'Content-Type': 'application/json;  charset=utf-8 ',
    }),
  };
  constructor(private httpClient: HttpClient) { }
  findAllCrmFollowUp() {
    return this.httpClient.get(`${environment.crmUrl}CrmFollowUp/allCrmFollowUp`);
  }

  addCrmFollowUp(c: CrmFollowUp) {
    return this.httpClient.post<CrmFollowUp>(`${environment.crmUrl}CrmFollowUp/add`, c, this.httpOptions);
  }

  updateCrmFollowUp(crmFollowUp: CrmFollowUp): Observable<CrmFollowUp> {
    return this.httpClient.put<CrmFollowUp>(
      `${environment.crmUrl}CrmFollowUp/update-CrmFollowUp`,
      crmFollowUp, this.httpOptions);
  }

  deleteCrmFollowUp(crmFollowUp: CrmFollowUp | string): Observable<string> {
    const id = typeof crmFollowUp === 'string' ? crmFollowUp : crmFollowUp.id;
    const url = `${environment.crmUrl}CrmFollowUp/delete/` + id;
    // @ts-ignore
    return this.httpClient.delete<string>(url, httpOptionsPlain);
  }

  getCrmFollowUpByID(id: string): Observable<CrmFollowUp> {
    return this.httpClient.get<CrmFollowUp>(`${environment.crmUrl}CrmFollowUp/CrmFollowUp-by-id/` + id);
  }
}
