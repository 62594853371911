import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {Event} from '../../models/Event';

const httpOptionsPlain = {
  headers: new HttpHeaders({
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }),
  'responseType': 'text as json',
};
@Injectable({
  providedIn: 'root',
})
export class EventService {

  httpOptions = {
    headers: new HttpHeaders({

      'Content-Type': 'application/json;  charset=utf-8 ',
    }),
  };


  constructor(private httpClient: HttpClient) {
  }

  addEvent(e: Event, idUser: string): Observable<Event> {
    return this.httpClient.post<Event>(`${environment.crmUrl}event/add/` + idUser, e, this.httpOptions);
  }

  updateEvent(event: Event, idUser: string): Observable<Event> {
    return this.httpClient.put<Event>(`${environment.crmUrl}event/update-event/` + idUser,
      event, this.httpOptions);
  }

  archiveEvent(event: Event, id: string): Observable<Event> {
    return this.httpClient.put<Event>(`${environment.crmUrl}event/archive-event/` + id,
      event, this.httpOptions);
  }

  cancelArchiveEvent(event: Event, id: string): Observable<Event> {
    return this.httpClient.put<Event>(`${environment.crmUrl}event/cancel-archive-event/` + id,
      event, this.httpOptions);
  }

  deleteEvent(id: string): Observable<string> {
    return this.httpClient.delete<string>(`${environment.crmUrl}event/delete/` + id);
  }

  getEventByID(id: string): Observable<Event> {
    return this.httpClient.get<Event>(`${environment.crmUrl}event/event-by-id/` + id);
  }

  countEvents(): Observable<number> {
    return this.httpClient.get<number>(`${environment.crmUrl}event/count-events`);
  }

  listEventDesc(): Observable<Event[]> {
    return this.httpClient.get<Event[]>(`${environment.crmUrl}event/all/descending`);
  }

  listEventAsc(): Observable<Event[]> {
    return this.httpClient.get<Event[]>(`${environment.crmUrl}event/all/ascending`);
  }

  findNonArchivedEvents(): Observable<Event[]> {
    return this.httpClient.get<Event[]>(`${environment.crmUrl}event/non-archived-events/False`);
  }

  findArchivedEvents(): Observable<Event[]> {
    return this.httpClient.get<Event[]>(`${environment.crmUrl}event/non-archived-events/True`);
  }

  findEvents(statusEvent: string, archive: string): Observable<Event[]> {
    return this.httpClient.get<Event[]>(`${environment.crmUrl}event/event-by-status/${statusEvent}/${archive}`);
  }

  findEventsCreatedDesc(statusEvent: string, archive: string): Observable<Event[]> {
    return this.httpClient.get<Event[]>(`${environment.crmUrl}event/event-by-desc-created/${statusEvent}/${archive}`);
  }

  findEventsTitleDesc(statusEvent: string, archive: string): Observable<Event[]> {
    return this.httpClient.get<Event[]>(`${environment.crmUrl}event/event-by-desc-name/${statusEvent}/False`);
  }

  findEventsTitleAsc(statusEvent: string, archive: string): Observable<Event[]> {
    return this.httpClient.get<Event[]>(`${environment.crmUrl}event/event-by-asc-name/${statusEvent}/False`);
  }

  findEventByEventDate(): Observable<Event[]> {
    return this.httpClient.get<Event[]>(`${environment.crmUrl}event/event-by-date`);
  }

  getEventDescName(): Observable<[Event]> {
    return this.httpClient.get<[Event]>(`${environment.crmUrl}event/events-descending-title/False`);
  }

  getEventDescCreatedAt(): Observable<[Event]> {
    return this.httpClient.get<[Event]>(`${environment.crmUrl}event/event-by-desc-created/False`);
  }

  getEventAscCreatedAt(): Observable<[Event]> {
    return this.httpClient.get<[Event]>(`${environment.crmUrl}event/event-by-asc-created/False`);
  }

  getEventAscName(): Observable<[Event]> {
    return this.httpClient.get<[Event]>(`${environment.crmUrl}event/events-asc-title/False`);
  }
  findAllEvents(): Observable<Event[]> {
    return this.httpClient.get<Event[]>(`${environment.crmUrl}event/allEvents`);
  }
}
