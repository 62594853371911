import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {EventService} from '../../../services/events/event.service';
import {Event} from '../../../models/Event';
@Component({
  selector: 'ngx-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss'],
})
export class EventDetailsComponent implements OnInit {

    idUri: string = '';
  event: Event = new Event();

  constructor( private ev: EventService, private activatedroute: ActivatedRoute) { }


  ngOnInit(): void {
    this.activatedroute.paramMap.subscribe(result => {
      this.idUri = result.get('id');
    });

    this.ev.getEventByID(this.idUri).subscribe(data => {
      this.event = data;
      error => console.log(error);
    });

  }

}
