<div class="row">
  <div class="col-md-8">
    <div id="jitsi-iframe"></div>
  </div>
  <div class="col-md-4">
    <ngx-only-clients></ngx-only-clients>

  </div>
</div>

