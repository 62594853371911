import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {CrmStages} from '../../models/CrmStages';
const httpOptionsPlain = {
  headers: new HttpHeaders({
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }),
  'responseType': 'text as json',
};
@Injectable({
  providedIn: 'root',
})
export class CrmStagesService {

  httpOptions = {
    headers: new HttpHeaders({

      'Content-Type': 'application/json;  charset=utf-8 ',
    }),
  };
  constructor(private httpClient: HttpClient) { }

  findAllCrmStages() {
    return this.httpClient.get(`${environment.crmUrl}CrmStages/allCrmStages`);
  }

  addCrmStages(c: CrmStages) {
    return this.httpClient.post<CrmStages>(`${environment.crmUrl}CrmStages/add`, c, this.httpOptions);
  }

  updateCrmStages(crmStages: CrmStages): Observable<CrmStages> {
    return this.httpClient.put<CrmStages>(
      `${environment.crmUrl}CrmStages/update-CrmStages`,
      crmStages, this.httpOptions);
  }

  deleteCrmStages(crmStages: CrmStages | string): Observable<string> {
    const id = typeof crmStages === 'string' ? crmStages : crmStages.id;
    const url = `${environment.crmUrl}CrmStages/delete/` + id;
    // @ts-ignore
    return this.httpClient.delete<string>(url, httpOptionsPlain);
  }

  getCrmStagesByID(id: string): Observable<CrmStages> {
    return this.httpClient.get<CrmStages>(`${environment.crmUrl}CrmStages/CrmStages-by-id/` + id);
  }
}
