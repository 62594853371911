<nb-card *ngIf="claim">
  <nb-card-body>
    <nb-card-header>
      <div class="row">
        <div class="col-md-10 col-sm-12">
          <h5 class="more-vertical">
            Update a claim </h5>
        </div>

        <div align="right" class="col-md-2 justify-content-end">
          <a [routerLink]="['/crm/claims']" class="w-25 mx-3"
             fullWidth nbButton nbTooltip="Back to claims list" nbTooltipStatus="primary"
             shape="round" status="basic">
            <nb-icon icon="corner-up-right-outline"></nb-icon>
          </a>
        </div>
      </div>
    </nb-card-header>
        <form [formGroup]="claimGroup" class="col-lg-12">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label class="label" for="claimCode">Claim code</label>
                <input [(ngModel)]="claim.claimCode" formControlName="claimCode"
                       fullWidth fieldSize="large"
                       id="claimCode"
                       name="claimCode" nbInput placeholder="Claim code" type="text">
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label class="label" for="claimTitle">Title</label>
                <input [(ngModel)]="claim.claimTitle" formControlName="claimTitle"
                          fullWidth fieldSize="large"
                       id="claimTitle"
                       name="claimTitle" nbInput placeholder="Claim Title" type="text">
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label class="label">Status</label><br>
                <nb-select (selectedChange)="onMenuStatusSelected($event)"
                           [(ngModel)]="claim.status"
                         size="large"
                           formControlName="status"
                              fullWidth
                               class="w-100"
                           id="status" name="status"
                           placeholder="Claim status">

                  <nb-option value="Pending">Pending</nb-option>
                  <nb-option value="In Progress">In Progress</nb-option>
                  <nb-option value="Resolved">Resolved</nb-option>

                </nb-select>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label class="label">Date claim</label>
                <input [(ngModel)]="claim.claimDate" [nbDatepicker]="dateTimePicker1"
                       formControlName="claimDate"     fullWidth fieldSize="large"
                       id="claimDate"
                       name="claimDate" nbInput placeholder="Date claim">
                <nb-datepicker #dateTimePicker1></nb-datepicker>
              </div>
            </div>

          </div>


          <div class="row">

            <div class="col-sm-6">
               <div class="form-group">
                  <label class="label"> Motif</label>
                  <input [(ngModel)]="claim.claimMotif" formControlName="claimMotif"
                                fullWidth fieldSize="large"
                            id="claimMotif" name="claimMotif"
                            nbInput >
                </div>

            </div>
            <div class="col-sm-6">

              <div class="form-group">
                <label class="label"> Other informations</label> <br>
                <input [(ngModel)]="claim.otherInfos"
                       formControlName="otherInfos"     fullWidth fieldSize="large"
                       id="otherInfos"
                       name="otherInfos" nbInput placeholder="Other informations">

              </div>
            </div>
          </div>


            <div class="row">

              <div class="col-lg-6">
                <div class="form-group">
                  <label class="label"> Urgency type </label> <br>
                  <nb-select (selectedChange)="onMenuItemSelectedCustomer($event)"
                             [(ngModel)]="claim.urgencyType"
                              class="w-100"
                             formControlName="urgencyType"
                                fullWidth size="large"
                             id="urgencyType" name="urgencyType"
                             placeholder="Urgency Type">

                    <nb-option value="Highest">Highest</nb-option>
                    <nb-option value="High">High</nb-option>
                    <nb-option value="Low">Low</nb-option>

                  </nb-select>
                </div>
              </div>

              <div class="col-sm-6">
              <div class="form-group">
                <label class="label" for="claimType">Claim type</label>
                <input [(ngModel)]="claim.claimType" formControlName="claimType"
                      fullWidth fieldSize="large"
                       id="claimType"
                       name="claimType" nbInput placeholder="Claim type" type="text">
              </div>
            </div>
            </div>

<div class="row">
              <div class="col-lg">
                 <div class="form-group">

                  <label class="label"> Evaluation Claim </label> <br>
                  <ngb-rating [max]="5" [(rate)]="currentRate"
                             formControlName="evaluationClaim"  name="evaluationClaim"
                             [readonly]="false" [(ngModel)]="claim.evaluationClaim"></ngb-rating>
                    <p>Evaluation : {{currentRate}}</p>

                </div>
              </div>
            </div>

            <div class="row">

              <div class="col-sm-12">

                 <div class="form-group">
                <label class="label"> Description Claim</label>
                <textarea [(ngModel)]="claim.descriptionClaim" formControlName="descriptionClaim"
                            fullWidth fieldSize="large" id="descriptionClaim"
                          name="descriptionClaim"
                          nbInput placeholder="Description Claim" rows="5" type="text"></textarea>
              </div>
              </div>

              <!-- <div class="col-sm-6">
                <div class="form-group">
                  <label class="label"> Claim response </label> <br>
                 <textarea [(ngModel)]="claim.responseClaim" formControlName="responseClaim"
                           fullWidth fieldSize="large"
                            id="responseClaim" name="responseClaim"
                            nbInput rows="5"></textarea>


                </div>
              </div>-->
            </div>




            <div class="row">
                <div class="col-lg">
                <div class="form-group">
                  <label class="label"> Employee </label> <br>
                  <nb-select (selectedChange)="onMenuItemSelectedEmployee($event)"
                             [(ngModel)]="claim.employee"
                             [selected]="selectedEmployee"
                             class="w-100"
                             formControlName="employee"
                              fullWidth size="large"
                             id="employee" name="employee"
                             placeholder="Employee">

                    <nb-option

                      *ngFor="let optionEmployee of list"
                      value="{{optionEmployee.id}}">{{optionEmployee.employeeName}}</nb-option>

                  </nb-select>
                </div>
              </div>

            </div>
            <div  align="right">

              <button (click)="updateClaimFunction()"  nbButton
                      status="success" type="submit">Update
              </button>
            </div>


    </form>
  </nb-card-body>
</nb-card>
