import {Component, OnInit} from '@angular/core';
import {CrmTeamTrackingDealsService} from '../../../../services/crmTeamTrackingDeals/crm-team-tracking-deals.service';
import {ActivatedRoute} from '@angular/router';
import {CrmTrackingDeals} from '../../../../models/CrmTrackingDeals';

@Component({
  selector: 'ngx-details-crm-team-tracking-deals',
  templateUrl: './details-crm-team-tracking-deals.component.html',
  styleUrls: ['./details-crm-team-tracking-deals.component.scss'],
})
export class DetailsCrmTeamTrackingDealsComponent implements OnInit {
  idUri: string = '';
  crmTrackingDeals: CrmTrackingDeals = new CrmTrackingDeals();

  constructor(private ct: CrmTeamTrackingDealsService,
              private activatedroute: ActivatedRoute,
             ) {
  }
  ngOnInit(): void {
    this.activatedroute.paramMap.subscribe(result => {
      this.idUri = result.get('id');
    });

    this.ct.getCrmTrackingDealsByID(this.idUri).subscribe(data => {
      this.crmTrackingDeals = data;
      error => console.log(error);
    });

  }
}
