<nb-card>
  <nb-card-body>
    <nb-card-header>
      <div class="row">
        <div class="col-md-10 col-sm-12">
          <h5 class="more-vertical">
            Create contract </h5>
        </div>
        
        <div align="right" class="col-md-2 justify-content-end">
          <a [routerLink]="['/crm/contracts']" class="w-25 mx-3"
             fullWidth nbButton nbTooltip="Back to contracts list" nbTooltipStatus="primary" shape="round"
             status="basic">
            <nb-icon icon="corner-up-right-outline"></nb-icon>
          </a>

        </div>
      </div>

    </nb-card-header>

    <form [formGroup]="contractGroup" class="col-lg-12 mt-3">
      <nb-stepper #stepper>
        <nb-step [stepControl]="contractGroup" label="Contract information">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label class="label" for="contractName">Contract code</label>
                <input [(ngModel)]="contract.contractCode"
                       formControlName="contractCode" fullWidth
                       id="contractCode" fieldSize="large"
                       name="contractCode" nbInput placeholder="Contract code"
                       type="text">
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label class="label" for="contractName">Contract Name</label>
                <input [(ngModel)]="contract.contractName"
                       formControlName="contractName" fullWidth
                       id="contractName" fieldSize="large"
                       name="contractName" nbInput placeholder="Contract Name" type="text">
              </div>
            </div>

          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="form-group">
                <label class="label">Contract Type</label><br>
                <nb-select (selectedChange)="onMenuItemSelectedContractType($event)"
                           [(ngModel)]="contract.contractType"
                           class="w-100" size="large"
                           formControlName="contractType" fullWidth
                           id="contractType" name="contractType"
                           placeholder="Contract Type">

                  <nb-option
                    *ngFor="let option of options" value="{{option.value}}">{{option.value}}</nb-option>
                </nb-select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label class="label" for="state">state</label>
                <input [(ngModel)]="contract.state"
                       formControlName="state" fieldSize="large"
                       fullWidth id="state"
                       name="state" nbInput placeholder="State" type="text">
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label class="label" for="designation">Designation</label>
                <input [(ngModel)]="contract.designation"
                       formControlName="designation" fullWidth
                       id="designation" fieldSize="large" 
                       name="designation" nbInput placeholder="Designation" type="text">
              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-md-10"></div>
            <div class="col-md-2 mt-2" align="right">
              <button (click)="onContractFormSubmit()" class="w-100" nbButton nbStepperNext status="info"
                      type="submit">next
              </button>
            </div>
          </div>
        </nb-step>
        <nb-step [stepControl]="periodGroup" label="Period">
          <form class="step-container" formGroupName="periodGroup">
            <div class="row">

              <div class="col-sm-6">
                <div class="form-group">
                  <label class="label" for="dateContract">Date Contract</label>
                  <input [(ngModel)]="contract.dateContract"
                         [nbDatepicker]="dateTimePicker1" formControlName="dateContract"
                         fullWidth id="dateContract" fieldSize="large"
                         name="dateContract" nbInput placeholder="Pick date contract">
                  <nb-datepicker #dateTimePicker1></nb-datepicker>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="label" for="dateFin">Date end</label>
                  <input [(ngModel)]="contract.dateFin" [nbDatepicker]="dateTimePicker"
                         formControlName="dateFin" fullWidth
                         id="dateFin" fieldSize="large"
                         name="dateFin" nbInput placeholder="Pick date end contract">
                  <nb-datepicker #dateTimePicker></nb-datepicker>
                </div>
              </div>
            </div>
            
            <div class="row">
              <button class="col-md-2" nbButton nbStepperPrevious>prev</button>
              <div class="col-md-8"></div>
              <button (click)="addContractFunction()" class="col-md-2" nbButton nbStepperNext
                      status="info" type="submit">Create
              </button>
            </div>
          </form>
        </nb-step>
       
      </nb-stepper>
    </form>
  </nb-card-body>
</nb-card>