<nb-card>
  <nb-card-body>
    <nb-card-header>
      <div class="row">
        <div class="col-md-10 col-sm-12">
          <h5 class="more-vertical">
            Add a claim </h5>
        </div>

        <div align="right" class="col-md-2 justify-content-end">
          <a [routerLink]="['/crm/claims']" class="w-25 mx-3"
             fullWidth nbButton nbTooltip="Back to claims list" nbTooltipStatus="primary"
             shape="round" status="basic">
            <nb-icon icon="corner-up-right-outline"></nb-icon>
          </a>
        </div>
      </div>
    </nb-card-header>
    <form [formGroup]="claimGroup" class="col-lg-12">
      <nb-stepper #stepper >
        <nb-step [stepControl]="claimGroup" label="Claim information">

          <div class="row">
         <div class="col-sm-6">
              <div class="form-group">
                <label class="label">Date claim</label>
                <input [(ngModel)]="claim.claimDate" [nbDatepicker]="dateTimePicker1"
                       formControlName="claimDate" fullWidth
                       id="claimDate" fieldSize="large"
                       name="claimDate" nbInput placeholder="Date claim">
                <nb-datepicker #dateTimePicker1></nb-datepicker>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label class="label" for="claimTitle">Title</label>
                <input [(ngModel)]="claim.claimTitle" formControlName="claimTitle"
                       fullWidth
                       id="claimTitle" fieldSize="large"
                       name="claimTitle" nbInput placeholder="Claim Title" type="text">
              </div>
            </div>
          </div>



          <div class="row">

            <div class="col-sm-6">
              <div class="form-group">
                <label class="label"> Description Claim</label>
                <textarea [(ngModel)]="claim.descriptionClaim" formControlName="descriptionClaim"
                          fullWidth id="descriptionClaim"
                          name="descriptionClaim" fieldSize="large"
                          nbInput placeholder="Description Claim" rows="3" type="text"></textarea>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label class="label"> Other informations</label> <br>
                <input [(ngModel)]="claim.otherInfos"
                       formControlName="otherInfos" fullWidth
                       id="otherInfos" fieldSize="large"
                       name="otherInfos" nbInput placeholder="Other informations">

              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-md-10"></div>
            <div class="col-md-2 mt-2" align="right">
              <button (click)="onClaimFormSubmit()" class="w-100" nbButton nbStepperNext status="info"
                      type="submit">next
              </button>
            </div>
          </div>
        </nb-step>
        <nb-step [stepControl]="type_form" label="Type claim informations">
          <form class="step-container" formGroupName="type_form">
            <div class="row">

              <div class="col-lg-6">
                <div class="form-group">
                  <label class="label"> Urgency type </label> <br>
                  <nb-select (selectedChange)="onMenuItemSelectedCustomer($event)"
                             [(ngModel)]="claim.urgencyType"
                              class="w-100"
                             formControlName="urgencyType"
                             fullWidth fieldSize="large" size="large"
                             id="urgencyType" name="urgencyType"
                             placeholder="Urgency Type">

                    <nb-option value="Highest">Highest</nb-option>
                    <nb-option value="High">High</nb-option>
                    <nb-option value="Low">Low</nb-option>

                  </nb-select>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="form-group">
                  <label class="label"> Claim type </label><br>
                  <input [(ngModel)]="claim.claimType"
                        nbInput class="w-100"
                         formControlName="claimType"
                         fullWidth fieldSize="large"
                         id="claimType" name="claimType"
                         placeholder="Claim Type">


                </div>
              </div>
            </div>


              <div class="row">
                <div class="col-lg-12">
                <div class="form-group">
                                <div class="col-lg-12">

                                  <label class="label"> Claim Motif </label>  </div>
                  <input
                    [(ngModel)]="claim.claimMotif"
                    nbInput
                    formControlName="claimMotif"
                    fullWidth  fieldSize="large"
                    id="claimMotif" name="claimMotif"
                    placeholder="Claim Motif">
                </div>
            </div>

            </div>
            <div class="row">
              <button class="col-md-2" nbButton nbStepperPrevious>prev</button>
              <div class="col-md-8"></div>
              <button (click)="onTypeFormSubmit()" class="col-md-2" nbButton nbStepperNext
                      status="info" type="submit">next
              </button>
            </div>
          </form>
        </nb-step>
        <!-- <nb-step [stepControl]="correction_form" label="Correction informations">
          <form class="step-container" formGroupName="correction_form">
            <div class="row">

              <div class="col-sm-6 ">
                <div class="form-group">
                  <label class="label"> Evaluation Claim </label> <br>
                  <ngb-rating [max]="5" [(rate)]="currentRate"
                           fieldSize="large"  formControlName="evaluationClaim"  name="evaluationClaim"
                             [readonly]="false" [(ngModel)]="claim.evaluationClaim"></ngb-rating>
                    <p>Evaluation : {{currentRate}}</p>

                </div>
              </div>

              <div class="col-sm-6">
                <div class="form-group">
                  <label class="label"> Claim response </label> <br>
                <textarea [(ngModel)]="claim.responseClaim" formControlName="responseClaim"
                            fullWidth fieldSize="large"
                            id="responseClaim" name="responseClaim"
                            nbInput rows="5"></textarea>


                </div>
              </div>
            </div>


            <div class="row">
              <button class="col-md-2" nbButton nbStepperPrevious>prev</button>
              <div class="col-md-8" ></div>
              <button (click)="onCorrectionFormSubmit()" class="col-md-2" nbButton nbStepperNext
                      status="info" type="submit">Create
              </button>
            </div>
          </form>
        </nb-step> -->
        <nb-step [stepControl]="extra_form" label="RMQ informations">
          <form class="step-container" formGroupName="extra_form">
            <div class="row">
                <div class="col-sm-6">
                <div class="form-group">
                  <label class="label"> Employee </label> <br>
                  <nb-select (selectedChange)="onMenuItemSelectedEmployee($event)"
                             [(ngModel)]="claim.employee"
                             [selected]="selectedEmployee"
                             class="w-100"
                             formControlName="employee"
                             fullWidth size="large"
                             id="employee" name="employee"
                             placeholder="Employee">

                    <nb-option

                      *ngFor="let optionEmployee of list"
                      value="{{optionEmployee.id}}">

                      <nb-user size="medium" [picture]="optionEmployee.employeeProfileImage" [name]="optionEmployee.employeeName"></nb-user>

                    </nb-option>

                  </nb-select>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="label"> Customer</label><br>
                  <nb-select (selectedChange)="onMenuItemSelectedCustomer($event)"
                             [(ngModel)]="claim.customer"
                             class="w-100"
                             formControlName="customer"
                             fullWidth size="large"
                             id="customer" name="customer"
                             placeholder="Customer">

                    <nb-option

                      *ngFor="let optionCustomer of dataCustomers"
                      value="{{optionCustomer.id}}">
                      <nb-user size="medium" [picture]="optionCustomer.image" [name]="optionCustomer.name"></nb-user>
</nb-option>

                  </nb-select>
                </div>
              </div>
            </div>
            <div class="row">
              <button class="col-md-2" nbButton nbStepperPrevious>prev</button>
              <div class="col-md-8" align="right"></div>
              <button (click)="addClaimFunction()" class="col-md-2" nbButton nbStepperNext
                      status="success" type="submit">Create
              </button>
            </div>
          </form>
        </nb-step>

      </nb-stepper>
    </form>
  </nb-card-body>
</nb-card>
