import { Component, ViewChild, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DefaultEditor, Editor } from 'ng2-smart-table';
import { NbDatepicker } from '@nebular/theme';

@Component({
  template: `
    <input nbInput [nbDatepicker]="datepicker" [(ngModel)]="date" (ngModelChange)="onDateChange($event)">
    <nb-datepicker #datepicker></nb-datepicker>
  `,
})
export class DatepickerEditorComponent extends DefaultEditor implements OnInit {
  @ViewChild(NbDatepicker) datepicker: NbDatepicker<Date>;

  date: Date;

  ngOnInit() {
    if (this.cell.newValue) {
      this.date = new Date(this.cell.newValue);
    }
  }

  onDateChange(date: Date) {
    this.cell.newValue = date.toISOString().substring(0, 10);  // Format as 'YYYY-MM-DD'
  }
}
