<div *ngIf="customer" class="container">
  <div class="row">
    <div class="col-md-5 col-sm-12">
      <h2 class="more-vertical">
        Update Prospect Details</h2>
    </div>
    <div class="col-md-5"></div>
    <div align="right" class="col-md-2 justify-content-end">
      <a [routerLink]="['/crm/prospect-detail/',customer.id]" class="w-25" fullWidth nbButton
         nbTooltip="Click here to see prospect details"
         nbTooltipStatus="primary" shape="round" status="info">
        <nb-icon icon="eye-outline"></nb-icon>
      </a>
      <a [routerLink]="['/crm/prospects']" class="w-25 mx-3"
         fullWidth nbButton nbTooltip="Back to propsects list" nbTooltipStatus="primary" shape="round" status="basic">
        <nb-icon icon="corner-up-right-outline"></nb-icon>
      </a>
    </div>

  </div>

  <form [formGroup]="customerGroup">

    <div class="row mt-3">
      <div class="col-md-4 mb-3">
        <nb-card>
          <nb-card-body class="card-body">
            <div class="d-flex flex-column align-items-center text-center">
              <div class="row">
                <img alt=""
                     src="{{customer.image}}" style="width: 150px;height: 150px;border-radius: 100%;">
                <br>
              </div>
              <div class="row mt-1 mx-1">
                <div class="col-md-5">
                  <button (click)="updateCustomerFunction()" nbButton shape="round" size="small" status="success">
                    <nb-icon icon="checkmark-outline"></nb-icon>
                  </button>
                </div>

                <div class="col-md-5">
                  <div class="button">
                    <label for="image" nbButton shape="round" size="small" status="primary">
                      <nb-icon class="mt-1" icon="upload-outline"></nb-icon>
                    </label>
                    <input (change)="onFileSelected($event)"
                           [(ngModel)]="customer.image"
                           autocomplete="off" size="large"
                           formControlName="image"
                           id="image"
                           name="image"
                           type="file">
                  </div>
                </div>
              </div>

              <div class="mt-3">
                <h4>{{customer?.name}}</h4>
                <p class="text-secondary mb-1">Status : {{customer?.status}}</p>
                <p class="text-muted font-size-sm">Address :{{customer?.city}} {{customer?.country}}</p>

              </div>
            </div>
          </nb-card-body>
        </nb-card>


      </div>

      <div class="col-md-8">

        <nb-accordion>
          <nb-accordion-item expanded="true">
            <nb-accordion-item-header>Personal Information</nb-accordion-item-header>
            <nb-accordion-item-body>
              <div class="row">
                <div class="col-sm-3">
                  <p class="mb-0 text-sm">Title</p>
                </div>
                <div class="col-sm-9 text-secondary">
                  <input [(ngModel)]="customer.title" fieldSize="large" formControlName="title" fullWidth
                         id="title" name="title" nbInput placeholder="Customer title" readonly
                         type="text">

                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-sm-3">
                  <p class="mb-0 text-sm">Full Name</p>
                </div>
                <div class="col-sm-9 text-secondary">
                  <input [(ngModel)]="customer.name" fieldSize="large" formControlName="name" fullWidth
                         id="name" name="name"
                         nbInput placeholder="Customer Name" type="text">

                </div>
              </div>
              <br>
              <hr>

              <div class="row">
                <div class="col-sm-3">
                  <p class="mb-0 text-sm">Address</p>
                </div>
                <div class="col-sm-9 text-secondary">
                  <input [(ngModel)]="customer.address"
                         fieldSize="large" formControlName="address" fullWidth id="address"
                         name="address" nbInput
                         placeholder="Address">


                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-sm-3">
                  <p class="mb-0 text-sm">City</p>
                </div>
                <div class="col-sm-9 text-secondary">
                  <input [(ngModel)]="customer.city"
                         fieldSize="large" formControlName="city" fullWidth id="city"
                         name="city" nbInput
                         placeholder="city">


                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-sm-3">
                  <p class="mb-0 text-sm">Country</p>
                </div>
                <div class="col-sm-9 text-secondary">
                <nb-select size="large" placeholder="Select Country" [(ngModel)]="customer.country"
              class="mt-1" formControlName="country" fullWidth size="large"
                                 id="country" name="country" selected="0">
                        <nb-option disabled value="0">Country</nb-option>
                        <nb-option *ngFor="let country of countries" value="{{country.country_name}}">
                          {{country.country_name}}</nb-option>
                      </nb-select>
                </div>
              </div>
              <hr>
              <div align="right">


                <button  (click)="updateCustomerFunction()" nbButton status="success">Edit</button></div>
            </nb-accordion-item-body>
          </nb-accordion-item>
        </nb-accordion>
        <br>
        <nb-accordion>
          <nb-accordion-item>
            <nb-accordion-item-header>Contact Information</nb-accordion-item-header>
            <nb-accordion-item-body>
              <div class="row">
                <div class="col-sm-3">
                  <p class="mb-0 text-sm">Email Address</p>
                </div>
                <div class="col-sm-9 text-secondary">
                  <input [(ngModel)]="customer.workEmail" fieldSize="large" formControlName="workEmail" fullWidth
                         id="workEmail" name="workEmail"
                         nbInput placeholder="Work Email" type="text">

                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-sm-3">
                  <p class="mb-0 text-sm">Second Email </p>
                </div>
                <div class="col-sm-9 text-secondary">
                  <input [(ngModel)]="customer.secondEmail" fieldSize="large" formControlName="secondEmail" fullWidth
                         id="secondEmail" name="secondEmail"
                         nbInput placeholder="Second email" type="text">

                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-sm-3">
                  <p class="mb-0 text-sm">Cell Phone</p>
                </div>
                <div class="col-sm-9 text-secondary">
                  <input [(ngModel)]="customer.phone"
                         formControlName="phone"
                         fullWidth
                         id="phone"
                         name="phone" fieldSize="large"
                         nbInput
                         placeholder="Phone"
                         type="text">
                  <div *ngIf="phone.invalid">
                    <div *ngIf="phone.touched">
                      <p class="alert alert-warning text-dark" role="alert">Champ Obligatoire</p>
                    </div>
                  </div>

                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-sm-3">
                  <p class="mb-0 text-sm">Second Phone</p>
                </div>
                <div class="col-sm-9 text-secondary">
                  <input [(ngModel)]="customer.secondPhone"
                         formControlName="secondPhone"
                         fullWidth fieldSize="large"
                         id="secondPhone"
                         name="secondPhone"
                         nbInput
                         placeholder="Second Phone"
                         type="text">

                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-sm-3">
                  <p class="mb-0 text-sm">Work website</p>
                </div>
                <div class="col-sm-9 text-secondary">
                  <input [(ngModel)]="customer.workWebsite"
                         formControlName="workWebsite"
                         fullWidth fieldSize="large"
                         id="workWebsite"
                         name="workWebsite"
                         nbInput
                         placeholder="Work Website"
                         type="url">

                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-sm-3">
                  <p class="mb-0 text-sm">Linkedin</p>
                </div>
                <div class="col-sm-9 text-secondary">
                  <input [(ngModel)]="customer.linkedinUrl"
                         formControlName="linkedinUrl"
                         fullWidth
                         id="linkedinUrl"
                         name="linkedinUrl"
                         nbInput fieldSize="large"
                         placeholder="linkedin "
                         type="url"/>

                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-sm-3">
                  <p class="mb-0 text-sm">Description</p>
                </div>
                <div class="col-sm-9 text-secondary">
                           <textarea
                             [(ngModel)]="customer.description"
                             formControlName="description"
                             fullWidth fieldSize="large"
                             id="description"
                             name="description"
                             nbInput
                             placeholder="Description">
                           </textarea>
                </div>
              </div>
              <hr>
              <div align="right">
                   <button (click)="updateCustomerFunction()" nbButton status="success">Edit</button>
              </div>

            </nb-accordion-item-body>
          </nb-accordion-item>
        </nb-accordion>
        <br>

        <nb-accordion>
          <nb-accordion-item>
            <nb-accordion-item-header>Security Information</nb-accordion-item-header>
            <nb-accordion-item-body>
              <div class="row">
                <div class="col-sm-3">
                  <p class="mb-0 text-sm">Social Security</p>
                </div>
                <div class="col-sm-9 text-secondary">
                  <input [(ngModel)]="customer.active"
                         formControlName="active"
                         fullWidth fieldSize="large"
                         id="active"
                         name="active"
                         nbInput
                         placeholder="active "
                         type="url"/>
                </div>
              </div>
              <hr>

              <hr>
              <div class="row">
                <div class="col-sm-3">
                  <p class="mb-0 text-sm">Last modification </p>
                </div>
                <div class="col-sm-9 text-secondary">
                  {{customer.modifiedAt | date: 'YYYY MMM dd'}} at
                  {{customer.modifiedAt | date: 'HH:MM'}}

                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-sm-3">
                  <p class="mb-0 text-sm">Customer Status</p>
                </div>
                <div class="col-sm-9 text-secondary">
                <nb-select (selectedChange)="onMenuItemSelected($event)"
                           [(ngModel)]="customer.status"
                           class="w-75 mx-3"
                           formControlName="status"
                           fullWidth size="large"
                           id="status"
                           name="status"
                           placeholder="Select status">

                  <nb-option
                    *ngFor="let option of options" value="{{option.value}}">{{option.value}}</nb-option>
                </nb-select>

                <nb-tag-list class="mx-2">
                  <nb-tag *ngIf="customer.status === 'Lead' " [text]="customer.status" size="small"
                          status="basic"></nb-tag>
                  <nb-tag *ngIf="customer.status === 'Prospect' " [text]="customer.status" size="small"
                          status="danger"></nb-tag>
                  <nb-tag *ngIf="customer.status === 'Client' " [text]="customer.status" size="small"
                          status="primary"></nb-tag>

                </nb-tag-list>
              </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-sm-3">
                  <p class="mb-0 text-sm">Assignee</p>
                </div>
                <div class="col-sm-9 text-secondary">
                <nb-select (selectedChange)="onMenuItemSelectedEmployee($event)"
                       [(ngModel)]="customer.assignee"
                       [selected]="selectedEmployee"
                       class="w-100" size="large"
                       placeholder="Assignee"
                       formControlName="assignee"
                       fullWidth id="assignee"
                       name="assignee">

              <nb-option

                *ngFor="let optionEmployee of list"
                value="{{optionEmployee.id}}">
                <nb-user size="medium" [picture]="optionEmployee.employeeProfileImage" [name]="optionEmployee.employeeName"></nb-user></nb-option>

            </nb-select>


                </div>
              </div>
              <hr>
              <div align="right">    <button (click)="updateCustomerFunction()" nbButton status="success">Edit</button></div>


            </nb-accordion-item-body>
          </nb-accordion-item>
        </nb-accordion>
        <br>


      </div>
    </div>


  </form>
</div>


