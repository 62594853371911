import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {CrmTeamObjective} from '../../models/CrmTeamObjective';

const httpOptionsPlain = {
  headers: new HttpHeaders({
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }),
  'responseType': 'text as json',
};
@Injectable({
  providedIn: 'root',
})
export class CrmTeamObjectiveService {

  httpOptions = {
    headers: new HttpHeaders({

      'Content-Type': 'application/json;  charset=utf-8 ',
    }),
  };


  constructor(private httpClient: HttpClient) { }

  findAllCrmTeamObjective() {
    return this.httpClient.get(`${environment.crmUrl}CrmTeamObjective/allCrmTeamObjectives`);
  }

  addCrmTeamObjective(c: CrmTeamObjective) {
    return this.httpClient.post<CrmTeamObjective>(`${environment.crmUrl}CrmTeamObjective/add`, c, this.httpOptions);
  }

  updateCrmTeamObjective(crmTeamObjective: CrmTeamObjective): Observable<CrmTeamObjective> {
    return this.httpClient.put<CrmTeamObjective>(
      `${environment.crmUrl}CrmTeamObjective/update-CrmTeamObjective/`,
      crmTeamObjective, this.httpOptions);
  }

  deleteCrmTeamObjective(crmTeamObjective: CrmTeamObjective | string): Observable<string> {
    const id = typeof crmTeamObjective === 'string' ? crmTeamObjective : crmTeamObjective.id;
    const url = `${environment.crmUrl}CrmTeamObjective/delete/` + id;
    // @ts-ignore
    return this.httpClient.delete<string>(url, httpOptionsPlain);
  }

  getCrmTeamObjectiveByID(id: string): Observable<CrmTeamObjective> {
    return this.httpClient.get<CrmTeamObjective>(`${environment.crmUrl}CrmTeamObjective/CrmTeamObjective-by-id/` + id);
  }
}
