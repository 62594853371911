<nb-card class="col-md-12 col-lg-12 col-xxxl-12">
  <nb-card-body>
    <nb-stepper orientation="horizontal">
      <nb-step [label]="labelOne">
        <ng-template #labelOne>First step</ng-template>
        <h4 style="color: #4b88cb;">1) Sales Process</h4>
        <p class="lorem">
          You can add phases, change phase titles and adapt probabilities.
          <br>
          This is the probability that an opportunity at this stage will move to the “Won” phase.
          <br>
          Do not change the name of the "Won" and "Lost" stages.
          <br>
          Cela brisera les calculs d'introspection.
        </p>
        <div style="display: flex; flex-direction: row-reverse;">
        <button nbButton nbStepperNext >next</button>
          </div>
        <nb-card xmlns="http://www.w3.org/1999/html">
          <nb-card-header>
            CRM Sales Stages
          </nb-card-header>

          <nb-card-body>
            <ng2-smart-table [settings]="settings" [source]="source"
                             (deleteConfirm)="onDeleteConfirm($event)"
                             (createConfirm)="onCreateConfirm($event)"
                             (editConfirm)="onEditConfirm($event)">
              >
            </ng2-smart-table>
          </nb-card-body>
        </nb-card>
      </nb-step>
      <nb-step [label]="labelTwo">
        <ng-template #labelTwo>Second step</ng-template>
        <h4 style="color: #4b88cb;">2) Follow up</h4>
        <p class="lorem">
          Set the number of days before each follow-up
          <br>
          to follow up on a prospect.
        </p>
        <div style="display: flex; justify-content: space-between; align-items: center;">
          <button nbButton nbStepperPrevious>prev</button>
          <button nbButton nbStepperNext>next</button>
        </div>
        <nb-card xmlns="http://www.w3.org/1999/html">
          <nb-card-header>
            Follow-up reminder
          </nb-card-header>

          <nb-card-body>
            <ng2-smart-table [settings]="CrmFollowUpSettings" [source]="sourceCrmFollowUp"
                             (deleteConfirm)="onDeleteCrmFollowUpConfirm($event)"
                             (createConfirm)="onCreateCrmFollowUpConfirm($event)"
                             (editConfirm)="onEditCrmFollowUpConfirm($event)">
              >
            </ng2-smart-table>
          </nb-card-body>
        </nb-card>
      </nb-step>
      <nb-step [label]="labelThree">
        <ng-template #labelThree>Third step</ng-template>
        <h4 style="color: #4b88cb;">3) FOLLOWING STEPS</h4>
        <div style="display: flex;">
        <button nbButton nbStepperPrevious>prev</button>
        </div>
        <nb-card xmlns="http://www.w3.org/1999/html">
          <nb-card-header>
            FOLLOWING STEPS
          </nb-card-header>

          <nb-card-body>
            <ng2-smart-table [settings]="CrmEtapesSettings" [source]="sourceCrmEtapes"
                             (deleteConfirm)="onDeleteCrmEtapesConfirm($event)"
                             (createConfirm)="onCreateCrmEtapesConfirm($event)"
                             (editConfirm)="onEditCrmEtapesConfirm($event)">
              >
            </ng2-smart-table>
          </nb-card-body>
        </nb-card>
      </nb-step>
    </nb-stepper>
  </nb-card-body>
</nb-card>

