import {Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import {CrmTeamMember} from '../../../models/CrmTeamMember';
import {Customer} from '../../../models/Customer';
import {CustomerService} from '../../../services/customers/customer.service';
import {CrmStages} from '../../../models/CrmStages';
import {CrmStagesService} from '../../../services/crmStages/crm-stages.service';
import {CrmTeamObjective} from '../../../models/CrmTeamObjective';
import {CrmTeamObjectiveService} from '../../../services/crmTeamObjective/crm-team-objective.service';
import {CrmEtapes} from '../../../models/CrmEtapes';
import {CrmEtapesService} from '../../../services/CrmEtapes/crm-etapes.service';
import { DatepickerEditorComponent } from '../Confiiguration-Smart-Table/datepicker-editor/datepicker-editor.component';
import {CrmTeamTrackingDealsService} from '../../../services/crmTeamTrackingDeals/crm-team-tracking-deals.service';
import {CrmTrackingDeals} from '../../../models/CrmTrackingDeals';
import { Router } from '@angular/router';
import {
  NbComponentStatus,
  NbGlobalPhysicalPosition,
  NbGlobalPosition,
  NbToastrConfig,
  NbToastrService} from '@nebular/theme';
import {ExportService} from '../../../../../shared/exports/export.service';
@Component({
  selector: 'ngx-crm-team-tracking-deals',
  templateUrl: './crm-team-tracking-deals.component.html',
  styleUrls: ['./crm-team-tracking-deals.component.scss'],
})
export class CrmTeamTrackingDealsComponent implements OnInit {
  source: LocalDataSource = new LocalDataSource();
  selectedCustomer: string = '';
  selectedStage: string = '';
  selectedTeamObjective: string = '';
  list: Customer[] = [];
  search: string = '';
  current: number = 1;
  total: string | number;
  pageSize: number = 4;
  listStage: CrmStages[] = [];
  listTeamObjective: CrmTeamObjective[] = [];
  listEtape: CrmEtapes[] = [];
  listCrmTeamTrackingDeals: CrmTrackingDeals[] = [];
  totalValeur: string;
  totalRecette: string;
  // toaster config begin
  config: NbToastrConfig;
  index = 1;
  destroyByClick = true;
  duration = 2000;
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  preventDuplicates = false;
  status: NbComponentStatus = 'primary';
  // toaster config end
  settings = {
    add: {
      addButtonContent: '<i class="nb-plus" ></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmCreate: true,
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmSave: true,
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },

    columns: {
     /* nomEntreprise: {
        title: 'NOM ENTREPRISE',
        type: 'html',
        editor: {
          type: 'list',
          config: {
            list: [],
          },
        },
        valuePrepareFunction: (cell, row) => cell ? cell.name : '',
      }, */
      prospect: {
        title: 'PROSPECT',
        type: 'html',
        editor: {
          type: 'list',
          config: {
            list: [],
          },
        },
        valuePrepareFunction: (cell, row) => cell ? cell.name : '',
      },
      emailContact: {
        title: 'Email du contact',
        type: 'string',
        valuePrepareFunction: (cell, row) => {
          return cell ? cell : '';  // Si cell est vide ou undefined, affiche "-"
        },
      },

      // Autres configurations de colonnes...
      stage: {
        title: 'Stage',
        type: 'html',
        editor: {
          type: 'list',
          config: {
            list: this.listStage.map(item => ({ value: item.id, title: `${item.stage} - ${item.probabilite}%` })),
          },
        },
        valuePrepareFunction: (cell, row) => {
          if (typeof cell === 'object' && cell.stage) {
            return `${cell.stage} : ${cell.probabilite}`;
          } else {
            const stage = this.listStage.find(s => s.id === cell);
            return stage ? `${stage.stage} - ${stage.probabilite}%` : '';
          }
        },
      },

      valeur: { title: 'Valeur du marché en dinars', type: 'string' },
      /*recette: {
        title: 'Recettes attendues',
        type: 'string',
        editable: false,  // This should prevent editing
        addable: false,  // Ensure this is added to prevent adding
        valuePrepareFunction: (cell, row) => {
          return cell ? `${CrmTeamTrackingDealsComponent.formatRecette(cell, row.valeur)}` : '';
        },
      },*/
      /*recetteR: {
        title: 'Recette',
        type: 'string',
        valuePrepareFunction: (cell, row) => {
          return cell ? cell : '';  // Si cell est vide ou undefined, affiche "-"
        },
      },*/
      dateP: {
        title: 'Date du premier contact',
        type: 'text',
        renderComponent: DatepickerEditorComponent,
        editor: {
          type: 'custom',
          component: DatepickerEditorComponent,
        },
        valuePrepareFunction: (cell, row) => {
          return cell ? new Date(cell).toLocaleDateString('fr-FR') : ''; // Format the date or adjust the locale as needed
        },
      },
      /*dateC: {
        title: 'Date de clôture prévue',
        type: 'text',
        renderComponent: DatepickerEditorComponent,
        editor: {
          type: 'custom',
          component: DatepickerEditorComponent,
        },
        valuePrepareFunction: (cell, row) => {
          return cell ? new Date(cell).toLocaleDateString('fr-FR') : ''; // Format the date or adjust the locale as needed
        },
      },*/
      teamMember: {
        title: 'Team Member',
        type: 'html',
        editor: {
          type: 'list',
          config: {
            list: [],  // Cette liste doit être remplie avec les données des employés
          },
        },
        valuePrepareFunction: (cell, row) => cell ? cell.representantCommercial : '',
      },
      /*dateDEchange: {
        title: 'Date dernier échange',
        type: 'text',
        renderComponent: DatepickerEditorComponent,
        editor: {
          type: 'custom',
          component: DatepickerEditorComponent,
        },
        valuePrepareFunction: (cell, row) => {
          return cell ? new Date(cell).toLocaleDateString('fr-FR') : ''; // Format the date or adjust the locale as needed
        },
      },*/
      etapeSuivante: {
        title: 'Etape Suivante',
        type: 'html',
        editor: {
          type: 'list',
          config: {
            list: [],  // Cette liste doit être remplie avec les données des employés
          },
        },
        valuePrepareFunction: (cell, row) => cell ? cell.etape : '',
      },
    },
  };


  // Example of setting selectedEmployee
  onCustomerSelected(Id: string): void {
    this.selectedCustomer = Id;
  }
  onStageSelected(Id: string): void {
    this.selectedStage = Id;
  }
  onTeamObjectiveSelected(Id: string): void {
    this.selectedTeamObjective = Id;
  }


  constructor(private changeDetectorRefs: ChangeDetectorRef,
              private es: CustomerService,
              private st: CrmStagesService,
              private to: CrmTeamObjectiveService,
              private et: CrmEtapesService,
              private ct: CrmTeamTrackingDealsService,
              private exportService: ExportService,
              private toastrService: NbToastrService,
              private router: Router) {
    // const data = this.service.getData();
    // this.source.load(data);
    // this.loadCrmTrackingDeals();
    this.getAllCrmTrackingDeals();
  }

  redirectToCreateProspect(): void {
    this.router.navigate(['/crm/create-prospect']);
  }

  ngOnInit(): void {

    // employees data
    this.es.findProspects().subscribe(
      (customers: Customer[]) => {
        this.list = customers;
        this.updateProspectEditorOptions(customers);

      }, (err) => {
        console.log(err);
      },
    );

    /* this.es.getAllClients().subscribe(
      (customers: Customer[]) => {
        this.list = customers;
        this.updateCustomerEditorOptions(customers);

      }, (err) => {
        console.log(err);
      },
    ); */

    this.st.findAllCrmStages().subscribe(
      (crmStages: CrmStages[]) => {
        this.listStage = crmStages;
        this.updateCrmStagesEditorOptions(crmStages);

      }, (err) => {
        console.log(err);
      },
    );

    this.to.findAllCrmTeamObjective().subscribe(
      (crmTeamObjectives: CrmTeamObjective[]) => {
        this.listTeamObjective = crmTeamObjectives;
        this.updateCrmTeamObjectiveEditorOptions(crmTeamObjectives);

      }, (err) => {
        console.log(err);
      },
    );

    this.et.findAllCrmEtapes().subscribe(
      (crmEtapes: CrmEtapes[]) => {
        this.listEtape = crmEtapes;
        this.updateCrmEtapesEditorOptions(crmEtapes);

      }, (err) => {
        console.log(err);
      },
    );

    this.ct.findAllCrmTrackingDeals().subscribe(
      (response) => {
        if (Array.isArray(response)) {
          this.updateTotalAndLoadDataValeur(response);
        } else {
          console.error('Expected an array of data:', response);
        }
      },
      error => {
        console.error('Error loading CRM TrackingDeals:', error);
      },
    );
  }

  onArchiveConfirm(crmTrackingDeals: CrmTrackingDeals, id: string) {
    this.ct.archiveCrmTeamTrackingDeals(crmTrackingDeals, id).subscribe(
      () => {
        this.showToast('success', 'Archived Successfully',
          'Sales Tracking Deal Archived !!');
        this.getAllCrmTrackingDeals();
        this.router.navigate(['/crm/crm-team/tracking-deals']).then(() => {
          this.getAllCrmTrackingDeals();
        });
      });
  }
  getAllCrmTrackingDeals() {
    this.ct.findAllCrmTrackingDeals().subscribe(
      (data: CrmTrackingDeals[]) => {
        this.listCrmTeamTrackingDeals  = data.filter(
          (c => c.archive === 'False'),
        );
        this.updateTotalAndLoadDataValeur(this.listCrmTeamTrackingDeals);
      },
    );
  }
  formatNumber(value: string | number): string {
    const numericValue = Number(value);  // Convert the input to a number
    if (isNaN(numericValue)) {
      return '0';  // Return '0' or some default value if conversion fails
    }
    return numericValue.toLocaleString();  // Use toLocaleString to format the number
  }


  updateTotalAndLoadDataValeur(response: any[]) {
    const filteredResponse = response.filter(item => item.archive === 'False');
    // Calculate and store totals
      this.totalValeur = filteredResponse.reduce((sum, current) => sum + parseFloat(current.valeur || '0'), 0);
      this.totalRecette = filteredResponse.reduce((sum, current) => sum + parseFloat(current.recette || '0'), 0);
    console.log('Calculated totalRecette:', this.totalRecette);
    this.ct.updateTotalRecette(parseFloat(this.totalRecette));

    // Optionally update the datasource or handle UI updates
    this.source.load(filteredResponse);
    this.changeDetectorRefs.detectChanges();
  }

  updateCrmEtapesEditorOptions(crmEtapes: CrmEtapes[]) {
    this.settings.columns.etapeSuivante.editor.config.list = crmEtapes.map(etape => ({
      value: etape.id,  // This should be the identifier used for selection
      title: etape.etape,  // This is what will be displayed in the dropdown
    }));

    // Important: Trigger a settings update to refresh the table
    this.settings = Object.assign({}, this.settings);
  }
  updateCrmTeamObjectiveEditorOptions(crmTeamObjectives: CrmTeamObjective[]) {
    this.settings.columns.teamMember.editor.config.list = crmTeamObjectives.map(teamObj => ({
      value: teamObj.id,  // This should be the identifier used for selection
      title: teamObj.representantCommercial,  // This is what will be displayed in the dropdown
    }));

    // Important: Trigger a settings update to refresh the table
    this.settings = Object.assign({}, this.settings);
  }
  /*updateCustomerEditorOptions(customers: Customer[]) {
    this.settings.columns.nomEntreprise.editor.config.list = customers.map(cus => ({
      value: cus.id,  // This should be the identifier used for selection
      title: cus.name,  // This is what will be displayed in the dropdown
    }));

    // Important: Trigger a settings update to refresh the table
    this.settings = Object.assign({}, this.settings);
  }*/
  updateProspectEditorOptions(customers: Customer[]) {
    this.settings.columns.prospect.editor.config.list = customers.map(cust => ({
      value: cust.id,  // This should be the identifier used for selection
      title: cust.name,  // This is what will be displayed in the dropdown
    }));

    // Important: Trigger a settings update to refresh the table
    this.settings = Object.assign({}, this.settings);
  }
  updateCrmStagesEditorOptions(crmStages: CrmStages[]) {
    this.settings = {
      ...this.settings,
      columns: {
        ...this.settings.columns,
        stage: {
          ...this.settings.columns.stage,
          editor: {
            type: 'list',
            config: {
              list: crmStages.map(item => ({ value: item.id, title: `${item.stage} - ${item.probabilite}` })),
            },
          },
        },
      },
    };
  }

  loadCrmTrackingDeals() {
    this.ct.findAllCrmTrackingDeals().subscribe(
      (response: any) => {
        if (Array.isArray(response)) {
          this.updateTotalAndLoadDataValeur(response);
        } else {
          console.error('Expected an array of data:', response);
        }
      },
      error => {
        console.error('Error loading CRM TrackingDeals:', error);
      },
    );
  }

  /*onCreateConfirm(event): void {
    const trackingDeal = new CrmTrackingDeals();
    trackingDeal.id = event.newData.id;
    trackingDeal.emailContact = event.newData.emailContact;
    trackingDeal.valeur = event.newData.valeur;
    trackingDeal.recette = event.newData.recette;
    trackingDeal.recetteR = event.newData.recetteR;
    trackingDeal.dateP = event.newData.dateP;
    trackingDeal.dateC = event.newData.dateC;
    trackingDeal.progresVente = event.newData.progresVente;
    trackingDeal.dateDEchange = event.newData.dateDEchange;
    // Assuming you have services to fetch these full objects if not already available:
    this.es.getCustomerById(event.newData.nomEntreprise).subscribe(customer => {
      trackingDeal.nomEntreprise = customer;
      this.es.getCustomerById(event.newData.prospect).subscribe(customerr => {
        trackingDeal.prospect = customerr;
      this.st.getCrmStagesByID(event.newData.stage).subscribe(crmStages => {
        trackingDeal.stage = crmStages;
        // Convert `valeur` from string to number
        const valeur = parseFloat(event.newData.valeur);
        // Convert `probabilite` from string to number and handle as percentage
        const probabilite = CrmTeamTrackingDealsComponent.extractPercentage(crmStages.probabilite);

        // Calculate and set `recette` as a fixed decimal string
        trackingDeal.recette = (valeur * probabilite).toFixed(2);
        this.to.getCrmTeamObjectiveByID(event.newData.teamMember).subscribe(crmTeamObjective => {
          trackingDeal.teamMember = crmTeamObjective;
          this.et.getCrmEtapesByID(event.newData.etapeSuivante).subscribe(crmEtapes => {
            trackingDeal.etapeSuivante = crmEtapes;
            // Do similar for other fields needing full objects
            // After all data is prepared:
            this.ct.addCrmTrackingDeals(trackingDeal).subscribe(data => {
              this.loadCrmTrackingDeals();
              event.confirm.resolve(data);
            }, error => {
              console.error('Error adding CRM TrackingDeals:', error);
              event.confirm.reject();
            });
          });
        });
      });
    });
  });
  }*/



  /*onEditConfirm(event): void {
    this.st.getCrmStagesByID(event.newData.stage).subscribe(crmStages => {
      event.newData.stage = crmStages;
      // Convert `valeur` from string to number
      const valeur = parseFloat(event.newData.valeur);
      const probabilite = CrmTeamTrackingDealsComponent.extractPercentage(crmStages.probabilite);

      // Calculate and set `recette` as a fixed decimal string
      event.newData.recette = (valeur * probabilite).toFixed(2);
    this.ct.updateCrmTrackingDeals(event.newData).subscribe(
      data => {
        // Assurez-vous que data contient l'objet modifié complet
        event.confirm.resolve(data); // Mettez à jour la ligne dans la table
        this.source.update(event.data, data);
      },
      error => {
        console.log('Error updating CRM TrackingDeals:', error);
        event.confirm.reject();
      });
    });
  }

  static countDecimals(value) {
    if (!value.includes('.')) return 0;  // No decimal point, no decimals
    return value.split('.')[1].length;   // Count the number of characters after the decimal point
  }
  static formatRecette(value, baseValue) {
    const decimalPlaces = CrmTeamTrackingDealsComponent.countDecimals(baseValue);

    // Convert the value to a number to ensure it handles floating-point representations accurately
    const number = parseFloat(value);

    // Format the number to the same number of decimal places as the base value
    return number.toFixed(decimalPlaces);
  }

  static extractPercentage(probabilite: string): number {
    const match = probabilite.match(/\d+/); // Find digits in the string
    return match ? parseFloat(match[0]) / 100 : 0; // Convert to a fraction
  }
  onDeleteConfirm(event): void {
    if (window.confirm('Are you sure you want to delete?')) {
      this.ct.deleteCrmTrackingDeals(event.data.id).subscribe(() => {
        event.confirm.resolve();
        this.loadCrmTrackingDeals();
      });
    } else {
      event.confirm.reject();
    }
  }*/
  exportAsXLSX(): void {
    this.exportService.exportAsExcelFile(this.listCrmTeamTrackingDeals, 'dataCrmTrackingDeals');
  }
  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: this.destroyByClick,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
    };
    const titleContent = title ? ` ${title}` : '';

    this.index += 1;
    this.toastrService.show(
      body,
      `${titleContent}`,
      config);
  }
}
