import {Visit} from './visit';

export class Quotation {
  id: string;
  title: string;
  comment: string;
  status: string;
  visit: Visit;
  archive: string;
}
