
<div *ngIf="crmTrackingDeals" class="container">
  <div class="row">
    <div class="col-md-10 col-sm-12">
      <h2 class="more-vertical">
        Sales Tracking Deal Details </h2>
    </div>

    <div align="right" class="col-md-2 justify-content-end">
      <a  [routerLink]="['/crm/edit-crm-team-tracking-deals/', crmTrackingDeals.id]" class="w-25"
         fullWidth nbButton nbTooltip="Click here to edit Sales Tracking Deal data" status="warning" nbTooltipStatus="primary" shape="round">
        <nb-icon icon="edit-outline"></nb-icon>
      </a>
      <a [routerLink]="['/crm/crm-team/tracking-deals']" class="w-25 mx-3"
         fullWidth nbButton nbTooltip="Back to Sales Tracking Deal list" nbTooltipStatus="primary"  shape="round" status="basic">
        <nb-icon icon="corner-up-right-outline"></nb-icon>
      </a>

    </div>

  </div>
  <div class="row mt-3">
    <div class="col-md-4 mb-3">
      <nb-card>
        <nb-card-body class="card-body">
          <div class="d-flex flex-column align-items-center text-center">
            <div class="row">

              <img alt="" src="{{crmTrackingDeals.nomEntreprise.image}}" style="width: 150px;height: 150px;border-radius: 100%;">
            </div>

            <div class="mt-3">
              <h4>{{crmTrackingDeals.nomEntreprise.name}}</h4>
              <div class="row mt-3">
                <div class="col-md-7">
                  <p class="mt-1 mx-2">  {{crmTrackingDeals.nomEntreprise.workEmail}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                </div>
                <div class="col-md-8">
                  <nb-tag-list>
                    <nb-tag [text]="crmTrackingDeals.nomEntreprise.status" status="danger" appearance="filled"></nb-tag>
                  </nb-tag-list>
                </div>
              </div>
            </div>
          </div>
        </nb-card-body>
      </nb-card>
      <nb-card>
        <nb-card-body class="card-body">
          <div class="d-flex flex-column align-items-center text-center">
            <div class="row">

              <img alt="" src="{{crmTrackingDeals.prospect.image}}" style="width: 150px;height: 150px;border-radius: 100%;">
            </div>

            <div class="mt-3">
              <h4>{{crmTrackingDeals.prospect.name}}</h4>
              <div class="row mt-3">
                <div class="col-md-7">
                  <p class="mt-1 mx-2">  {{crmTrackingDeals.prospect.workEmail}}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                </div>
                <div class="col-md-8">
                  <nb-tag-list>
                    <nb-tag [text]="crmTrackingDeals.prospect.status" status="success" appearance="filled"></nb-tag>
                  </nb-tag-list>
                </div>
              </div>
            </div>
          </div>
        </nb-card-body>
      </nb-card>

    </div>
    <div class="col-md-8">
      <nb-accordion>
        <nb-accordion-item expanded="true">
          <nb-accordion-item-header>More Details</nb-accordion-item-header>
          <nb-accordion-item-body>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Team Member</p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{crmTrackingDeals.teamMember.representantCommercial}}
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Email Contact</p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{crmTrackingDeals.emailContact}}
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Valeur du marché</p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{crmTrackingDeals.valeur}}
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Stage</p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{crmTrackingDeals.stage.stage}} : {{crmTrackingDeals.stage.probabilite}}
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Recette </p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{crmTrackingDeals.recetteR }}
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Recette attendue</p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{crmTrackingDeals.recette}}<br>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Etape Suivante</p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{crmTrackingDeals.etapeSuivante.etape}}<br>
              </div>
            </div>
            <hr>

          </nb-accordion-item-body>
        </nb-accordion-item>
      </nb-accordion>
      <br>
      <nb-accordion>
        <nb-accordion-item expanded="true">
          <nb-accordion-item-header>Dates</nb-accordion-item-header>
          <nb-accordion-item-body>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Date du premier contact</p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{crmTrackingDeals.dateP | date: 'yyyy-MM-dd'}}
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Date de clôture prévue</p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{crmTrackingDeals.dateC | date: 'yyyy-MM-dd'}}
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-sm-3">
                <p class="mb-0 text-sm">Date dernier échange</p>
              </div>
              <div class="col-sm-9 text-secondary">
                {{crmTrackingDeals.dateDEchange | date: 'yyyy-MM-dd'}}
              </div>
            </div>

          </nb-accordion-item-body>
        </nb-accordion-item>
      </nb-accordion>
      <br>
      <br>
    </div>
  </div>




</div>
