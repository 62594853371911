<nb-card-header>
  <h4 style="color: #4b88cb; text-align: center;">MEMBERS OBJECTIVES</h4>
</nb-card-header>
<nb-card xmlns="http://www.w3.org/1999/html">
  <nb-card-header>
    Set annual goals for your sales reps
  </nb-card-header>
  <nb-card-body>
    <ng2-smart-table [settings]="settings" [source]="source"
                     (deleteConfirm)="onDeleteConfirm($event)"
                     (createConfirm)="onCreateConfirm($event)"
                     (editConfirm)="onEditConfirm($event)">
      >
    </ng2-smart-table>
  </nb-card-body>
</nb-card>
