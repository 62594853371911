import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {CrmTeamMember} from '../../models/CrmTeamMember';

const httpOptionsPlain = {
  headers: new HttpHeaders({
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }),
  'responseType': 'text as json',
};
@Injectable({
  providedIn: 'root',
})
export class CrmTeamMemberService {

  httpOptions = {
    headers: new HttpHeaders({

      'Content-Type': 'application/json;  charset=utf-8 ',
    }),
  };


  constructor(private httpClient: HttpClient) { } 

  findAllCrmTeamMember() {
    return this.httpClient.get(`${environment.crmUrl}CrmTeamMember/allCrmTeamMembers`);
  }

  addCrmTeamMember(c: CrmTeamMember, idEmployee: string) {
    return this.httpClient.post<CrmTeamMember>(`${environment.crmUrl}CrmTeamMember/add/${idEmployee}`, c, this.httpOptions);
  }

  updateCrmTeamMember(crmTeamMember: CrmTeamMember, idEmployee: string): Observable<CrmTeamMember> {
    return this.httpClient.put<CrmTeamMember>(
      `${environment.crmUrl}CrmTeamMember/update-CrmTeamMember/` + idEmployee,
      crmTeamMember, this.httpOptions);
  }

  deleteCrmTeamMember(crmTeamMember: CrmTeamMember | string): Observable<string> {
    const id = typeof crmTeamMember === 'string' ? crmTeamMember : crmTeamMember.id;
    const url = `${environment.crmUrl}CrmTeamMember/delete/` + id;
    // @ts-ignore
    return this.httpClient.delete<string>(url, httpOptionsPlain);
  }

  getCrmTeamMemberByID(id: string): Observable<CrmTeamMember> {
    return this.httpClient.get<CrmTeamMember>(`${environment.crmUrl}CrmTeamMember/CrmTeamMember-by-id/` + id);
  }
}
