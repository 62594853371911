import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TokenStorageService } from '../service/token/token.service';
import { throwError } from 'rxjs';
import { AuthService } from '../service/auth.service';
const TOKEN_HEADER_KEY = 'Authorization';       // for Spring Boot back-end

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor   {

  constructor(private tokenService: TokenStorageService, private router: Router, private authS: AuthService
    ) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const token = this.tokenService.getToken();
      const userEmail = this.authS.getUserEmail();
      const sessionId = this.authS.getSessionId();
  
      // Clone the request and set the Authorization and user-id headers
      let authReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          'user-id': userEmail,
          'X-Session-ID' : sessionId
        }
      }); 
  
      // Handle the request
      return next.handle(authReq).pipe(
        catchError(err => {
          // onError
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.router.navigate(['/auth/login']);
            }
          }
          return throwError(err);
        })
      );
    }

  // intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //   let authReq = req;
  //   const token = this.tokenService.getToken();

  //   if (token != null) {
  //     authReq = req.clone(
  //       {
  //         setHeaders: {Authorization: `Bearer ${token}`} },
  //       );
  //   }
  //   return next.handle(authReq).pipe(catchError(err => {
  //     // onError
  //     if (err instanceof HttpErrorResponse) {
  //         if (err.status === 401) {
  //           this.router.navigate(['/auth/login']);

  //         }
  //     }
  //       return throwError(err);
  //     // return Observable.throw(err);
  // },
  // ));
  // }
  // interceptMail(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //   const userEmail = this.authS.getUserEmail() // Assume this method exists and returns the user's email
  //   console.log('User email in interceptor:', userEmail);

  //   const clonedRequest = req.clone({ 
  //     headers: req.headers.set('user-id', userEmail) 
  //   });
  //   console.log(clonedRequest,"hneeeeeeeeeeee") 
 
  //   return next.handle(clonedRequest);
  // } 
}

