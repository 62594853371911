<div class="content">
  <div class="container">
    <nb-card-header>
      <div class="row">
        <div class="col-md-3">
          <h3>Quotations</h3>
        </div>
        <div class="col-md-4 mx-3">
          <div class="row">
            <input [(ngModel)]="search"
                   class="" fullWidth  fieldSize="large" nbInput
                   nbTooltip="Tap here to search your quotation"
                   nbTooltipStatus="primary" placeholder="Search quotation"
                   type="text">
          </div>
        </div>
        <div class="col-md-4 pt-1">
          <div>
            <a [routerLink]="['/crm/grid-quotations']"
               nbButton nbTooltip="Visualize data on dimensions grid 2x2"
               nbTooltipStatus="primary" status="primary">
              <nb-icon icon="grid-outline"></nb-icon>
            </a>&nbsp;
            <a [routerLink]="['/crm/quotations']" class="mx-1" nbButton
               nbTooltip="Visualize data on keypad "
               nbTooltipStatus="primary" status="warning">
              <nb-icon icon="keypad-outline"></nb-icon>
            </a>
            <a [routerLink]="['/crm/table-quotations']" class="mx-1" nbButton
               nbTooltip="Visualize data on list " nbTooltipStatus="primary" status="basic">
              <nb-icon icon="list-outline"></nb-icon>
            </a>
            <button (click)="exportAsXLSX()"
                    align="right" class="mx-1"
                    nbButton nbTooltip="Click here to export data to Excel" nbTooltipStatus="primary"
                    status="success">
              <i class="fas fa-file-excel text-white"></i>
            </button>
            <button [routerLink]="['/crm/demand-quotation']" class="mx-1" nbButton
                    nbTooltip="Click here to demand quotation" nbTooltipStatus="primary"
                    status="primary">
              <nb-icon icon="plus-outline"></nb-icon>
              Demand
            </button>
          </div>
        </div>
      </div>
    </nb-card-header>
    <div *ngIf="!dataQuotations || dataQuotations.length === 0" align="center">
      <nb-alert class="w-100 mt-3" outline="danger">No Data Found.</nb-alert>
    </div>
    <div *ngIf="(dataQuotations | filter : search).length === 0" [hidden]="dataQuotations.length === 0"
         class="">
      <nb-alert class="col-lg-12 mt-3" fullWidth outline="danger">The item searched is not found. Please make sure
        while typing your data
      </nb-alert>
    </div>
    <div class="page-content container note-has-grid mt-4">
      <div class="row">
        <div class="col-md-3">
         
              <nb-form-field>
                <nb-icon class="mt-3" icon="funnel-outline" nbPrefix></nb-icon>
                      <nb-select (selectedChange)="filterByStatus($event)"
                             class="mx-2 mt-3 w-75" nbTooltip="Click here to filter by status"
                             nbTooltipStatus="primary"
                             placeholder="Status"
                      >
                    <nb-option (click)="getAllQuotations()" value="All">All</nb-option>
                          <nb-option value="PENDING">PENDING</nb-option>

                    <nb-option value="Validated">Validated</nb-option>
                  </nb-select>
              </nb-form-field>
          
                <nb-form-field>
                <nb-icon class="mt-3" icon="funnel-outline" nbPrefix></nb-icon>
                <nb-select (selectedChange)="filterByVisit($event)" 
                           class="mt-3 mx-2 w-75" placeholder="Visit"
                nbTooltip="Click here to filter by visit" nbTooltipStatus="primary">
                  <nb-option (click)="getAllQuotations()" value="All">All</nb-option>
                  <nb-option
                      *ngFor="let optionVisit of dataVisits"
                      value="{{optionVisit.id}}">{{optionVisit.title}}</nb-option>
      
      
                </nb-select>
              </nb-form-field>  
               <nb-form-field>
                <nb-icon class="mt-3" icon="funnel-outline" nbPrefix></nb-icon>
                <nb-select (selectedChange)="filterByProperty($event)" 
                           class="mt-3 mx-2 w-75" placeholder="Property"
                nbTooltip="Click here to filter by property" nbTooltipStatus="primary">
                  <nb-option (click)="getAllQuotations()" value="All">All</nb-option>
                   <nb-option
                      *ngFor="let optionProperty of properties"
                      value="{{optionProperty.propertyId}}">{{optionProperty.propertyName}}</nb-option>
                </nb-select>
              </nb-form-field>
            
                      <div class="row mt-3 mx-2">
          <label>Quotations Per Page : </label>
            <input [(ngModel)]="pageSize" [disabled]="dataQuotations.length === 0"
                   class="mt-2 col-lg-8"
                   min="9" nbInput
                   nbTooltip="Tap a number to get contracts per page" nbTooltipStatus="primary"
                   placeholer="Number to get contracts per page" type="number">
          </div>
         
        </div>
        <div class="col-md-9 mt-3">
          <div class="tab-content">
            <div class="note-has-grid row" id="note-full-container">
              <div
                *ngFor="let quotation of dataQuotations |filter : search | paginate : {itemsPerPage:pageSize, currentPage: current,totalItems: dataQuotations.length}"
                class="col-md-6 single-note-item all-category">
                <nb-card class=" card-body">
                  <span class="side-stick"></span>
                  <div class="row">
                    <div class="col-md-9">
                      <div class="row">
                        <div class="col-md-9 mx-2">
                          <p>{{quotation.title}}
                          </p>
                        </div>
                      </div>
                      <p class="note-date ">
                        <nb-icon class="text-primary"
                                 icon="calendar-outline" nbTooltip="Quotation date">
                        </nb-icon>
                        {{quotation.createdAt | date : 'EEEE  MMMM YYYY'}}
                      </p>
                      <p class="note-date">
                        <nb-icon class="text-info" icon="text-outline"></nb-icon>
                        {{quotation?.visit?.title}}
                      </p>
                    </div>
                    <div class="col-md-3">
                      <button [matMenuTriggerFor]="menu" aria-expanded="false" aria-haspopup="true"
                              aria-label="Example icon-button with a menu"
                              class="file-close" mat-icon-button
                              nbButton nbTooltip="Click here to see advanced menu"
                              nbTooltipStatus="primary"
                              shape="round"
                              size="small"
                              status="basic">
                        <nb-icon icon="more-horizontal-outline"></nb-icon>
                      </button>
                      <mat-menu #menu="matMenu">
                        <button mat-menu-item>
                          <a [routerLink]="['/crm/edit-quotation/',quotation.id]"
                             fullWidth nbButton>
                      <span class="text-warning">
                        <nb-icon icon="edit-outline"></nb-icon>
                      </span> Edit
                          </a>
                        </button>
                       <button mat-menu-item>
                          <a [routerLink]="['/crm/quotation-details/',quotation.id]" fullWidth nbButton>
                            <span class="text-info"><nb-icon icon="eye-outline"></nb-icon></span>Details
                          </a>
                        </button>
                        <button mat-menu-item>
                          <a (click)="onArchiveConfirm(quotation,quotation.id)" fullWidth nbButton status="basic">
                            <nb-icon icon="archive-outline"></nb-icon>
                            Archive
                          </a>
                        </button>
                      </mat-menu>
                    </div>
                  </div>
                </nb-card>
              </div>
            </div>
          </div>
        </div>
      </div>
      
       <div class="d-flex justify-content-center">
      <nb-card class="card ml-auto mr-auto text-center" style="height: 30px; width: auto ;">
        <pagination-controls (pageChange)="current = $event" align="right" nextLabel=""
                             previousLabel=""></pagination-controls>

      </nb-card>
    </div>
      
    </div>
  </div>
</div>