import {Users} from '../../auth/model/Users';
export class Event {
  id: string;
  title: string;
  type: string;
  user: { [key: string]: any };
  otherType: string;
  image: string;
  description: string;
  address: string;
  dateOfEvent: Date;
  timeOfEvent: string;
  country: string;
  statusEvent: string;
  participants: number;
  archive: string;
  active: boolean;
  createdAt: Date;
  modifiedAt: Date;

}
