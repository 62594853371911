import {Employee} from '../../humain-capital-management/models/Employee';

export class CrmTeamMember {
    id: string;

    projet: string;

    coache: string;

    coach: Employee;

    dateVisite: Date;

    heureVisite: string;

    phone: string;

    duree: string;

    address: string;

    region: string;
   }
