import { Component } from '@angular/core';
import { LocalDataSource } from 'ng2-smart-table';
import {CrmStages} from '../../../models/CrmStages';
import {CrmStagesService} from '../../../services/crmStages/crm-stages.service';
import {CrmFollowUpService} from '../../../services/crmFollowUp/crm-follow-up.service';
import {CrmEtapesService} from '../../../services/CrmEtapes/crm-etapes.service';

@Component({
  selector: 'ngx-crm-sales-stages',
  templateUrl: './crm-sales-stages.component.html',
  styleUrls: ['./crm-sales-stages.component.scss'],
})
export class CrmSalesStagesComponent {
  source: LocalDataSource = new LocalDataSource();
  sourceCrmFollowUp: LocalDataSource = new LocalDataSource();
  sourceCrmEtapes: LocalDataSource = new LocalDataSource();
  crmStages: CrmStages = new CrmStages();
  CrmEtapesSettings = {
    add: {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmCreate: true,
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmSave: true,
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    columns: {
      etape: { title: 'LES ETAPES SUIVANTES', type: 'string' },
    },
  };
  CrmFollowUpSettings = {
    add: {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmCreate: true,
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmSave: true,
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    columns: {
      nbJours: { title: 'NOMBRE DE JOURS AVANT LE PROCHAIN RAPPEL', type: 'number' },
    },
  };
  settings = {
    add: {
      addButtonContent: '<i class="nb-plus"></i>',
      createButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmCreate: true,
    },
    edit: {
      editButtonContent: '<i class="nb-edit"></i>',
      saveButtonContent: '<i class="nb-checkmark"></i>',
      cancelButtonContent: '<i class="nb-close"></i>',
      confirmSave: true,
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    columns: {
      stage: { title: 'STAGE', type: 'string' },
      probabilite: { title: 'PROBABILITE', type: 'string' },
    },
  };



  constructor(private service: CrmStagesService, private serviceCrmFollowUp: CrmFollowUpService, private serviceCrmEtapes: CrmEtapesService ) {
    // const data = this.service.getData();
    // this.source.load(data);
    this.loadCrmFollowUp();
    this.loadCrmStages();
    this.loadCrmEtapes();
  }

  onCreateConfirm(event): void {
    this.service.addCrmStages(event.newData).subscribe(
      data => {
        // Mettez à jour la table pour refléter les données du serveur au lieu d'ajouter localement
        this.loadCrmStages();  // Rechargez toutes les données depuis le serveur
        event.confirm.resolve();  // Confirmez l'ajout dans la table
      },
      error => {
        console.log('Error adding CRM stages:', error);
        event.confirm.reject();
      },
    );
  }



  loadCrmStages() {
    this.service.findAllCrmStages().subscribe(
      (response: any) => {
        if (Array.isArray(response)) {
          this.source.load(response);
        } else {
          console.error('Expected an array of data:', response);
        }
      },
      error => {
        console.error('Error loading CRM stages:', error);
      },
    );
  }


  onEditConfirm(event): void {

    this.service.updateCrmStages(event.newData).subscribe(
      data => {
        // Assurez-vous que data contient l'objet modifié complet
        event.confirm.resolve(data); // Mettez à jour la ligne dans la table
        this.source.update(event.data, data);
      },
      error => {
        console.log('Error updating CRM stages:', error);
        event.confirm.reject();
      },
    );
  }
  onDeleteConfirm(event): void {
    if (window.confirm('Are you sure you want to delete?')) {
      this.service.deleteCrmStages(event.data).subscribe(data => {
        event.confirm.resolve();
      });
    } else {
      event.confirm.reject();
    }
  }

  onCreateCrmFollowUpConfirm(event): void {
    this.serviceCrmFollowUp.addCrmFollowUp(event.newData).subscribe(
      data => {
        // Mettez à jour la table pour refléter les données du serveur au lieu d'ajouter localement
        this.loadCrmFollowUp();  // Rechargez toutes les données depuis le serveur
        event.confirm.resolve();  // Confirmez l'ajout dans la table
      },
      error => {
        console.log('Error adding CRM follow up:', error);
        event.confirm.reject();
      },
    );
  }



  loadCrmFollowUp() {
    this.serviceCrmFollowUp.findAllCrmFollowUp().subscribe(
      (response: any) => {
        if (Array.isArray(response)) {
          this.sourceCrmFollowUp.load(response);
        } else {
          console.error('Expected an array of data:', response);
        }
      },
      error => {
        console.error('Error loading CRM follow up:', error);
      },
    );
  }


  onEditCrmFollowUpConfirm(event): void {

    this.serviceCrmFollowUp.updateCrmFollowUp(event.newData).subscribe(
      data => {
        // Assurez-vous que data contient l'objet modifié complet
        event.confirm.resolve(data); // Mettez à jour la ligne dans la table
        this.sourceCrmFollowUp.update(event.data, data);
      },
      error => {
        console.log('Error updating CRM follow up:', error);
        event.confirm.reject();
      },
    );
  }
  onDeleteCrmFollowUpConfirm(event): void {
    if (window.confirm('Are you sure you want to delete?')) {
      this.serviceCrmFollowUp.deleteCrmFollowUp(event.data).subscribe(data => {
        event.confirm.resolve();
      });
    } else {
      event.confirm.reject();
    }
  }

  onCreateCrmEtapesConfirm(event): void {
    this.serviceCrmEtapes.addCrmEtapes(event.newData).subscribe(
      data => {
        // Mettez à jour la table pour refléter les données du serveur au lieu d'ajouter localement
        this.loadCrmEtapes();  // Rechargez toutes les données depuis le serveur
        event.confirm.resolve();  // Confirmez l'ajout dans la table
      },
      error => {
        console.log('Error adding CRM Etapes:', error);
        event.confirm.reject();
      },
    );
  }



  loadCrmEtapes() {
    this.serviceCrmEtapes.findAllCrmEtapes().subscribe(
      (response: any) => {
        if (Array.isArray(response)) {
          this.sourceCrmEtapes.load(response);
        } else {
          console.error('Expected an array of data:', response);
        }
      },
      error => {
        console.error('Error loading CRM Etapes:', error);
      },
    );
  }


  onEditCrmEtapesConfirm(event): void {

    this.serviceCrmEtapes.updateCrmEtapes(event.newData).subscribe(
      data => {
        // Assurez-vous que data contient l'objet modifié complet
        event.confirm.resolve(data); // Mettez à jour la ligne dans la table
        this.sourceCrmEtapes.update(event.data, data);
      },
      error => {
        console.log('Error updating CRM Etapes:', error);
        event.confirm.reject();
      },
    );
  }
  onDeleteCrmEtapesConfirm(event): void {
    if (window.confirm('Are you sure you want to delete?')) {
      this.serviceCrmEtapes.deleteCrmEtapes(event.data).subscribe(data => {
        event.confirm.resolve();
      });
    } else {
      event.confirm.reject();
    }
  }
}
