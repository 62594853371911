import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LocalDataSource} from 'ng2-smart-table';
import { NbSortDirection, NbSortRequest, NbTreeGridDataSource, NbTreeGridDataSourceBuilder } from '@nebular/theme';
import { CrmTeamTrackingDealsService } from 'app/modules/customer-relationship-management/services/crmTeamTrackingDeals/crm-team-tracking-deals.service';
import { takeWhile } from 'rxjs/operators';
import {ProfitChart} from '../../../../../@core/data/profit-chart';
import {OrderProfitChartSummary, OrdersProfitChartData} from '../../../../../@core/data/orders-profit-chart';

interface TreeNode<T> {
  data: T;
  children?: TreeNode<T>[];
  expanded?: boolean;
}

interface FSEntry {
  'Valeur de Gains Effectués': string; // Sum of 'Recettes attendues' for won deals
  'Valeur de Gains Prévus': string; // Total anticipated revenue
  'Moyenne des Gains Effectués': string; // Average value of won opportunities
  'Nombre de Gains Effectués': string; // Count of won deals
}

interface TeamMemberData {
  Equipe: string;
  Objectif: string;
  'Valeur de Gains Effectués': string;
  'Valeur de Gains Prévus': string;
}
interface FSEntry2 {
  'Representative with the most sales': string;
}

@Component({
  selector: 'ngx-crm-team-insights',
  templateUrl: './crm-team-insights.component.html',
  styleUrls: ['./crm-team-insights.component.scss'],
})
export class CrmTeamInsightsComponent implements OnInit , OnDestroy {
  private alive = true;

  chartPanelSummary: OrderProfitChartSummary[];
  period: string = 'week';
  profitChartData: ProfitChart;

  // @ViewChild('profitChart', { static: true }) profitChart: ProfitChartComponent;


  highestValueRepresentative: string = '';
  errorMessage: string = '';

  source: LocalDataSource = new LocalDataSource();

  defaultColumns = [ 'Valeur de Gains Effectués' , 'Valeur de Gains Prévus', 'Moyenne des Gains Effectués', 'Nombre de Gains Effectués' ];
  allColumns = [...this.defaultColumns ];

  defaultColumns2 = [ 'Representative with the most sales'];
  allColumns2 = [...this.defaultColumns2 ];

  defaultColumns3 = ['Equipe', 'Objectif', 'Valeur de Gains Effectués', 'Valeur de Gains Prévus'];
  allColumns3 = [...this.defaultColumns3];

  dataSource: NbTreeGridDataSource<FSEntry>;
  dataSource2: NbTreeGridDataSource<FSEntry2>;
  dataSource3: NbTreeGridDataSource<TeamMemberData>;


  sortColumn: string;
  sortDirection: NbSortDirection = NbSortDirection.NONE;

  constructor(private dataSourceBuilder: NbTreeGridDataSourceBuilder<FSEntry> ,
              private dataSourceBuilder2: NbTreeGridDataSourceBuilder<FSEntry2> ,
              private dataSourceBuilder3: NbTreeGridDataSourceBuilder<TeamMemberData> ,
              private to: CrmTeamTrackingDealsService,
              private ordersProfitChartService: OrdersProfitChartData) {
    this.ordersProfitChartService.getOrderProfitChartSummary()
      .pipe(takeWhile(() => this.alive))
      .subscribe((summary) => {
        this.chartPanelSummary = summary;
      });

    this.getProfitChartData(this.period);
  }

  setPeriodAndGetChartData(value: string): void {
    if (this.period !== value) {
      this.period = value;
    }

    this.getProfitChartData(value);
  }

  /*changeTab(selectedTab) {
    if (selectedTab.tabTitle === 'Profit') {
      this.profitChart.resizeChart();
    }
  }*/

  getProfitChartData(period: string) {
    this.ordersProfitChartService.getProfitChartData(period)
      .pipe(takeWhile(() => this.alive))
      .subscribe(profitChartData => {
        this.profitChartData = profitChartData;
      });
  }

  ngOnDestroy() {
    this.alive = false;
  }
  ngOnInit() {
    this.to.totalRecette$.subscribe(totalRecette => {
      if (totalRecette !== null) { // Ensure totalRecette is not null
        this.loadDealsData(totalRecette);
      }
    });
    this.loadHighestValueRepresentative();
    this.loadTeamMembersData();
  }

  loadTeamMembersData() {
    this.to.findAllCrmTrackingDeals().subscribe(teamMembers => {
      const teamMemberMap: Record<string, TeamMemberData> = teamMembers.reduce((acc, teamMember) => {
        const name = teamMember.teamMember.representantCommercial;
        if (!acc[name]) {
          acc[name] = {
            Equipe: name,
            Objectif: parseFloat(teamMember.teamMember.objectifGain || '0'), // Set initially, do not sum across entries
            'Valeur de Gains Effectués': 0,
            'Valeur de Gains Prévus': 0,
          };
        }

        // Sum 'Valeur de Gains Effectués' only if probability is 100%
        if (teamMember.stage.probabilite === '100%') {
          acc[name]['Valeur de Gains Effectués'] += parseFloat(teamMember.valeur || '0');
        } else {
          acc[name]['Valeur de Gains Prévus'] += parseFloat(teamMember.recette || '0');
        }

        return acc;
      }, {});

      const data3: TreeNode<TeamMemberData>[] = Object.values(teamMemberMap).map(item => ({
        data: {
          Equipe: item.Equipe,
          Objectif: item.Objectif, // Keep as initially set for each representative
          'Valeur de Gains Effectués': item['Valeur de Gains Effectués'],
          'Valeur de Gains Prévus': item['Valeur de Gains Prévus'],
        },
      }));

      // Optionally add a summary row
      data3.unshift({
        data: {
          Equipe: 'Total',
          Objectif: String(data3.reduce((sum, row) => sum + parseFloat(row.data.Objectif || '0'), 0)),
          'Valeur de Gains Effectués': String(data3.reduce((sum, row) => sum + parseFloat(row.data['Valeur de Gains Effectués'] || '0'), 0)),
          'Valeur de Gains Prévus': String(data3.reduce((sum, row) => sum + parseFloat(row.data['Valeur de Gains Prévus'] || '0'), 0)),
        },
      });

      this.dataSource3 = this.dataSourceBuilder3.create(data3);
    });
  }





  loadHighestValueRepresentative() {
    this.to.getHighestValueRepresentative().subscribe({
      next: (representative) => {
        console.log('Representative:', representative);  // Debug log
        this.highestValueRepresentative = representative;

        // Create the tree node array for the data source with the representative data
        const data2: TreeNode<FSEntry2>[] = [{
          data: {
            'Representative with the most sales': representative,  // Correctly assign the representative
          },
        }];

        // Update the tree grid data source
        this.dataSource2 = this.dataSourceBuilder2.create(data2);
      },
      error: (error) => {
        console.error('Failed to load the highest value representative', error);
        // Handle errors if necessary, e.g., show a user-friendly message or log to a service
      },
    });
  }


  loadDealsData(totalRecette: number) {
    console.log('Received totalRecette in loadDealsData:', totalRecette);
    this.to.findAllDealsWithFullProbability().subscribe(deals => {
      if (!deals.length) {
        return; // Handle case where there are no deals
      }

      const totalRecettes = deals.reduce((acc, curr) => acc + parseFloat(curr.recette), 0);
      const moyenneGains = deals.length > 0 ? (totalRecettes / deals.length) : 0;
      const nombreGains = deals.length;

      // Create a single TreeNode<FSEntry> object
      const data: TreeNode<FSEntry>[] = [{
        data: {
          'Valeur de Gains Effectués': this.formatNumber(totalRecettes), // Display total of 'recette' from all deals
          'Valeur de Gains Prévus': this.formatNumber(totalRecette), // This is the passed totalRecette
          'Moyenne des Gains Effectués': this.formatNumber(moyenneGains),
          'Nombre de Gains Effectués': nombreGains.toString(),
        },
      }];

      this.dataSource = this.dataSourceBuilder.create(data);
    });
  }


  formatNumber(value: number): string {
    return value.toLocaleString(); // Simple locale string conversion for demonstration
  }
  updateSort(sortRequest: NbSortRequest): void {
    this.sortColumn = sortRequest.column;
    this.sortDirection = sortRequest.direction;
  }

  getSortDirection(column: string): NbSortDirection {
    if (this.sortColumn === column) {
      return this.sortDirection;
    }
    return NbSortDirection.NONE;
  }

  getShowOn(index: number) {
    const minWithForMultipleColumns = 400;
    const nextColumnStep = 100;
    return minWithForMultipleColumns + (nextColumnStep * index);
  }
}

@Component({
  selector: 'ngx-fs-icon',
  template: `
    <nb-tree-grid-row-toggle [expanded]="expanded" *ngIf="isDir(); else fileIcon">
    </nb-tree-grid-row-toggle>
    <ng-template #fileIcon>
      <nb-icon icon="file-text-outline"></nb-icon>
    </ng-template>
  `,
})
export class FsIconComponent {
  @Input() kind: string;
  @Input() expanded: boolean;

  isDir(): boolean {
    return this.kind === 'dir';
  }
}
