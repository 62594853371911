import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {CrmEtapes} from '../../models/CrmEtapes';
const httpOptionsPlain = {
  headers: new HttpHeaders({
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }),
  'responseType': 'text as json',
};
@Injectable({
  providedIn: 'root',
})
export class CrmEtapesService {

  httpOptions = {
    headers: new HttpHeaders({

      'Content-Type': 'application/json;  charset=utf-8 ',
    }),
  };
  constructor(private httpClient: HttpClient) { }

  findAllCrmEtapes() {
    return this.httpClient.get(`${environment.crmUrl}CrmEtapes/allCrmEtapes`);
  }

  addCrmEtapes(c: CrmEtapes) {
    return this.httpClient.post<CrmEtapes>(`${environment.crmUrl}CrmEtapes/add`, c, this.httpOptions);
  }

  updateCrmEtapes(crmEtapes: CrmEtapes): Observable<CrmEtapes> {
    return this.httpClient.put<CrmEtapes>(
      `${environment.crmUrl}CrmEtapes/update-CrmEtapes`,
      crmEtapes, this.httpOptions);
  }

  deleteCrmEtapes(crmEtapes: CrmEtapes | string): Observable<string> {
    const id = typeof crmEtapes === 'string' ? crmEtapes : crmEtapes.id;
    const url = `${environment.crmUrl}CrmEtapes/delete/` + id;
    // @ts-ignore
    return this.httpClient.delete<string>(url, httpOptionsPlain);
  }

  getCrmEtapesByID(id: string): Observable<CrmEtapes> {
    return this.httpClient.get<CrmEtapes>(`${environment.crmUrl}CrmEtapes/CrmEtapes-by-id/` + id);
  }
}
