import {Component, OnInit} from '@angular/core';
import {
  NbComponentStatus,
  NbGlobalPhysicalPosition,
  NbGlobalPosition,
  NbToastrConfig,
  NbToastrService,
} from '@nebular/theme';
import {Router} from '@angular/router';
import {ExportService} from '../../../../../shared/exports/export.service';
import {EventService} from '../../../services/events/event.service';
import {Observable} from 'rxjs';
import {Event} from '../../../models/Event';
import GoogleCountries from '../../../../humain-capital-management/services/googlecountries.json';

@Component({
  selector: 'ngx-events-history',
  templateUrl: './events-history.component.html',
  styleUrls: ['./events-history.component.scss'],
})
export class EventsHistoryComponent implements OnInit {

  search: string;
  countries: any [] = [];
  config: NbToastrConfig;
  index = 1;
  destroyByClick = true;
  duration = 2000;
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  preventDuplicates = false;
  // getting customers per page
  pageSize: number = 4;
  // current number page for pagination
  current: number = 1;
  // toast alert notification
  dataClaims: string ;
  events$: Observable<Event[]>;
  dataEvents: Event [] = [];
  selectedSortOrder: string = 'All';
  constructor(private toastrService: NbToastrService,
              private router: Router,
              private exportService: ExportService,
              private ev: EventService) {
  }
  ngOnInit(): void {
    this.getAllEvent();
    this.countries = GoogleCountries;
  }

  onRestoreConfirm(event: Event, id: string) {
    this.ev.cancelArchiveEvent(event, id).subscribe(
      () => {
        this.showToast('success', 'Restored Successfully',
          'Customer restored !!');
        this.router.navigate(['/crm/events']).then(() => {
          window.location.reload();
        });
      });
  }
  exportAsXLSX(): void {
    this.exportService.exportAsExcelFile(this.dataEvents, 'dataEvents');
  }

  getAllEvent() {
    this.ev.findArchivedEvents()
      .subscribe(
        (data: any[]) => {
          this.dataEvents = data;
        },
      );
  }

  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: this.destroyByClick,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
    };
    const titleContent = title ? ` ${title}` : '';

    this.index += 1;
    this.toastrService.show(
      body,
      `${titleContent}`,
      config);
  }

}
