import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Customer} from '../../../../models/Customer';
import {CustomerService} from '../../../../services/customers/customer.service';
import {CrmStages} from '../../../../models/CrmStages';
import {CrmStagesService} from '../../../../services/crmStages/crm-stages.service';
import {CrmTeamObjective} from '../../../../models/CrmTeamObjective';
import {CrmTeamObjectiveService} from '../../../../services/crmTeamObjective/crm-team-objective.service';
import {CrmEtapes} from '../../../../models/CrmEtapes';
import {CrmEtapesService} from '../../../../services/CrmEtapes/crm-etapes.service';
import {CrmTeamTrackingDealsService} from '../../../../services/crmTeamTrackingDeals/crm-team-tracking-deals.service';
import {CrmTrackingDeals} from '../../../../models/CrmTrackingDeals';
import { Router } from '@angular/router';
import {NbComponentStatus, NbGlobalPhysicalPosition, NbGlobalPosition, NbToastrService} from '@nebular/theme';
import {LocalDataSource} from 'ng2-smart-table';
import {Observable} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'ngx-add-crm-team-tracking-deals',
  templateUrl: './add-crm-team-tracking-deals.component.html',
  styleUrls: ['./add-crm-team-tracking-deals.component.scss'],
})
export class AddCrmTeamTrackingDealsComponent implements OnInit {

  crmTrackingDeals: CrmTrackingDeals = new CrmTrackingDeals();
  crmGroup: FormGroup = new FormGroup({});
  datesForm: FormGroup = new FormGroup({});
  teamGroup: FormGroup = new FormGroup({});
  prefixPhoneSupp: String = '';
  source: LocalDataSource = new LocalDataSource();
  selectedCustomer: string = '';
  selectedTeamObjective: string = '';
  list: Customer[] = [];
  listP: Customer[] = [];
  search: string = '';
  current: number = 1;
  total: string | number;
  pageSize: number = 4;
  listStage: CrmStages[] = [];
  listTeamMember: CrmTeamObjective[] = [];
  listNextStep: CrmEtapes[] = [];
  listCrmTeamTrackingDeals: CrmTrackingDeals[] = [];
  totalValeur: string;
  totalRecette: string;
  index = 1;
  destroyByClick = true;
  duration = 2000;
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  preventDuplicates = false;
  selectedFile: File = null;
  fb = '';

  downloadURL: Observable<string>;
  selectedStage: CrmStages;
  stageToStore: any;
  selectedNomEntreprise: Customer;
  nomEntrepriseToStore: any;
  selectedTeamMember: CrmTeamObjective;
  teamMemberToStore: any;
  selectedNextStep: CrmEtapes;
  nextStepToStore: any;
  selectedProspect: Customer;
  prospectToStore: any;
  linearMode = true;
  loading: boolean;
  user: any;

  constructor(private changeDetectorRefs: ChangeDetectorRef,
              private es: CustomerService,
              private st: CrmStagesService,
              private to: CrmTeamObjectiveService,
              private et: CrmEtapesService,
              private ct: CrmTeamTrackingDealsService,
              private toastrService: NbToastrService,
              private router: Router) {
    this.crmGroup = new FormGroup({
      nomEntreprise: new FormControl(''),
      prospect: new FormControl(''),
      emailContact: new FormControl(''),
      valeur: new FormControl(''),
      stage: new FormControl(''),

      teamGroup: new FormGroup({
        recette: new FormControl(''),
        recetteR: new FormControl(''),
        teamMember: new FormControl(''),
        etapeSuivante: new FormControl(''),

      }),

      datesForm : new FormGroup({
        dateP: new FormControl(''),
        dateC: new FormControl(''),
        dateDEchange: new FormControl(''),
      }),

    });
  }

  get nomEntreprise() {
    return this.crmGroup.get('nomEntreprise');
  }

  get prospect() {
    return this.crmGroup.get('prospect');
  }

  get emailContact() {
    return this.crmGroup.get('emailContact');
  }

  get valeur() {
    return this.crmGroup.get('valeur');
  }

  get stage() {
    return this.crmGroup.get('stage');
  }

// Getters for teamGroup
  get recette() {
    return this.crmGroup.get('teamGroup').get('recette');
  }


  get recetteR() {
    return this.teamGroup.get('recetteR');
  }

  get dateP() {
    return this.datesForm.get('dateP');
  }

  get dateC() {
    return this.datesForm.get('dateC');
  }

  get teamMember() {
    return this.teamGroup.get('teamMember');
  }

  get dateDEchange() {
    return this.datesForm.get('dateDEchange');
  }

  get etapeSuivante() {
    return this.teamGroup.get('etapeSuivante');
  }

  onCrmTrackingDealsFormSubmit() {
    this.crmGroup.markAsDirty();
  }

  onDatesFormSubmit() {
    this.datesForm.markAsDirty();
  }

  onTeamFormSubmit() {
    this.teamGroup.markAsDirty();
  }

  onMenuItemSelectedNomEntreprise(item: Customer) {
    this.selectedNomEntreprise = item;
  }

  onMenuItemSelectedNextStep(item: CrmEtapes) {
    this.selectedNextStep = item;
  }

  onMenuItemSelectedStage(item: CrmStages) {
    this.selectedStage = item;
  }

  onMenuItemSelectedTeamMember(item: CrmTeamObjective) {
    this.selectedTeamMember = item;
  }

  onMenuItemSelectedProspect(item: Customer) {
    this.selectedProspect = item;
  }

  ngOnInit(): void {

    this.es.findProspects().subscribe(
      (customers: Customer[]) => {
        this.listP = customers;
      }, (err) => {
        console.log(err);
      },
    );

     this.es.getAllClients().subscribe(
      (customers: Customer[]) => {
        this.list = customers;
      }, (err) => {
        console.log(err);
      },
    );

    this.st.findAllCrmStages().subscribe(
      (crmStages: CrmStages[]) => {
        this.listStage = crmStages;
      }, (err) => {
        console.log(err);
      },
    );

    this.to.findAllCrmTeamObjective().subscribe(
      (crmTeamObjectives: CrmTeamObjective[]) => {
        this.listTeamMember = crmTeamObjectives;
      }, (err) => {
        console.log(err);
      },
    );

    this.et.findAllCrmEtapes().subscribe(
      (crmEtapes: CrmEtapes[]) => {
        this.listNextStep = crmEtapes;
      }, (err) => {
        console.log(err);
      },
    );

    this.ct.findAllCrmTrackingDeals().subscribe(
      (response) => {
        if (Array.isArray(response)) {
        } else {
          console.error('Expected an array of data:', response);
        }
      },
      error => {
        console.error('Error loading CRM TrackingDeals:', error);
      },
    );
  }

  addCrmTeamTrackingDealsFunction() {
    const valeur = parseFloat(this.crmGroup.get('valeur').value);
    const probabilite = parseFloat(this.selectedStage.probabilite) / 100; // Adaptez cette ligne si le format de probabilite est différent

    // Calcul de la recette
    const recette = (valeur * probabilite).toString(); // Convertit en string avec 2 décimales

    const trackingDeal: CrmTrackingDeals = {
      nomEntreprise: this.selectedNomEntreprise,
      prospect: this.selectedProspect,
      stage: this.selectedStage,
      teamMember: this.selectedTeamMember,
      etapeSuivante: this.selectedNextStep,
      emailContact: this.crmGroup.get('emailContact').value,
      valeur: this.crmGroup.get('valeur').value,
      dateC: this.crmGroup.get('datesForm').get('dateC').value,
      dateP: this.crmGroup.get('datesForm').get('dateP').value,
      dateDEchange: this.crmGroup.get('datesForm').get('dateDEchange').value,
      recetteR: this.crmGroup.get('teamGroup').get('recetteR').value,
      recette: recette,
    };

    console.log('Sending:', trackingDeal);
    this.ct.addCrmTrackingDeals(trackingDeal).subscribe(result => {
        this.showToast('success', 'Add ! ', 'Project Added !!');
        this.router.navigateByUrl('/crm/crm-team/tracking-deals').then(() => {
          this.router.navigate(['/crm/crm-team/tracking-deals']);
        });
      },
      (err) => {
        this.showToast('danger', 'Add ! ', err);
      },
    );
  }

  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: this.destroyByClick,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
    };
    const titleContent = title ? ` ${title}` : '';

    this.index += 1;
    this.toastrService.show(
      body,
      `${titleContent}`,
      config);
  }

  onError($event: boolean) {
  }
}
