<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet">
<div class="content">
  <div class="container">

     <nb-card-header>
      <div class="row">
        <div class="col-md-3">
          <h3>Contracts</h3>
        </div>
        <div class="col-md-5">
          <div class="row">
            <input [(ngModel)]="search"
                   nbInput class="w-100" fieldSize="large"
                   nbTooltip="Tap here to search your contract"
                   nbTooltipStatus="primary" placeholder="Search contract"
                   type="text">
    
          </div>
        </div>
        <div class="col-md-4 pt-1">
          <div>
            <a [routerLink]="['/crm/grid-contracts']"
               nbButton nbTooltip="Visualize data on dimensions grid 2x2"
               nbTooltipStatus="primary" status="primary">
              <nb-icon icon="grid-outline"></nb-icon>
            </a>&nbsp;
            <a [routerLink]="['/crm/contracts']" class="mx-1" nbButton
               nbTooltip="Visualize data on keypad " 
               nbTooltipStatus="primary" status="warning">
              <nb-icon icon="keypad-outline"></nb-icon>
            </a>
            <a [routerLink]="['/crm/list-contracts']" class="mx-1" nbButton
               nbTooltip="Visualize data on list " nbTooltipStatus="primary" status="basic">
              <nb-icon icon="list-outline"></nb-icon>
            </a>
           <button (click)="exportAsXLSX()"
                    align="right" class="mx-1"
                    nbButton nbTooltip="Click here to export data to Excel" nbTooltipStatus="primary"
                    status="success">
              <i class="fas fa-file-excel text-white"></i>
            </button>
     
           

          </div>
        </div>
      </div>

     
    </nb-card-header>
    <div *ngIf="!dataContracts || dataContracts.length === 0" align="center">
      <nb-alert class="w-100 mt-3" outline="danger">No Data Found.</nb-alert>
    </div>
    <div *ngIf="(dataContracts | filter : search).length === 0" [hidden]="dataContracts.length === 0"
         class="">
      <nb-alert class="col-lg-12 mt-3" fullWidth outline="danger">The item searched is not found. Please make sure
        while typing your data
      </nb-alert>
    </div>


    <div class="page-content container note-has-grid mt-4">

      <div class="row">
       <div class="col-md-3">
        
        <nb-form-field>
          <nb-icon class="mt-3" icon="funnel-outline" nbPrefix></nb-icon>
                <nb-select (selectedChange)="getContractsByType($event)"
                       class="mx-2 mt-3 w-75" nbTooltip="Click here to filter by status"
                       nbTooltipStatus="primary"
                       placeholder="Status"
                      >
              <nb-option (click)="getAllContracts()" value="All">All</nb-option>

              <nb-option value="VEFR">VEFR</nb-option>
              <nb-option value="VEFA">VEFA</nb-option>
              <nb-option value="CPI">CPI</nb-option>
            </nb-select>
        </nb-form-field>
     <nb-form-field>
          <nb-icon class="mt-3" icon="funnel-outline" nbPrefix></nb-icon>
          <nb-select (selectedChange)="getContractsByAddress($event,'propertyCountry')"
                    
                     class="mt-3 mx-2 w-75" placeholder="Country"
                  nbTooltip="Click here to filter by country"
                     nbTooltipStatus="primary">
            <nb-option (click)="getAllContracts()" value="All">All</nb-option>
            <nb-option *ngFor="let country of countries"
                       value="{{country.country_name}}">{{country.country_name}}</nb-option>

          </nb-select>
        </nb-form-field>
          <nb-form-field>
          <nb-icon class="mt-3" icon="funnel-outline" nbPrefix></nb-icon>
          <nb-select (selectedChange)="filterByEmployee($event)" 
                     class="mt-3 mx-2 w-75" placeholder="Employee"
          nbTooltip="Click here to filter contract by employee" nbTooltipStatus="primary">
            <nb-option (click)="getAllContracts()" value="All">All</nb-option>
            <nb-option
                *ngFor="let optionEmployee of list"
                value="{{optionEmployee.id}}">{{optionEmployee.employeeName}}</nb-option>


          </nb-select>
        </nb-form-field>  
         <nb-form-field>
          <nb-icon class="mt-3" icon="funnel-outline" nbPrefix></nb-icon>
          <nb-select (selectedChange)="filterByProperty($event)" 
                     class="mt-3 mx-2 w-75" placeholder="Property"
          nbTooltip="Click here to filter contract by property" nbTooltipStatus="primary">
            <nb-option (click)="getAllContracts()" value="All">All</nb-option>
             <nb-option
                *ngFor="let optionProperty of properties"
                value="{{optionProperty.propertyId}}">{{optionProperty.propertyName}}</nb-option>
          </nb-select>
        </nb-form-field>
           <nb-form-field>
          <nb-icon class="mt-3" icon="funnel-outline" nbPrefix></nb-icon>
          <nb-select (selectedChange)="getContractsByCustomer($event)" 
                     class="mt-3 mx-2 w-75" placeholder="Customer"
          nbTooltip="Click here to filter contract by customer" nbTooltipStatus="primary">
            <nb-option (click)="getAllContracts()" value="All">All</nb-option>
             <nb-option
                *ngFor="let customer of dataCustomers"
                value="{{customer.id}}">{{customer.name}}</nb-option>
          </nb-select>
        </nb-form-field>
       <nb-form-field>
          <nb-icon class="mt-3" icon="funnel-outline" nbPrefix></nb-icon>
          <nb-select (selectedChange)="filterByVisit($event)" 
                     class="mt-3 mx-2 w-75" placeholder="Visit"
          nbTooltip="Click here to filter contract by visit" nbTooltipStatus="primary">
            <nb-option (click)="getAllContracts()" value="All">All</nb-option>
             <nb-option 
                *ngFor="let visit of dataVisits"
                value="{{visit.id}}">{{visit.title}}</nb-option>
          </nb-select>
        </nb-form-field>
         <div class="col-lg-10 mt-3">
        <label>Contracts Per Page :  </label>
        <input [(ngModel)]="pageSize" [disabled]="dataContracts.length === 0"
               placeholer="Number to get contracts per page"
                 nbTooltip="Tap a number to get contracts per page" nbTooltipStatus="primary"
               class="w-100" min="9" nbInput type="number">
      </div>
      </div>
        <div class="col-md-9 mt-3">


          <table class="table mb-0 " id="htmlData">
            <thead class="bg-light">
            <tr>
              <th>Contract Name</th>
              <th>Contract Type</th>
              <th>Designation</th>
              <th>Price Effective</th>
              <th>Date Contract</th>
              <th>state</th>
              <th>Actions</th>

            </tr>
            </thead>
            <tbody
              *ngFor="let contract of dataContracts | filter : search | paginate : {itemsPerPage:10, currentPage: current,totalItems: total}"
              [hidden]="contract.archive === 'True'">
            <tr class="col-md-9 col-sm-10">
              <td>
                <p class=" mb-1">{{contract.contractName}}</p>
              </td>
              <td><p class=" mb-0">{{contract.contractType}}</p>

              </td>
              <td><p class="fw-normal mb-1">{{contract.designation}}</p></td>
              <td><p class="fw-normal mb-1">{{contract.priceEffective}}</p></td>
              <td><p class="fw-normal mb-1">{{contract.dateContract | date : 'YYYY MMMM dd'}}</p></td>
              <td><p class="fw-normal mb-1">{{contract.state}}</p></td>
            

              <td>
                <button [matMenuTriggerFor]="menu" aria-expanded="false" aria-haspopup="true"
                        aria-label="Example icon-button with a menu" mat-icon-button
                        nbButton nbTooltip="Click here to see advanced menu"
                        nbTooltipStatus="primary"
                        shape="round"
                        size="small"
                        status="basic">
                  <nb-icon icon="more-horizontal-outline"></nb-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item>
                    <a (click)="onArchiveConfirm(contract,contract.id)" fullWidth nbButton status="basic">
                      <nb-icon icon="archive-outline"></nb-icon>
                      Archive
                    </a>
                  </button>
                </mat-menu>
              </td>
            </tr>
            </tbody>
          </table>


        </div>
      </div>

<div class="d-flex justify-content-center">
      <nb-card class="card ml-auto mr-auto text-center" style="height: 30px; width: auto ;">
        <pagination-controls (pageChange)="current = $event" align="right" nextLabel=""
                             previousLabel=""></pagination-controls>

      </nb-card>
    </div>

    </div>
  </div>
</div>