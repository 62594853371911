<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet">

<div class="content">
  <div class="container">
      <nb-card-header>
      <div class="row">
        <div class="col-md-3">
          <h3>Contracts</h3>
        </div>
        <div class="col-md-6">
          <div class="row">
            <input [(ngModel)]="search" fieldSize="large"
                   nbInput class="w-100"
                   nbTooltip="Tap here to search your contract" nbTooltipStatus="primary" 
                   placeholder="search contract"
                   type="text">
    
          </div>
        </div>
        <div class="col-md-3">
          <div>
            <a [routerLink]="['/crm/grid-contracts']"
               nbButton nbTooltip="Visualize data on dimensions grid 2x2" nbTooltipStatus="primary" status="primary">
              <nb-icon icon="grid-outline"></nb-icon>
            </a>&nbsp;
            <a [routerLink]="['/crm/contracts']" class="mx-1" nbButton
               nbTooltip="Visualize data on keypad " nbTooltipStatus="primary" status="warning">
              <nb-icon icon="keypad-outline"></nb-icon>
            </a>
            <a [routerLink]="['/crm/list-contracts']" class="mx-1" nbButton
               nbTooltip="Visualize data on list " nbTooltipStatus="primary" status="basic">
              <nb-icon icon="list-outline"></nb-icon>
            </a>
           <button (click)="exportAsXLSX()"
                    align="right" class="mx-1"
                    nbButton nbTooltip="Click here to export data to Excel" nbTooltipStatus="primary"
                    status="success">
              <i class="fas fa-file-excel text-white"></i>
            </button>
     
         
          </div>
        </div>
      </div>

     
    </nb-card-header>
    <div *ngIf="!dataContracts || dataContracts.length === 0" align="center">
      <nb-alert class="w-100 mt-3" outline="danger">No Data Found.</nb-alert>
    </div>
    <div *ngIf="(dataContracts | filter : search).length === 0" [hidden]="dataContracts.length === 0"
         class="">
      <nb-alert class="col-lg-12 mt-3" fullWidth outline="danger">The item searched is not found. Please make sure
        while typing your data
      </nb-alert>
    </div>

<div class="page-content container note-has-grid mt-4">
   
    <div class="row">
     <div class="col-md-3">
        
        <nb-form-field>
          <nb-icon class="mt-3" icon="funnel-outline" nbPrefix></nb-icon>
                <nb-select (selectedChange)="getContractsByType($event)"
                       class="mx-2 mt-3 w-75" nbTooltip="Click here to filter by status"
                       nbTooltipStatus="primary"
                       placeholder="Contract by Status"
                      >
              <nb-option (click)="getAllContracts()" value="All">All</nb-option>

              <nb-option value="VEFR">VEFR</nb-option>
              <nb-option value="VEFA">VEFA</nb-option>
              <nb-option value="CPI">CPI</nb-option>
            </nb-select>
        </nb-form-field>
     <nb-form-field>
          <nb-icon class="mt-3" icon="funnel-outline" nbPrefix></nb-icon>
          <nb-select (selectedChange)="getContractsByAddress($event,'propertyCountry')"
                    
                     class="mt-3 mx-2 w-75" placeholder="Contract by country"
                  nbTooltip="Click here to filter by country"
                     nbTooltipStatus="primary">
            <nb-option (click)="getAllContracts()" value="All">All</nb-option>
            <nb-option *ngFor="let country of countries"
                       value="{{country.country_name}}">{{country.country_name}}</nb-option>

          </nb-select>
        </nb-form-field>
          <nb-form-field>
          <nb-icon class="mt-3" icon="funnel-outline" nbPrefix></nb-icon>
          <nb-select (selectedChange)="filterByEmployee($event)" 
                     class="mt-3 mx-2 w-75" placeholder="Contract by employee"
          nbTooltip="Click here to filter contract by employee" nbTooltipStatus="primary">
            <nb-option (click)="getAllContracts()" value="All">All</nb-option>
            <nb-option
                *ngFor="let optionEmployee of list"
                value="{{optionEmployee.id}}">{{optionEmployee.employeeName}}</nb-option>


          </nb-select>
        </nb-form-field>  
         <nb-form-field>
          <nb-icon class="mt-3" icon="funnel-outline" nbPrefix></nb-icon>
          <nb-select (selectedChange)="filterByProperty($event)" 
                     class="mt-3 mx-2 w-75" placeholder="Contract by property"
          nbTooltip="Click here to filter contract by property" nbTooltipStatus="primary">
            <nb-option (click)="getAllContracts()" value="All">All</nb-option>
             <nb-option
                *ngFor="let optionProperty of properties"
                value="{{optionProperty.propertyId}}">{{optionProperty.propertyName}}</nb-option>
          </nb-select>
        </nb-form-field>
           <nb-form-field>
          <nb-icon class="mt-3" icon="funnel-outline" nbPrefix></nb-icon>
          <nb-select (selectedChange)="getContractsByCustomer($event)" 
                     class="mt-3 mx-2 w-75" placeholder="Contract by customer"
          nbTooltip="Click here to filter contract by customer" nbTooltipStatus="primary">
            <nb-option (click)="getAllContracts()" value="All">All</nb-option>
             <nb-option
                *ngFor="let customer of dataCustomers"
                value="{{customer.id}}">{{customer.name}}</nb-option>
          </nb-select>
        </nb-form-field>
       <nb-form-field>
          <nb-icon class="mt-3" icon="funnel-outline" nbPrefix></nb-icon>
          <nb-select (selectedChange)="filterByVisit($event)" 
                     class="mt-3 mx-2 w-75" placeholder="Contract by visit"
          nbTooltip="Click here to filter contract by visit" nbTooltipStatus="primary">
            <nb-option (click)="getAllContracts()" value="All">All</nb-option>
             <nb-option 
                *ngFor="let visit of dataVisits"
                value="{{visit.id}}">{{visit.title}}</nb-option>
          </nb-select>
        </nb-form-field>
         <div class="col-lg-10 mt-3">
        <label>Contracts Per Page :  </label>
        <input [(ngModel)]="pageSize" [disabled]="dataContracts.length === 0"
               placeholer="Number to get contracts per page"
                 nbTooltip="Tap a number to get contracts per page" nbTooltipStatus="primary"
               class="w-100" min="4" nbInput type="number">
      </div>
      </div>
        <div class="col-md-9 mt-3">
            <div class="tab-content">
                <div id="note-full-container" class="note-has-grid row">
                    <div class="col-md-6 single-note-item all-category" 
                         *ngFor="let contract of dataContracts |filter : search | paginate : {itemsPerPage:pageSize, currentPage: current,totalItems: total}">
                        <nb-card class=" card-body">
                            <span class="side-stick"></span>
                            <div class="row">
                                <div class="col-md-9">
                                  <div class="row">
                                    <div class="col-md-2 mx-1">
                                     <nb-user onlyPicture [picture]="contract?.deal?.visit?.customer?.image" size="medium" ></nb-user>
                                    </div>
                                    <div class="col-md-8 mx-2">
                                      <p>{{contract?.deal?.visit?.customer?.name}} 
                                        <br> <small> <b>{{contract?.deal?.visit?.customer?.workEmail}}</b></small> 
                                      </p>
                                    </div>  
                                  </div>
                                    <p class="note-date ">
                                      <nb-icon class="text-primary"  nbTooltip="Contract date" icon="calendar-outline"></nb-icon> {{contract.dateContract | date : 'EEEE  MMMM YYYY'}}
                                    </p>   
                                  <p class="note-date">
                                      <i nbTooltip="Duration" class="fa fa-clock text-primary"></i> {{contract.duration}}
                                    </p>
                                   <p class="note-date ">
                                      <nb-icon class="text-primary"  nbTooltip="contract type" icon="file-outline"></nb-icon> {{contract.contractType}}
                                    </p>
                                    <p class="note-date">
                                      <nb-icon class="text-primary"  nbTooltip="property" icon="home-outline"></nb-icon> {{contract?.deal?.visit?.property?.propertyName}}
                                    </p>  
                                   <p class="note-date">
                                      <nb-icon class="text-primary" nbTooltip="employee"   icon="person-outline"></nb-icon> {{contract?.deal?.visit?.employee?.employeeName}}
                                    </p>
                                </div>
                                <div class="col-md-3">
                                   <button [matMenuTriggerFor]="menu"  class="file-close"aria-expanded="false" aria-haspopup="true"
                            aria-label="Example icon-button with a menu" mat-icon-button
                            nbButton nbTooltip="Click here to see advanced menu"
                            nbTooltipStatus="primary"
                            shape="round"
                            size="small"
                            status="basic">
                      <nb-icon icon="more-horizontal-outline"></nb-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                     
               <button mat-menu-item >
                        <a  fullWidth nbButton status="basic" (click)="onArchiveConfirm(contract,contract.id)">
                          <nb-icon icon="archive-outline"></nb-icon>Archive
                        </a>
                      </button>
                    </mat-menu>
                                </div>
                            </div>

                            
                            
                        </nb-card>
                    </div>
               

                </div>
            </div>
           
                        
        </div>
    </div>

<div class="d-flex justify-content-center">
      <nb-card class="card ml-auto mr-auto text-center" style="height: 30px; width: auto ;">
        <pagination-controls (pageChange)="current = $event" align="right" nextLabel=""
                             previousLabel=""></pagination-controls>

      </nb-card>
    </div>

</div>
</div>
</div>