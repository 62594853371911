<div class="row">
  <div class="col-md-3">
    <h2>Organigram</h2>
  </div>
  <div class="col-md-8"></div>
  <div class="col-md-1">
    <button (click)="openPDF()" nbTooltip="Export this organigram to pdf file" nbTooltipStatus="primary"  nbButton status="danger"><i class="fas fa-file-pdf text-white"></i>  </button>

  </div>
<div class="ml-auto mr-auto" align="center">

<div class="pg-orgchart">
  <div class="org-chart" id="htmlData">
    <ul>
      <li>
        <div class="user bg-primary" style="border: 1px solid; border-color: blue;">
          <div class="row">
            <div class="col-md-12">
              <nb-icon class="text-center text-white" icon="home-outline"></nb-icon>

            </div>
            <div class="col-md-12">
              <p class="text-center">CEO</p>
            </div>
          </div>
          <small class="text-white">General Director</small>
        </div>
        <ul>

          <li class="ml-auto mr-auto" *ngFor="let department of list" [hidden]="department.isArchived ==='Yes' ">
            <div class="user row bg-primary" style="border: 1px solid; border-color: blue;">
              <div class="row">
                <div class="col-md-12">
                  <nb-icon class="text-center text-white" icon="home-outline"></nb-icon>

                </div>
                <div class="col-md-12">
                  <p class="text-center">{{department?.departmentName}}</p>
                </div>
              </div>
                   </div>


            <div class="row mt-2">
             <div class="col-md-12 w-100" >
                       <li  class="list-unstyled mt-3" *ngFor="let sub of subList" [hidden]="department?.departmentName !== sub?.department?.departmentName" >

                                  <div class="row bg-primary" style="border-radius: 15px;">

                                      <p class="pt-2 mx-4">{{sub.subDepartmentName}}</p>
                                  </div>
                      </li>
             </div>
              </div>



          </li>

        </ul>

      </li>
    </ul>
  </div>
</div>
</div>
</div>
