import {Component, ViewEncapsulation} from '@angular/core';
import {ChatbotService} from '../../services/chatbot/chatbot.service';
import {AuthService} from '../../../auth/service/auth.service';
import {Users} from '../../../auth/model/Users';
interface Message {
  text: string;
  sender: 'user' | 'bot';
  time: string;
  userPhoto?: string; // Optional user photo for the message
}
@Component({
  selector: 'ngx-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom // Use Shadow DOM to isolate styles

})
export class ChatbotComponent {
  userMessage: string = '';
  botResponse: string = '';
  messages: Message[] = [];
  currentUser: Users;

  constructor(private chatbotService: ChatbotService, private us: AuthService) { }
  ngOnInit(): void {
    this.us.getCurrentUser().subscribe(user => {
      this.currentUser = user;
      console.log('Current User Loaded:', this.currentUser?.userProfileImage);
    });
  }
  sendMessage(): void {
    if (this.userMessage.trim() === '') {
      return;
    }

    const date = new Date();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const str_time = hour + ":" + (minute < 10 ? '0' : '') + minute;

    const userMessage: Message = {
      text: this.userMessage,
      sender: 'user',
      time: str_time,
      userPhoto: this.currentUser?.userProfileImage
    };

    this.messages.push(userMessage);
    this.userMessage = '';

    this.chatbotService.getResponse(userMessage.text).subscribe(response => {
      const botMessage: Message = {
        text: response.answer,
        sender: 'bot',
        time: str_time
      };
      this.messages.push(botMessage);
    });
  }
}
