import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {CrmTeamTrackingDealsService} from '../../../../services/crmTeamTrackingDeals/crm-team-tracking-deals.service';
import {CrmTrackingDeals} from '../../../../models/CrmTrackingDeals';
import { Router } from '@angular/router';
import {
  NbComponentStatus,
  NbGlobalPhysicalPosition,
  NbGlobalPosition,
  NbToastrConfig,
  NbToastrService} from '@nebular/theme';
import {ExportService} from '../../../../../../shared/exports/export.service';
@Component({
  selector: 'ngx-history-crm-team-tracking-deals',
  templateUrl: './history-crm-team-tracking-deals.component.html',
  styleUrls: ['./history-crm-team-tracking-deals.component.scss'],
})
export class HistoryCrmTeamTrackingDealsComponent implements OnInit {
  search: string = '';
  current: number = 1;
  total: string | number;
  pageSize: number = 4;
  listCrmTeamTrackingDeals: CrmTrackingDeals[] = [];
  // toaster config begin
  config: NbToastrConfig;
  index = 1;
  destroyByClick = true;
  duration = 2000;
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  preventDuplicates = false;
  status: NbComponentStatus = 'primary';

  constructor(private changeDetectorRefs: ChangeDetectorRef,
              private ct: CrmTeamTrackingDealsService,
              private exportService: ExportService,
              private toastrService: NbToastrService,
              private router: Router) {
    // const data = this.service.getData();
    // this.source.load(data);
    // this.loadCrmTrackingDeals();
    this.getAllCrmTrackingDeals();
  }
  ngOnInit(): void {
    this.getAllCrmTrackingDeals();
  }
  getAllCrmTrackingDeals() {
    this.ct.findAllCrmTrackingDeals().subscribe(
      (data: CrmTrackingDeals[]) => {
        this.listCrmTeamTrackingDeals = data.filter(
          (c => c.archive === 'True'),
        );
      },
    );
  }
  exportAsXLSX(): void {
    this.exportService.exportAsExcelFile(this.listCrmTeamTrackingDeals, 'dataCrmTrackingDeals');
  }
  onRestoreConfirm(crmTrackingDeals: CrmTrackingDeals, id: string) {
    this.ct.cancelArchiveCrmTrackingDeals(crmTrackingDeals, id).subscribe(
      () => {
        this.showToast('success', 'Restored Successfully',
          'Crm Tracking Deal restored !!');
        this.router.navigate(['/crm/crm-team/tracking-deals']).then(() => {
          window.location.reload();
        });
      });
  }

  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: this.destroyByClick,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
    };
    const titleContent = title ? ` ${title}` : '';

    this.index += 1;
    this.toastrService.show(
      body,
      `${titleContent}`,
      config);
  }
}
