<nb-card xmlns="http://www.w3.org/1999/html">
  <nb-card-header>
    CRM Commercial Study
  </nb-card-header>

  <nb-card-body>
    <ng2-smart-table [settings]="settings" [source]="source"
                     (deleteConfirm)="onDeleteConfirm($event)"
                     (createConfirm)="onCreateConfirm($event)"
                      (editConfirm)="onEditConfirm($event)">
                          >
    </ng2-smart-table>
  </nb-card-body>
</nb-card>


<nb-card class="col-md-12 col-lg-12 col-xxxl-12">
  <nb-card-header>
    Use this checklist to help you get started
  </nb-card-header>
  <nb-card-body>
    <nb-stepper orientation="horizontal">
      <nb-step [label]="labelOne">
        <ng-template #labelOne>First step</ng-template>
        <h3 style="color: #4b88cb;">Step 1: Define your steps</h3>
        <h5>- Settings and instructions -</h5>
        <p class="lorem">
          Check each stage of your sales process in the “Stage” column.
          <br>
          Check the probability of closing at each stage in the “Probability” column.
        </p>
        <button nbButton disabled nbStepperNext>prev</button>
        <button nbButton nbStepperNext>next</button>
      </nb-step>
      <nb-step [label]="labelTwo">
        <ng-template #labelTwo>Second step</ng-template>
        <h3 style="color: #4b88cb;">Step 2: Set goals</h3>
        <h5>- Settings and instructions -</h5>
        <p class="lorem">
          Add/remove members of your team in the "Sales Representative" column.
          <br>
          Set your goals for each member of your team.
        </p>
        <button nbButton nbStepperPrevious>prev</button>
        <button nbButton nbStepperNext>next</button>
      </nb-step>
      <nb-step label="Third step">
        <h3 style="color: #4b88cb;">Step 3: Fill your funnel</h3>
        <h5>- Sales Funnel -</h5>
        <p class="lorem">
          Add your current prospects to the Sales Funnel sheet and assign them an internship.
          <br>
          You can also add other data: deal size, expected closing date, and sales representative working on the deal.
          <br>
          Feel free to add/remove columns from the “Sales Funnel” sheet.
        </p>
        <button nbButton nbStepperPrevious>prev</button>
        <button nbButton nbStepperNext>next</button>
      </nb-step>
      <nb-step [label]="labelFour">
        <ng-template #labelFour>Fourth step</ng-template>
        <h3 style="color: #4b88cb;">Step 4: Check your stats</h3>
        <h5>- Outlook -</h5>
        <p class="lorem">
          Track your progress in the Insights Dashboard.
          <br>
          The outlook is automatically calculated for you based on your other sheets.          <br>
          <br>
          Click on the red corners of the tables/graphs to see where the calculations come from.
        </p>
        <button nbButton nbStepperPrevious>prev</button>
        <button nbButton disabled nbStepperNext>next</button>
      </nb-step>
    </nb-stepper>
  </nb-card-body>
</nb-card>

