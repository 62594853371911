import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-list-quotations',
  templateUrl: './list-quotations.component.html',
  styleUrls: ['./list-quotations.component.scss'],
})
export class ListQuotationsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
