import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {Employee} from '../../models/Employee';
import {EmployeeData} from '../../models/EmployeeData';


@Injectable({
  providedIn: 'root',
})
export class EmployeeService {

  employeeUrl = environment.employees_hcm;
  urlmail = environment.mailing;
  urlauthRole = environment.authRoles;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };


  constructor(private httpclient: HttpClient) {
  }

  addEmployee(employee: Employee) {
    return this.httpclient.post<Employee>(`${this.employeeUrl}employee/create`, employee, this.httpOptions);
  }

  findAll(): Observable<[Employee]> {
    return this.httpclient.get<[Employee]>(`${this.employeeUrl}employee/all`);
  }

  findEmployeesWithNameAndImage(): Observable<[Employee]> {
    return this.httpclient.get<[Employee]>(`${this.employeeUrl}employee/employees_select`);
  }

  findAllEmps(): Observable<[EmployeeData]> {
    return this.httpclient.get<[EmployeeData]>(`${this.employeeUrl}employee/all_Emps`);
  }

  findAllEmployeesDesc(): Observable<[Employee]> {
    return this.httpclient.get<[Employee]>(`${this.employeeUrl}employee/all/descending`);
  }

  findAllEmployeesAsc(): Observable<[Employee]> {
    return this.httpclient.get<[Employee]>(`${this.employeeUrl}employee/all/ascending`);
  }

  findAllEmployeesHomme(): Observable<[Employee]> {
    return this.httpclient.get<[Employee]>(`${this.employeeUrl}employee/all/homme`);
  }

  findAllEmployeesFemme(): Observable<[Employee]> {
    return this.httpclient.get<[Employee]>(`${this.employeeUrl}employee/all/femme`);
  }

  deleteEmployeeById(employee: Employee | string): Observable<string> {
    const id = typeof employee === 'string' ? employee : employee.id;
    const path = `${this.employeeUrl}employee/delete/` + id;
    // @ts-ignore
    return this.httpclient.delete<string>(path, this.httpOptions);
  }

  updateEmployee(id: string, employee: Employee): Observable<Employee> {
    const path = `${this.employeeUrl}employee/update/` + id;
    // @ts-ignore
    return this.httpclient.put<Employee>(path, employee, this.httpOptions);
  }

  updateImageEmployee(id: string, file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.httpclient.post<Employee>(
      `${this.employeeUrl}employee/upload/${id}`, formData);
  }

  editProfile(idUser: string, employee: Employee): Observable<Employee> {
    return this.httpclient.put<Employee>(
      `${this.employeeUrl}employee/updateProfile/${idUser}`,
      employee,
      this.httpOptions);
  }

  getImageEmployee(id: string): Observable<HttpResponse<Blob>> {
    return this.httpclient.get(`${this.employeeUrl}employee/download/${id}`, {
      ...this.httpOptions,
      responseType: 'blob',
      observe: 'response',
    });
  }

  getEmployee(id: string): Observable<Employee> {
    return this.httpclient.get<Employee>(`${this.employeeUrl}employee/findbyid/${id}`, this.httpOptions);
  }

  getemployeebyuser(iduser: string): Observable<Employee> {
    return this.httpclient.get<Employee>(`${this.employeeUrl}employee/findEmployeeByUser/${iduser}`, this.httpOptions);
  }

  updateIsArchived(employee: Employee, id: string): Observable<Employee> {
    const path = `${this.employeeUrl}employee/update-is-archived/` + id;
    // @ts-ignore
    return this.httpclient.put<Employee>(path, employee, this.httpOptions);
  }

  updateIsRestored(employee: Employee, id: string): Observable<Employee> {
    const path = `${this.employeeUrl}employee/update-is-restored/` + id;
    // @ts-ignore
    return this.httpclient.put<Employee>(path, employee, this.httpOptions);
  }
  // shared with syrine
  findAllCommercialsEmployees(): Observable<[Employee]> {
    return this.httpclient.get<[Employee]>(`${this.employeeUrl}employee/commercial`);
  }

  invite(email: string, matriculate: string, role: string) {
    const path = `${this.urlmail}invite/${email}/${matriculate}/${role}`;
    return this.httpclient.post(path, this.httpOptions);
  }

  getAllRoles() {
    return this.httpclient.get<[any]>(`${this.urlauthRole}findAll`);

  }
}
