import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {CrmTrackingDeals} from '../../models/CrmTrackingDeals';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CrmTeamTrackingDealsService {
  private totalRecetteSource = new BehaviorSubject<number | null>(null);  // Changed from 0 to null
  totalRecette$ = this.totalRecetteSource.asObservable();


  httpOptions = {
    headers: new HttpHeaders({

      'Content-Type': 'application/json;  charset=utf-8 ',
    }),
  };


  constructor(private httpClient: HttpClient) { }


  addCrmTrackingDeals(crmTrackingDealsDTO: CrmTrackingDeals): Observable<CrmTrackingDeals> {
    return this.httpClient.post<CrmTrackingDeals>(`${environment.crmUrl}CrmTrackingDeals/add`, crmTrackingDealsDTO, this.httpOptions);
  }

  getTeamMemberNames(): Observable<string[]> {
    return this.findAllCrmTrackingDeals().pipe(
      map(deals => deals.map(deal => deal.teamMember.representantCommercial)),
    );
  }
  findAllCrmTrackingDeals(): Observable<CrmTrackingDeals[]> {
    return this.httpClient.get<CrmTrackingDeals[]>(`${environment.crmUrl}CrmTrackingDeals/allCrmTrackingDeals`);
  }

  updateCrmTrackingDeals(crmTrackingDealsDTO: CrmTrackingDeals): Observable<CrmTrackingDeals> {
    return this.httpClient.put<CrmTrackingDeals>(`${environment.crmUrl}CrmTrackingDeals/update-CrmTrackingDeals`, crmTrackingDealsDTO, this.httpOptions);
  }

  deleteCrmTrackingDeals(id: string): Observable<string> {
    return this.httpClient.delete<string>(`${environment.crmUrl}CrmTrackingDeals/delete/${id}`, this.httpOptions);
  }

  getCrmTrackingDealsByID(id: string): Observable<CrmTrackingDeals> {
    return this.httpClient.get<CrmTrackingDeals>(`${environment.crmUrl}CrmTrackingDeals/CrmTrackingDeals-by-id/${id}`);
  }

  findAllDealsWithFullProbability(): Observable<CrmTrackingDeals[]> {
    return this.httpClient.get<CrmTrackingDeals[]>(`${environment.crmUrl}CrmTrackingDeals/full-probability`);
  }

  cancelArchiveCrmTrackingDeals(crmTrackingDeals: CrmTrackingDeals, id: string): Observable<CrmTrackingDeals> {
    return this.httpClient.put<CrmTrackingDeals>(
      `${environment.crmUrl}CrmTrackingDeals/cancel-archive-crmTrackingDeals/` + id,
      crmTrackingDeals, this.httpOptions);
  }
  archiveCrmTeamTrackingDeals(crmTrackingDeals: CrmTrackingDeals, id: string): Observable<CrmTrackingDeals> {
    return this.httpClient.put<CrmTrackingDeals>(
      `${environment.crmUrl}CrmTrackingDeals/archive-crmTrackingDeals/` + id,
      crmTrackingDeals, this.httpOptions);
  }

  findNonArchivedCrmTrackingDeals() {
    return this.httpClient.get(`${environment.crmUrl}CrmTrackingDeals/non-archived-crmTrackingDeals/False`);
  }

  findCrmTrackingDealsArchived() {
    return this.httpClient.get(`${environment.crmUrl}CrmTrackingDeals/non-archived-crmTrackingDeals/True`);
  }


  updateTotalRecette(value: number) {
    console.log('Updating totalRecette to:', value);
    this.totalRecetteSource.next(value);
  }

  getHighestValueRepresentative(): Observable<string> {
    return this.httpClient.get(`${environment.crmUrl}CrmTrackingDeals/highest-value-representative`, { responseType: 'text' });
  }
}
