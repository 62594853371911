import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthRoutingModule } from './auth-routing.module';
import { FormsModule , ReactiveFormsModule} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NbAuthModule } from '@nebular/auth';
import {
  NbAlertModule,
  NbButtonModule,
  NbCheckboxModule,
  NbInputModule,
  NbCardModule,

  NbIconModule, NbLayoutModule, NbSpinnerModule,

} from '@nebular/theme';

import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ProfileComponent } from './components/profile/profile.component';
// import { NgxCaptchaModule } from 'ngx-captcha';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { UnlockingComponent } from './components/unlocking/unlocking.component';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthInterceptorService} from './helpers/auth-interceptor.service';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';
import {VerificationCodeComponent} from './components/verification-code/verification-code.component';


@NgModule({
  declarations: [ ForgetPasswordComponent, ResetPasswordComponent, ProfileComponent, UnlockingComponent, VerificationCodeComponent],
    imports: [
        AuthRoutingModule,
        CommonModule,
        FormsModule,
        RouterModule,
        NbAlertModule,
        NbInputModule,
        NbButtonModule,
        NbCheckboxModule,
        NbAuthModule,
        NbCardModule,
        NbIconModule,
        ReactiveFormsModule,
        NbLayoutModule,
        NbSpinnerModule,
        RecaptchaModule,
        NgxIntlTelInputModule,

    ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
  ],
})
export class AuthModule {}
