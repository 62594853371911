<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css" rel="stylesheet">
<div class="content">
  <div class="container">
    <nb-card-header>
      <div class="row">
        <div class="col-md-3">
          <h3>Customers</h3>
        </div>
        <div class="col-md-6">
          <div class="row mx-1">
            <input [(ngModel)]="search"
                   class="w-100 " fieldSize="large" fullWidth nbInput
                   nbTooltip="Tap here to search your customer" nbTooltipStatus="primary"
                   placeholder="Search customer"
                   type="text">

          </div>
        </div>
        <div class="col-md-3 pt-1 ">
          <div>
            <!--   <a [routerLink]="['/crm/grid-customers']" 
                  nbButton nbTooltip="Visualize data on dimensions grid 2x2" nbTooltipStatus="primary" status="primary">
                 <nb-icon icon="grid-outline"></nb-icon>
               </a>-->
            <a [routerLink]="['/crm/customers']" class="mx-1" nbButton
               nbTooltip="Visualize data on keypad " nbTooltipStatus="primary" status="warning">
              <nb-icon icon="keypad-outline"></nb-icon>
            </a>
            <a [routerLink]="['/crm/list-customers']" class="mx-1" nbButton
               nbTooltip="Visualize data on list " nbTooltipStatus="primary" status="basic">
              <nb-icon icon="list-outline"></nb-icon>
            </a>
            <button (click)="exportAsXLSX()"
                    align="right" class="mx-1"
                    nbButton nbTooltip="Click here to export data to Excel" nbTooltipStatus="primary"
                    status="success">
              <i class="fas fa-file-excel text-white"></i>
            </button>
            <button [routerLink]="['/crm/create-customer']" class="mx-1" nbButton
                    nbTooltip="Click here to create customer" nbTooltipStatus="primary"
                    status="primary">
              <nb-icon icon="plus-outline"></nb-icon>
              Add
            </button>

          </div>
        </div>
      </div>


    </nb-card-header>
    <div *ngIf="!dataCustomers || dataCustomers.length === 0" align="center">
      <nb-alert class="w-100 mt-3" outline="danger">No Data Found.</nb-alert>
    </div>
    <div *ngIf="(dataCustomers | filter : search).length === 0" [hidden]="dataCustomers.length === 0"
         class="">
      <nb-alert class="col-lg-12 mt-3" fullWidth outline="danger">The item searched is not found. Please make sure
        while typing your data
      </nb-alert>
    </div>
    <div class="row mt-3">
      <div class="col-md-3">

        <nb-form-field>
          <nb-icon class="mt-3" icon="funnel-outline" nbPrefix></nb-icon>
          <nb-select (selectedChange)="getCustomersByStatus($event)"
                     class="mx-2 mt-3 w-75" nbTooltip="Click here to filter by status"
                     nbTooltipStatus="primary"
                     placeholder="&nbsp; Status"
          >
            <nb-option (click)="getAllCustomers()" value="All">All</nb-option>

            <nb-option value="Lead">Lead</nb-option>
            <nb-option value="Client">Client</nb-option>
          </nb-select>
        </nb-form-field>
        <nb-form-field>
          <nb-icon class="mt-3" icon="funnel-outline" nbPrefix></nb-icon>
          <nb-select [disabled]="dataCustomers.length === 0"
                     class="mx-2 mt-3 w-75" nbTooltip="Click here to sort by name"
                     nbTooltipStatus="primary" placeholder="&nbsp; Name"
          >
            <nb-option (click)="getAllCustomers()" value="All">All</nb-option>
            <nb-option (click)="getCustomersAsc()" value="1">Names (A-Z)</nb-option>
            <nb-option (click)="getCustomersDesc()" value="2">Names (Z-A)</nb-option>
          </nb-select>
        </nb-form-field>
        <nb-form-field>
          <nb-icon class="mt-3" icon="funnel-outline" nbPrefix></nb-icon>
          <nb-select (selectedChange)="getProspectsByAddress($event,'country')" [disabled]="dataCustomers.length === 0"
                     class="w-75 mt-3 mx-2" nbTooltip="Click here to sort by country"
                     nbTooltipStatus="primary" placeholder="&nbsp; Country">
            <nb-option (click)="getAllCustomers()" value="All">All</nb-option>
            <nb-option *ngFor="let country of countries"
                       value="{{country.country_name}}">{{country.country_name}}</nb-option>

          </nb-select>
        </nb-form-field>
        <nb-form-field>
          <nb-icon class="mt-3" icon="funnel-outline" nbPrefix></nb-icon>
          <nb-select (selectedChange)="filterByEmployee($event)"
                     class="w-75 mt-3 mx-2" nbTooltip="Click here to filter customer by employee"
                     nbTooltipStatus="primary" placeholder="&nbsp; Employee">
            <nb-option (click)="getAllCustomers()" value="All">All</nb-option>
            <nb-option
              *ngFor="let optionEmployee of list"
              value="{{optionEmployee.id}}">{{optionEmployee.employeeName}}</nb-option>


          </nb-select>
        </nb-form-field>
        <div class="col-lg-10 mt-3">
          <label>Customers Per Page : </label>
          <input [(ngModel)]="pageSize" [disabled]="dataCustomers.length === 0"
                 class="w-100"
                 min="9" nbInput
                 nbTooltip="Tap a number to get customers per page" nbTooltipStatus="primary"
                 placeholer="Number to get customers per page" type="number">
        </div>
      </div>

      <div class="row col-md-9">
        <div
          *ngFor="let customer of dataCustomers |filter: search
           | paginate : {itemsPerPage:pageSize, currentPage: current,totalItems: total}"
          align="center" class="col-lg-4">
          <div class="mx-1 row input-group">
            <nb-card>
              <nb-card-body class="pt-2  pb-2">
                <div>
                  <div class="row mt-2">
                    <div class="col-lg-12">
                      <div align="right" >
                        <button [matMenuTriggerFor]="menu" aria-expanded="false" aria-haspopup="true"
                                aria-label="Example icon-button with a menu" mat-icon-button
                                nbButton nbTooltip="Click here to see advanced menu"
                                nbTooltipStatus="primary"
                                shape="round"
                                size="small"
                                status="basic">
                          <nb-icon icon="more-horizontal-outline"></nb-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                          
                          <button mat-menu-item >
                            <a [routerLink]="['/crm/edit-customer/',customer.id]"
                               fullWidth nbButton >
                      <span class="text-warning" >
                        <nb-icon icon="edit-outline"></nb-icon>
                      </span> Edit
                            </a>
                          </button>

                          <button mat-menu-item>
                            <a [routerLink]="['/crm/customer-details/',customer.id]" fullWidth nbButton>
                              <span class="text-info"><nb-icon icon="eye-outline"></nb-icon></span>Details
                            </a>
                          </button>

                          <button *ngIf="customer.status === 'Lead'" mat-menu-item>
                            <a
                              (click)="updateStatus(customer,customer.id)"
                              fullWidth nbButton>
                  <span class="text-success">
                  <nb-icon icon="checkmark-outline"></nb-icon></span> Convert to client
                            </a>
                          </button>
                          <button mat-menu-item>
                            <a (click)="onArchiveConfirm(customer,customer.id)" fullWidth nbButton status="basic">
                              <nb-icon icon="archive-outline"></nb-icon>
                              Archive
                            </a>
                          </button>
                        </mat-menu>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <nb-user
                        [name]="customer.name"
                        class="text-dark justify-content-center"
                        onlyPicture picture="{{customer.image}}" size="giant"></nb-user>
                    </div>
                  </div>
                </div>
                <div>
                  <h4>{{customer.name}}</h4>
                  <span class="text-sm-center mt-2">{{customer.workEmail}}</span>
                  <p class="text-muted mt-2">@{{customer.status}} <span>|
                            </span><span><a class="text-pink" href={{customer.workWebsite}
                                            target="_blank">{{customer.workWebsite}}</a></span></p>
                </div>
                <ul class="social-links list-inline">
                  <li class="list-inline-item">
                    <a class="tooltips" data-original-title="Linkedin" data-placement="top"
                       data-toggle="tooltip" href="{{customer.linkedinUrl}}" target="_blank"
                       title="{{customer.linkedinUrl}}">
                      <i class="fa fa-linkedin"></i></a></li>
                </ul>
                <div class="mt-4">
                  <div class="row">
                    <div class="col-lg">
                      <div class="mt-3">
                        <span>{{customer.phone}}</span>
                        <p class="mb-0 text-muted">Phone</p>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div align="center" class="col-lg ">
                      <div class="mt-3">
                        <p>{{customer.address}}</p>
                        <p class="mb-0 text-muted">Address</p>
                      </div>
                    </div>
                  </div>
                </div>

              </nb-card-body>
            </nb-card>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <nb-card class="card ml-auto mr-auto text-center" style="height: 30px; width: auto ;">
        <pagination-controls (pageChange)="current = $event" align="right" nextLabel=""
                             previousLabel=""></pagination-controls>

      </nb-card>
    </div>

  </div>

</div>
